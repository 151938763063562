<template lang="pug">
	.engine-page
		.main
			iframe(src="https://hourglass.infusemedia.com/engine_frame_v2")

</template>

<script>
	import UserBlock from '@/components/User-Action'

	export default {
		name: 'Assets',
		components:{
			UserBlock,
		},
		data() {
			return {
			}
		},
		created(){
		},
		mounted(){
		},
		methods: {
		}
	}
</script>