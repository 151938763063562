<template lang="pug">
	.user-top
		a(href="https://infusemedia.com", target="_blank").logo
			img(src="@/assets/infuse-logo.svg", alt="")

		.user-menu
			.user-ico

			.user-info
				.name
					h5 {{ this.$store.state.Auth.first_name }}
						<!-- {{ this.$store.state.Auth }} -->
						br
						| {{ this.$store.state.Auth.last_name }}

				p.mail {{ this.$store.state.Auth.email }}

				.btn-row
					button.btn.logout(type="button", v-on:click="logOut()") Log out
					a(href="https://docs.google.com/presentation/d/1gDjOYaV6CvpDhqzQo3W3ilIYdGWd5-3xpSH6Wrw-xZ0/edit#slide=id.p", target="_blank").btn.blue HELP
						.ico
							svg(width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg")
								path(d="M8.5 0C3.80603 0 0 3.80603 0 8.5C0 13.194 3.80603 17 8.5 17C13.194 17 17 13.194 17 8.5C17 3.80603 13.194 0 8.5 0ZM8.5 15.558C4.6029 15.558 1.44196 12.3971 1.44196 8.5C1.44196 4.6029 4.6029 1.44196 8.5 1.44196C12.3971 1.44196 15.558 4.6029 15.558 8.5C15.558 12.3971 12.3971 15.558 8.5 15.558Z")
								path(d="M10.5941 4.73851C10.0314 4.26129 9.28868 4 8.50093 4C7.71319 4 6.97046 4.2631 6.40778 4.73851C5.8226 5.23387 5.5 5.8998 5.5 6.6129V6.75081C5.5 6.83065 5.56752 6.89597 5.65005 6.89597H6.55033C6.63285 6.89597 6.70037 6.83065 6.70037 6.75081V6.6129C6.70037 5.8127 7.50875 5.16129 8.50093 5.16129C9.49312 5.16129 10.3015 5.8127 10.3015 6.6129C10.3015 7.17722 9.88887 7.69436 9.24929 7.93206C8.85167 8.07903 8.51406 8.33669 8.27211 8.67419C8.02641 9.01895 7.89887 9.43266 7.89887 9.85181V10.2419C7.89887 10.3218 7.96639 10.3871 8.04892 10.3871H8.9492C9.03172 10.3871 9.09925 10.3218 9.09925 10.2419V9.83004C9.10021 9.65389 9.15603 9.48211 9.25941 9.3371C9.36279 9.1921 9.50894 9.08059 9.6788 9.01714C10.7854 8.60524 11.5 7.66169 11.5 6.6129C11.5019 5.8998 11.1793 5.23387 10.5941 4.73851ZM7.7507 12.2742C7.7507 12.4667 7.82974 12.6513 7.97044 12.7874C8.11113 12.9235 8.30196 13 8.50093 13C8.69991 13 8.89073 12.9235 9.03143 12.7874C9.17212 12.6513 9.25117 12.4667 9.25117 12.2742C9.25117 12.0817 9.17212 11.8971 9.03143 11.761C8.89073 11.6249 8.69991 11.5484 8.50093 11.5484C8.30196 11.5484 8.11113 11.6249 7.97044 11.761C7.82974 11.8971 7.7507 12.0817 7.7507 12.2742Z")

		.message-form(v-bind:class="{active: RightSidebar.contactM}", v-click-outside="handleFocusOut")
			.user-status.online
				p Online

			textarea(placeholder="Type your message here", v-model="messageForm.message", v-bind:class="{error: errorMessage}").message-area

			button.btn.send-message(type="button", v-on:click="sendMessage()") Send a message
</template>

<script>
	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'UserBlock',
		created(){
			// this.Auth.init();
		},
		data(){
			return {
				errorMessage: false,
				messageForm:{
					message: '',
					campaign: null,
					manager: null
				}
			}
		},
		computed: {
			...mapGetters(['RightSidebar']),
		},
		methods: {
			...mapMutations(['updateRS']),
			logOut(){
				window.Token.deletetoken();
				window.Auth.logout();
				document.location.href="/login/";
				// this.$router.push('login');
			},
			sendMessage(){
				this.errorMessage = false;
				this.messageForm.campaign = this.$store.state.SelectedCampaign.id;
				this.messageForm.manager = this.RightSidebar.manager.id;
				if (this.messageForm.message.length < 2) {
					this.errorMessage = true;
					setTimeout(() => {
						this.errorMessage = false;
					}, 2000)
				}
				if (!this.errorMessage) {
					window.api.post("campaign_settings/message/", this.messageForm)
						.then(() => {
							setTimeout(() => {
								var contactM = !this.RightSidebar.contactM;
								this.updateRS(['contactM', contactM]);
								this.messageForm = { message: '', campaign: null, manager: this.RightSidebar.manager.id }
							}, 500)
						})
						.catch(error => {
							console.log(error)
						})
				}
			},
			handleFocusOut() {
				if (!this.RightSidebar.triggerContact) {
					this.updateRS(['contactM', false]);
				}
			}
		}
	}
</script>