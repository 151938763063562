<template lang="pug">
	.home
		.center
			HourGlass
</template>

<script>
	import LeftSidebar from '@/components/Left-Sidebar'
	import RightSidebar from '@/components/Right-Sidebar'
	import HourGlass from '@/components/Hour-Glass'

	export default {
		name: 'Home',
		components: {
			LeftSidebar,
			RightSidebar,
			HourGlass
		},
		data(){
			return {
				activeCampaing: null
			}
		},
		methods:{
			onCampaingSelected (data) {
				this.activeCampaing = data.activeCampaing
			}
		}
	}
</script>