<template lang="pug">
	.login-page
		form.login-form
			.logo
				img(src="../../assets/logo.svg", alt="")

			.actions-fields
				.field
					input(type="input", placeholder="User Email", v-model="loginForm.username").inp
				.field
					input(type="password", placeholder="Password", v-model="loginForm.password").inp

				span.error-message(v-if="error") Incorrect username or password
				
				.more-actions
					.checkbox
						input(type="checkbox", v-model="remember").check#remeber-me
						label(for="remeber-me") Remember me
					//- router-link(to="/forgot-password").forgot Forgot Password?


				.login-action
					button.btn.login(type="button", v-on:click="onLogin()") login

		//- .login-bottom
		//- 	.infuse-logo
		//- 		img(src="../../assets/infuse-logo.svg", alt="")
		//- 	p.copyright © 2023 by INFUSEmedia, All Rights Reserved
			
</template>

<script>
	export default {
		name: 'Login',
		data() {
			return {
				loginForm: {
					username: '',
					password: ''
					// username: 'testuser',
					// password: 'testS5ab80'
				},
				remember: false,
				error: false
			}
		},
		computed:{
			auth(){
				return this.$store.state.Auth;
			}
		},
		methods: {
			async onLogin(){
				// this.$root.preload = true;
				window.AddDD.deleteData();
				this.error = false;
				let header = {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
				let params = new URLSearchParams();
				params.append('username', this.loginForm.username.toLowerCase());
				params.append('password', this.loginForm.password);
				window.api.post("auth/token/obtain/", params, header)
					.then(res => {
						window.Token.settoken(res.data);
						window.Token.init();
						window.api.get('auth/user/')
							.then(user => {
								var timestamp = new Date().getTime();
								var hours = (timestamp / (1000 * 60 * 60)).toFixed(0);
								localStorage.setItem('hours', hours);
								var day = (timestamp / (1000 * 60 * 60 * 24)).toFixed(0);
								localStorage.setItem('day', day);
								localStorage.setItem('remember_me', this.remember);
								window.Auth.login(user.data.user);
								window.Auth.init();
								this.$router.push(window.baseURL);
							})
							.catch(userError => {
								console.log(userError);
							})
					})
					.catch(error => {
						console.log(error)
						this.error = true;
					})
			}
		}
	}
</script>