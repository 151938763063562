<template lang="pug">
	div.card-box
		.card.itcurated-card(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="The Curated Network of Digital Publications", slug="ITCurated" v-model="cardStatus", file="https://itcurated.com")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.itcurateds.length)}")
				p Engage your leads with trending content on ITCurated.

			div(v-if="GetSettings.itcurateds.length")
				.itcurated-title(v-if="showActivated")
					h4.orange Activated Publications

				.curated-list.active(v-if="showActivated")
					.single-item(
						v-for="(item, index) in GetSettings.itcurateds",
						v-bind:class="item.status",
						v-if="item.status == 'active'"
					)
						.logo(v-bind:class="item.curated.slug")

						.actions
							a(:href="item.curated.link", target="_blank").link Open
								.ico
									svg(xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none")
										path(d="M7 1H2C1.44772 1 1 1.44772 1 2V13C1 13.5523 1.44772 14 2 14H13C13.5523 14 14 13.5523 14 13V8" stroke-linecap="round").stroke
										path(d="M14.5 1C14.5 0.723858 14.2761 0.5 14 0.5L9.5 0.500001C9.22386 0.5 9 0.723858 9 1C9 1.27614 9.22386 1.5 9.5 1.5L13.5 1.5L13.5 5.5C13.5 5.77614 13.7239 6 14 6C14.2761 6 14.5 5.77614 14.5 5.5L14.5 1ZM8.35355 7.35355L14.3536 1.35355L13.6464 0.646447L7.64645 6.64645L8.35355 7.35355Z").fill

							.settings
								svg(width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg")
									path(d="M15.9463 8.90605C15.9791 8.60864 16 8.30706 16 8.00026C16 7.69346 15.9791 7.39188 15.9457 7.09395L13.6615 6.28156C13.5478 5.90797 13.3986 5.55108 13.218 5.21194L14.2574 3.02469C13.8791 2.55032 13.4489 2.11979 12.9749 1.74114L10.7877 2.78259C10.4468 2.59959 10.0885 2.45102 9.71812 2.33908L8.90628 0.0547856C8.60836 0.0213925 8.30679 0 8 0C7.69321 0 7.39164 0.0208707 7.09372 0.0547856L6.28188 2.33908C5.9083 2.45231 5.55142 2.60101 5.21229 2.78259L3.02459 1.74114C2.54979 2.11942 2.11987 2.54988 1.74108 3.02469L2.7825 5.21089C2.60093 5.55004 2.45223 5.90745 2.33901 6.28052L0.0547838 7.09447C0.0213918 7.39188 0 7.69451 0 8.00078C0 8.30706 0.02087 8.60864 0.0547838 8.90657L2.33901 9.71949C2.45223 10.0925 2.60093 10.45 2.7825 10.7891L1.7416 12.9764C2.11987 13.4512 2.55032 13.8806 3.02511 14.2589L5.21072 13.2179C5.54986 13.3995 5.90726 13.5487 6.28031 13.6625L7.0932 15.9463C7.3906 15.9791 7.69217 16 7.99948 16C8.30627 16 8.60732 15.9791 8.90524 15.9457L9.71812 13.6614C10.0912 13.5477 10.4486 13.3985 10.7877 13.2179L12.9749 14.2573C13.4497 13.879 13.8791 13.4486 14.2574 12.9748L13.2175 10.7881C13.3991 10.4505 13.5483 10.092 13.662 9.71844L15.9457 8.90605H15.9463ZM8 10.5413C7.66625 10.5413 7.33576 10.4757 7.02739 10.348C6.71902 10.2203 6.43882 10.0332 6.20277 9.79724C5.72606 9.32071 5.45817 8.67432 5.45803 8.00026C5.45789 7.32621 5.72552 6.6797 6.20204 6.20298C6.67855 5.72625 7.32492 5.45835 7.99896 5.45821C8.67299 5.45807 9.31947 5.72571 9.79618 6.20224C10.2729 6.67877 10.5408 7.32516 10.5409 7.99922C10.5411 8.67327 10.2734 9.31978 9.79692 9.7965C9.32041 10.2732 8.67403 10.5411 8 10.5413Z")

								.action-list
									p(v-on:click="makeInactive(index)") Make Inactive

				//- .curated-list.active
				//- 	.single-item(
				//- 		v-for="(item, index) in GetSettings.itcurateds",
				//- 		v-bind:class="item.status",
				//- 		v-if="item.status == 'active'"
				//- 	)
				//- 		a(:href="item.curated.link", target="_blank").logo(v-bind:class="item.curated.slug")

				//- 		.actions
				//- 			.activate-btn(v-if="item.status == 'pause'")
				//- 				p.activate(v-on:click="makeRequested(index)") ACTIVATE

				//- 			.requested(v-if="item.status == 'requested'")
				//- 				p REQUESTED

				//- 			.btns-links(v-else)
				//- 				a(:href="item.curated.link", target="_blank").link Open
				//- 					.ico
				//- 						svg(xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none")
				//- 							path(d="M7 1H2C1.44772 1 1 1.44772 1 2V13C1 13.5523 1.44772 14 2 14H13C13.5523 14 14 13.5523 14 13V8" stroke-linecap="round").stroke
				//- 							path(d="M14.5 1C14.5 0.723858 14.2761 0.5 14 0.5L9.5 0.500001C9.22386 0.5 9 0.723858 9 1C9 1.27614 9.22386 1.5 9.5 1.5L13.5 1.5L13.5 5.5C13.5 5.77614 13.7239 6 14 6C14.2761 6 14.5 5.77614 14.5 5.5L14.5 1ZM8.35355 7.35355L14.3536 1.35355L13.6464 0.646447L7.64645 6.64645L8.35355 7.35355Z").fill

				//- 				.settings
				//- 					svg(width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg")
				//- 						path(d="M15.9463 8.90605C15.9791 8.60864 16 8.30706 16 8.00026C16 7.69346 15.9791 7.39188 15.9457 7.09395L13.6615 6.28156C13.5478 5.90797 13.3986 5.55108 13.218 5.21194L14.2574 3.02469C13.8791 2.55032 13.4489 2.11979 12.9749 1.74114L10.7877 2.78259C10.4468 2.59959 10.0885 2.45102 9.71812 2.33908L8.90628 0.0547856C8.60836 0.0213925 8.30679 0 8 0C7.69321 0 7.39164 0.0208707 7.09372 0.0547856L6.28188 2.33908C5.9083 2.45231 5.55142 2.60101 5.21229 2.78259L3.02459 1.74114C2.54979 2.11942 2.11987 2.54988 1.74108 3.02469L2.7825 5.21089C2.60093 5.55004 2.45223 5.90745 2.33901 6.28052L0.0547838 7.09447C0.0213918 7.39188 0 7.69451 0 8.00078C0 8.30706 0.02087 8.60864 0.0547838 8.90657L2.33901 9.71949C2.45223 10.0925 2.60093 10.45 2.7825 10.7891L1.7416 12.9764C2.11987 13.4512 2.55032 13.8806 3.02511 14.2589L5.21072 13.2179C5.54986 13.3995 5.90726 13.5487 6.28031 13.6625L7.0932 15.9463C7.3906 15.9791 7.69217 16 7.99948 16C8.30627 16 8.60732 15.9791 8.90524 15.9457L9.71812 13.6614C10.0912 13.5477 10.4486 13.3985 10.7877 13.2179L12.9749 14.2573C13.4497 13.879 13.8791 13.4486 14.2574 12.9748L13.2175 10.7881C13.3991 10.4505 13.5483 10.092 13.662 9.71844L15.9457 8.90605H15.9463ZM8 10.5413C7.66625 10.5413 7.33576 10.4757 7.02739 10.348C6.71902 10.2203 6.43882 10.0332 6.20277 9.79724C5.72606 9.32071 5.45817 8.67432 5.45803 8.00026C5.45789 7.32621 5.72552 6.6797 6.20204 6.20298C6.67855 5.72625 7.32492 5.45835 7.99896 5.45821C8.67299 5.45807 9.31947 5.72571 9.79618 6.20224C10.2729 6.67877 10.5408 7.32516 10.5409 7.99922C10.5411 8.67327 10.2734 9.31978 9.79692 9.7965C9.32041 10.2732 8.67403 10.5411 8 10.5413Z")

				//- 					.action-list
				//- 						p(v-on:click="makeInactive(index)") Make Inactive

				.itcurated-title(v-if="showInactive")
					h4.blue Inactive Publications

				.curated-list.inactive(v-if="showInactive")
					.single-item(
						v-for="(item, index) in GetSettings.itcurateds",
						v-bind:class="item.status",
						v-if="item.status != 'active'"
					)
						.logo(v-bind:class="item.curated.slug")

						.actions
							p.activate(v-if="item.status == 'pause'", v-on:click="makeRequested(index)") ACTIVATE

							.requested(v-if="item.status == 'requested'")
								p REQUESTED

							.settings
								svg(width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg")
									path(d="M15.9463 8.90605C15.9791 8.60864 16 8.30706 16 8.00026C16 7.69346 15.9791 7.39188 15.9457 7.09395L13.6615 6.28156C13.5478 5.90797 13.3986 5.55108 13.218 5.21194L14.2574 3.02469C13.8791 2.55032 13.4489 2.11979 12.9749 1.74114L10.7877 2.78259C10.4468 2.59959 10.0885 2.45102 9.71812 2.33908L8.90628 0.0547856C8.60836 0.0213925 8.30679 0 8 0C7.69321 0 7.39164 0.0208707 7.09372 0.0547856L6.28188 2.33908C5.9083 2.45231 5.55142 2.60101 5.21229 2.78259L3.02459 1.74114C2.54979 2.11942 2.11987 2.54988 1.74108 3.02469L2.7825 5.21089C2.60093 5.55004 2.45223 5.90745 2.33901 6.28052L0.0547838 7.09447C0.0213918 7.39188 0 7.69451 0 8.00078C0 8.30706 0.02087 8.60864 0.0547838 8.90657L2.33901 9.71949C2.45223 10.0925 2.60093 10.45 2.7825 10.7891L1.7416 12.9764C2.11987 13.4512 2.55032 13.8806 3.02511 14.2589L5.21072 13.2179C5.54986 13.3995 5.90726 13.5487 6.28031 13.6625L7.0932 15.9463C7.3906 15.9791 7.69217 16 7.99948 16C8.30627 16 8.60732 15.9791 8.90524 15.9457L9.71812 13.6614C10.0912 13.5477 10.4486 13.3985 10.7877 13.2179L12.9749 14.2573C13.4497 13.879 13.8791 13.4486 14.2574 12.9748L13.2175 10.7881C13.3991 10.4505 13.5483 10.092 13.662 9.71844L15.9457 8.90605H15.9463ZM8 10.5413C7.66625 10.5413 7.33576 10.4757 7.02739 10.348C6.71902 10.2203 6.43882 10.0332 6.20277 9.79724C5.72606 9.32071 5.45817 8.67432 5.45803 8.00026C5.45789 7.32621 5.72552 6.6797 6.20204 6.20298C6.67855 5.72625 7.32492 5.45835 7.99896 5.45821C8.67299 5.45807 9.31947 5.72571 9.79618 6.20224C10.2729 6.67877 10.5408 7.32516 10.5409 7.99922C10.5411 8.67327 10.2734 9.31978 9.79692 9.7965C9.32041 10.2732 8.67403 10.5411 8 10.5413Z")

								.action-list
									a(:href="item.curated.link", target="_blank") Open

				//- .curated-list.inactive
				//- 	.single-item(
				//- 		v-for="(item, index) in GetSettings.itcurateds",
				//- 		v-bind:class="item.status",
				//- 		v-if="item.status != 'active'"
				//- 	)
				//- 		a(:href="item.curated.link", target="_blank").logo(v-bind:class="item.curated.slug")

				//- 		.actions
				//- 			.activate-btn(v-if="item.status == 'pause'")
				//- 				p.activate(v-on:click="makeRequested(index)") ACTIVATE

				//- 			.requested(v-if="item.status == 'requested'")
				//- 				p REQUESTED

				//- 			.btns-links(v-else)
				//- 				a(:href="item.curated.link", target="_blank").link Open
				//- 					.ico
				//- 						svg(xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none")
				//- 							path(d="M7 1H2C1.44772 1 1 1.44772 1 2V13C1 13.5523 1.44772 14 2 14H13C13.5523 14 14 13.5523 14 13V8" stroke-linecap="round").stroke
				//- 							path(d="M14.5 1C14.5 0.723858 14.2761 0.5 14 0.5L9.5 0.500001C9.22386 0.5 9 0.723858 9 1C9 1.27614 9.22386 1.5 9.5 1.5L13.5 1.5L13.5 5.5C13.5 5.77614 13.7239 6 14 6C14.2761 6 14.5 5.77614 14.5 5.5L14.5 1ZM8.35355 7.35355L14.3536 1.35355L13.6464 0.646447L7.64645 6.64645L8.35355 7.35355Z").fill

				//- 				.settings
				//- 					svg(width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg")
				//- 						path(d="M15.9463 8.90605C15.9791 8.60864 16 8.30706 16 8.00026C16 7.69346 15.9791 7.39188 15.9457 7.09395L13.6615 6.28156C13.5478 5.90797 13.3986 5.55108 13.218 5.21194L14.2574 3.02469C13.8791 2.55032 13.4489 2.11979 12.9749 1.74114L10.7877 2.78259C10.4468 2.59959 10.0885 2.45102 9.71812 2.33908L8.90628 0.0547856C8.60836 0.0213925 8.30679 0 8 0C7.69321 0 7.39164 0.0208707 7.09372 0.0547856L6.28188 2.33908C5.9083 2.45231 5.55142 2.60101 5.21229 2.78259L3.02459 1.74114C2.54979 2.11942 2.11987 2.54988 1.74108 3.02469L2.7825 5.21089C2.60093 5.55004 2.45223 5.90745 2.33901 6.28052L0.0547838 7.09447C0.0213918 7.39188 0 7.69451 0 8.00078C0 8.30706 0.02087 8.60864 0.0547838 8.90657L2.33901 9.71949C2.45223 10.0925 2.60093 10.45 2.7825 10.7891L1.7416 12.9764C2.11987 13.4512 2.55032 13.8806 3.02511 14.2589L5.21072 13.2179C5.54986 13.3995 5.90726 13.5487 6.28031 13.6625L7.0932 15.9463C7.3906 15.9791 7.69217 16 7.99948 16C8.30627 16 8.60732 15.9791 8.90524 15.9457L9.71812 13.6614C10.0912 13.5477 10.4486 13.3985 10.7877 13.2179L12.9749 14.2573C13.4497 13.879 13.8791 13.4486 14.2574 12.9748L13.2175 10.7881C13.3991 10.4505 13.5483 10.092 13.662 9.71844L15.9457 8.90605H15.9463ZM8 10.5413C7.66625 10.5413 7.33576 10.4757 7.02739 10.348C6.71902 10.2203 6.43882 10.0332 6.20277 9.79724C5.72606 9.32071 5.45817 8.67432 5.45803 8.00026C5.45789 7.32621 5.72552 6.6797 6.20204 6.20298C6.67855 5.72625 7.32492 5.45835 7.99896 5.45821C8.67299 5.45807 9.31947 5.72571 9.79618 6.20224C10.2729 6.67877 10.5408 7.32516 10.5409 7.99922C10.5411 8.67327 10.2734 9.31978 9.79692 9.7965C9.32041 10.2732 8.67403 10.5411 8 10.5413Z")

				//- 					.action-list
				//- 						p(v-on:click="makeInactive(index)") Make Inactive

				.contact-manager
					.contact(v-bind:class="{show: managerContact}", v-click-outside="handleFocusOut")
						.message-form
							.user-status.online
								p Online

							textarea(placeholder="Type your message here", v-model="messageForm.message", v-bind:class="{error: errorMessage}").message-area

							button.btn.send-message(type="button", v-on:click="sendMessage()") Send a message

						.contact-btn(v-on:click="showManagerForm()")
							.ico
								svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
									path(d="M5 15V14.5C4.86739 14.5 4.74021 14.5527 4.64645 14.6464L5 15ZM1 19H0.5C0.5 19.2022 0.621821 19.3846 0.808658 19.4619C0.995495 19.5393 1.21055 19.4966 1.35355 19.3536L1 19ZM3 1V0.5V1ZM17 1V0.5V1ZM5.5 4.59142C5.22386 4.59142 5 4.81528 5 5.09142C5 5.36756 5.22386 5.59142 5.5 5.59142V4.59142ZM14.5 5.59142C14.7761 5.59142 15 5.36756 15 5.09142C15 4.81528 14.7761 4.59142 14.5 4.59142V5.59142ZM5.5 7.59142C5.22386 7.59142 5 7.81528 5 8.09142C5 8.36756 5.22386 8.59142 5.5 8.59142V7.59142ZM14.5 8.59142C14.7761 8.59142 15 8.36756 15 8.09142C15 7.81528 14.7761 7.59142 14.5 7.59142V8.59142ZM5.5 10.5914C5.22386 10.5914 5 10.8153 5 11.0914C5 11.3676 5.22386 11.5914 5.5 11.5914V10.5914ZM14.5 11.5914C14.7761 11.5914 15 11.3676 15 11.0914C15 10.8153 14.7761 10.5914 14.5 10.5914V11.5914ZM18.5 13C18.5 13.3978 18.342 13.7794 18.0607 14.0607L18.7678 14.7678C19.2366 14.2989 19.5 13.663 19.5 13H18.5ZM18.0607 14.0607C17.7794 14.342 17.3978 14.5 17 14.5V15.5C17.663 15.5 18.2989 15.2366 18.7678 14.7678L18.0607 14.0607ZM17 14.5H5V15.5H17V14.5ZM4.64645 14.6464L0.646447 18.6464L1.35355 19.3536L5.35355 15.3536L4.64645 14.6464ZM1.5 19V3H0.5V19H1.5ZM1.5 3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934L1.23223 1.23223C0.763392 1.70107 0.5 2.33696 0.5 3H1.5ZM1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5V0.5C2.33696 0.5 1.70107 0.763392 1.23223 1.23223L1.93934 1.93934ZM3 1.5H17V0.5H3V1.5ZM17 1.5C17.3978 1.5 17.7794 1.65804 18.0607 1.93934L18.7678 1.23223C18.2989 0.763392 17.663 0.5 17 0.5V1.5ZM18.0607 1.93934C18.342 2.22064 18.5 2.60218 18.5 3H19.5C19.5 2.33696 19.2366 1.70107 18.7678 1.23223L18.0607 1.93934ZM18.5 3V13H19.5V3H18.5ZM5.5 5.59142H14.5V4.59142H5.5V5.59142ZM5.5 8.59142H14.5V7.59142H5.5V8.59142ZM5.5 11.5914H14.5V10.5914H5.5V11.5914Z")

							span contact client success manager
			
			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Engage your leads with trending content on ITCurated.

		.popup.itcurated-requested(v-if="popupRequested")
			.close(v-on:click="showHidePopups()")
			.inside-form
				p Your request is submitted. 
					br
					| Our Client Success Manager 
					br
					| will update the campaign shortly.

				button.btn.simple(type="button", v-on:click="showHidePopups()") OK
			

</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'ITCurated',
		components:{
			CardHeader
		},
		data() {
			return {
				cardStatus: false,
				popupRequested: false,
				managerContact: false,
				errorMessage: false,
				messageForm:{
					message: '',
					campaign: null,
					manager: null
				},
				showActivated: false,
				showInactive: false
			}
		},
		created(){
			this.sortFunc(this.GetSettings.itcurateds);
			this.checkAll();
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "ITCurated") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			checkAll(){
				this.showActivated = false;
				this.showInactive = false;
				for(var it in this.GetSettings.itcurateds){
					if (this.GetSettings.itcurateds[it].status == 'active') {
						this.showActivated = true;
					}else{
						this.showInactive = true;
					}
				}
			},
			makeRequested(index){
				if (this.cardStatus) {
					this.popupRequested = true;
					this.$emit('input', this.popupRequested);
					this.GetSettings.itcurateds.[index].status = 'requested'
					this.checkAll();
				}
			},
			showHidePopups(){
				this.popupRequested = false;
				this.$emit('input', false);
			},
			makeInactive(index){
				if (this.cardStatus) {
					this.GetSettings.itcurateds.[index].status = 'pause'
					this.checkAll();
				}
			},
			showManagerForm(){
				this.managerContact = !this.managerContact;
			},
			handleFocusOut() {
				this.managerContact = false;
			},
			sortFunc: function (obj){
				return obj.sort((a, b) => a.pos - b.pos );
			},
			sendMessage(){
				this.errorMessage = false;
				this.messageForm.campaign = this.GetSettings.campaing_id;
				this.messageForm.manager = this.GetSettings.managed_by;
				if (this.messageForm.message.length < 2) {
					this.errorMessage = true;
					setTimeout(() => {
						this.errorMessage = false;
					}, 2000)
				}
				if (!this.errorMessage) {
					window.api.post("campaign_settings/message/", this.messageForm)
						.then(() => {
							setTimeout(() => {
								this.managerContact = false
								this.messageForm = { message: '', campaign: null, manager: this.GetSettings.managed_by }
							}, 500)
						})
						.catch(error => {
							console.log(error)
						})
				}
			}
		}
	}
</script>