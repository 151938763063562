<template lang="pug">
	div.card-box
		.lv-card
			.statistic
				.item
					a(href="https://www.letsverify.com/", target="_blank").lv-logo
						img(src="@/assets/s-lv-logo.svg", alt="")

				.item
					.info.associates
						h5 {{ associates }} Associates 
						p Assigned to this Project

				.item
					.info.green(v-if="cardStatus")
						h5 {{ Number(GetSettings.total_main.leads_generated).toFixed(0) }}
						p Validated and Delivered 
							br
							| to Client

				.item
					.info.blue
						h5 {{ +Number(+GetSettings.total_main.leads_generated).toFixed(0) + +Number(+GetSettings.rejected).toFixed(0) }}
						p Total Leads
							br
							| Generated

				.item
					.info.orange
						h5 {{ Number(GetSettings.doughnutPercents[1] / 100 * GetSettings.total_main.leads_goal).toFixed(0) }}
						p In Process 
							br
							| of Validation

				.item(v-if="cardStatus")
					.info.red
						h5 {{ Number(GetSettings.rejected).toFixed(0) }}
						p Discarded 
							br
							| Leads

				.item(v-if="!cardStatus")
					.info.green-bottom
						h5 {{ Number(GetSettings.total_main.leads_generated).toFixed(0) }}
						p Validated and Delivered 
							br
							| to Client

			.teams
				h6(v-if="GetSettings.teams.length") View the Team:
				h6(v-else) Your Let's Verify Team is expecting the Campaign launch.

				.teams-list
					vuescroll(:ops="ops")
						.list
							.teams-row.first-row
								.team(v-for="(item, index) in GetSettings.teams", v-if="index < teamsInRow")
									.photos
										.single-photo.team-lead(v-if="item.team_lead")
											.lead-photo
												img(:src="item.team_lead.photo_url", alt="")
											.tooltip-lead
												h6 Team Lead
												i Team Performance
												p
													span.green {{ Number(item.delivered_leads).toFixed(0) }}
													| /
													span.yellow {{ Number(item.rejected).toFixed(0) }}

										.single-photo(v-if="item.team_member1")
											img(:src="item.team_member1.photo_url", alt="")

										.single-photo(v-if="item.team_member2")
											img(:src="item.team_member2.photo_url", alt="")

										.single-photo(v-if="item.team_member3")
											img(:src="item.team_member3.photo_url", alt="")

										.single-photo(v-if="item.team_member4")
											img(:src="item.team_member4.photo_url", alt="")

									h4 {{ item.name }}

							.teams-row.second-row
								.team(v-for="(item, index) in GetSettings.teams", v-if="index >= teamsInRow")
									.photos
										.single-photo.team-lead(v-if="item.team_lead")
											.lead-photo
												img(:src="item.team_lead.photo_url", alt="")
											.tooltip-lead
												h6 Team Lead
												i Team Performance
												p
													span.green {{ Number(item.delivered_leads).toFixed(0) }}
													| /
													span.yellow {{ Number(item.rejected).toFixed(0) }}

										.single-photo(v-if="item.team_member1")
											img(:src="item.team_member1.photo_url", alt="")

										.single-photo(v-if="item.team_member2")
											img(:src="item.team_member2.photo_url", alt="")

										.single-photo(v-if="item.team_member3")
											img(:src="item.team_member3.photo_url", alt="")

										.single-photo(v-if="item.team_member4")
											img(:src="item.team_member4.photo_url", alt="")

									h4 {{ item.name }}
</template>

<script>
	import vuescroll from 'vuescroll'

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'LV',
		data() {
			return {
				cardStatus: false,
				ops:{
					bar:{
						background: '#3DA3DB',
						onlyShowBarOnScroll: false,
						keepShow: true,
						size: '2px'
					},
					rail:{
						background: '#BCBEC0',
						opacity: 1,
						size: '2px'
					}
				},
				teamsInRow: 0,
				associates: 0
			}
		},
		components: {
			vuescroll
		},
		created(){
			var sorted = this.sortFunc(this.GetSettings.teams);
			this.updateSettings(['teams', sorted]);
			this.divideTeams();
			this.getCount();
			this.generateLeads();
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "lets_verify") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		watch: {
			'GetSettings.total_main': function () {
				this.generateLeads();
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			sortFunc: function (obj){
				return obj.sort((a, b) => a.name.localeCompare(b.name));
			},
			divideTeams(){
				this.teamsInRow = Math.floor(this.GetSettings.teams.length / 2);
			},
			getCount(){
				for(var item in this.GetSettings.teams){
					var person = this.GetSettings.teams[item];
					if (person.team_lead) {
						this.associates += 1;
					}
					if (person.team_member1) {
						this.associates += 1;
					}
					if (person.team_member2) {
						this.associates += 1;
					}
					if (person.team_member3) {
						this.associates += 1;
					}
					if (person.team_member4) {
						this.associates += 1;
					}
				}
			},
			generateLeads(){
				var rej = 0;
				for(var single in this.GetSettings.teams){
					this.GetSettings.teams[single].rejected = Number(this.GetSettings.teams[single].rejected_percent / 100 * this.GetSettings.total_main.leads_generated).toFixed(0);
					this.GetSettings.teams[single].delivered_leads = Number(this.GetSettings.teams[single].delivered / 100 * this.GetSettings.total_main.leads_generated).toFixed(0);
					rej += +this.GetSettings.teams[single].rejected;
				}
				this.updateSettings(['rejected', rej]);
				this.updateSettings(['teams', this.GetSettings.teams]);
			}
		}
	}
</script>