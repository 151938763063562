<template lang="pug">
	.add-campaing
		.main-form
			.ty(v-bind:class="{'show-ty': success}")
				.go-back(v-on:click="goBack()") Restore
				h3 THANK YOU!
				h5 Your Client Success Team is working on launching your campaign!
			scrolly.scroll.foo
				scrolly-viewport
					.row-inp
						.single-3
							label Customer Information*
							input(
								type="text",
								v-model.trim="campaignForm.customer_information",
								v-bind:class="{error: errorForm.customer_information}"
							).inp

						.single-3
							label Contact Name*
							input(
								type="text",
								v-model.trim="campaignForm.contact_name",
								v-bind:class="{error: errorForm.contact_name}"
							).inp

						.single-3
							label Email*
							input(
								type="text",
								v-model.trim="campaignForm.email",
								v-bind:class="{error: errorForm.email}"
							).inp

					.row-inp
						.single
							label Campaign Name*
							input(
								type="text",
								v-model.trim="campaignForm.name",
								v-bind:class="{error: errorForm.name}"
							).inp

					.row-inp
						.single-2
							label Campaign Start Date
							//- input(
							//- 	type="text",
							//- 	v-model.trim="campaignForm.start_date",
							//- 	v-bind:class="{error: errorForm.start_date}",
							//- 	maxlength="10"
							//- ).inp

							date-picker(
								v-model="startDate",
								type="date",
								change="selectStartDate()",
								:disabled-date="notBeforeToday",
								format="MMMM D, YYYY",
								popup-class="mx-custom",
								:append-to-body="false",
								:editable="false"
							).datepicker

						.single-2
							label Campaign End Date
							//- input(
							//- 	type="text",
							//- 	v-model.trim="campaignForm.end_date",
							//- 	v-bind:class="{error: errorForm.end_date}",
							//- 	maxlength="10"
							//- ).inp

							date-picker(
								v-model="endDate",
								type="date",
								change="selectEndDate()",
								:disabled-date="notBeforeStart",
								format="MMMM D, YYYY",
								popup-class="mx-custom",
								:append-to-body="false",
								:editable="false"
							).datepicker

					.row-inp
						.single-2
							label Campaign Type
							input(
								type="text",
								v-model.trim="campaignForm.campaign_type",
								v-bind:class="{error: errorForm.campaign_type}"
							).inp
							
						.single-2
							label Purchase Order
							input(
								type="text",
								v-model.trim="formOrder",
								v-bind:class="{error: errorForm.order}"
							).inp

					.row-inp
						.single-2
							label Campaign Guarantees
							input(
								type="text",
								v-model.trim="campaignForm.guarantees",
								v-bind:class="{error: errorForm.guarantees}"
							).inp
							
						.single-2
							label Campaign Details
							input(
								type="text",
								v-model.trim="campaignForm.details",
								v-bind:class="{error: errorForm.details}"
							).inp

					.row-inp
						.single
							label Notes:
							textarea(
								v-model.trim="campaignForm.note",
								v-bind:class="{error: errorForm.note}"
							).area-field

					.actions
						button.btn.submit(type="button", v-on:click="saveCampaign()", v-bind:class="{disable: disableBTN}") SUBMIT
						button.btn.cancel(type="button", v-on:click="goBack()") Cancel
				.scroll-bar
				scrolly-bar(axis="y")
</template>

<script>
	import UserBlock from '@/components/User-Action'
	import { Scrolly, ScrollyViewport, ScrollyBar } from 'vue-scrolly'
	import DatePicker from 'vue2-datepicker';
	import { mapMutations } from 'vuex'
	export default {
		name: 'Add-Campaing',
		components: {
			UserBlock,
			Scrolly,
			ScrollyViewport,
			ScrollyBar,
			DatePicker
		},
		data(){
			return {
				success: false,
				errorForm:{
					id: false,
					customer_information: false,
					contact_name: false,
					email: false,
					name: false,
					start_date: false,
					end_date: false,
					campaign_type: false,
					order: false,
					guarantees: false,
					details: false,
					note: false
				},
				error: false,
				startDate: '',
				endDate: '',
				formOrder: '',
				campaignForm:{
					client: '',
					customer_information: '',
					contact_name: '',
					email: '',
					name: '',
					start_date: '',
					end_date: '',
					campaign_type: '',
					guarantees: '',
					details: '',
					note: '',
					order: '',
					kind: 'contract',
					pacing_type: 1,
					part_of_the_map: 3,
					integration_type: 6
				},
				disableBTN: true,
				allCampaigns: null,

			}
		},
		computed: {
			AddCampaign() {
				return this.$store.getters.AddCampaign
			}
		},
		watch: {
			startDate() {
				this.selectStartDate();
			},
			endDate() {
				this.selectEndDate();
			},
			AddCampaign(){
				var id = isNaN(parseInt(this.$store.state.AddCampaign.clientID)) ? '' : parseInt(this.$store.state.AddCampaign.clientID);
				this.campaignForm.client = id;
			}
		},
		created: function(){
			this.getCampaignList();
		},
		methods:{
			...mapMutations(['setSC']),
			getCampaignList(){
				window.api.get('campaign/campaign_list')
					.then(campaigns => {
						this.allCampaigns = campaigns.data;
						this.disableBTN = false;
					})
					.catch(clientsError => {
						console.log(clientsError);
					})
			},
			notBeforeToday(date) {
				return date < window.moment().add(-1, 'day');
			},
			notBeforeStart(date) {
				if (!this.startDate) {
					return date < window.moment().add(-1, 'day');
				}else{
					return date < window.moment(this.startDate);
				}
			},
			selectStartDate(){
				this.campaignForm.start_date = window.moment(this.startDate).format("MM/DD/YYYY");
				if (!this.endDate || this.startDate.getTime() >= this.endDate.getTime()) {
					this.endDate = new Date(window.moment(this.startDate))
				}
			},
			selectEndDate(){
				this.campaignForm.end_date = window.moment(this.endDate).format("MM/DD/YYYY");
			},
			resetErrors(){
				this.error = false;
				this.errorForm = {
					customer_information: false,
					contact_name: false,
					email: false,
					name: false,
					start_date: false,
					end_date: false,
					campaign_type: false,
					order: false,
					guarantees: false,
					details: false,
					note: false
				}
			},
			maskDate(event, model){
				var FS = event.target.value.charAt(0);
				if(FS == ' '){
					this.campaignForm[model] = event.target.value.substring(1)
				}
				var v = event.target.value;
				if (v.match(/^\d{2}$/) !== null) {
					this.campaignForm[model] = v + '/';
				} else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
					this.campaignForm[model] = v + '/';
				}
			},
			validEmail(email) {
				var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return pattern.test(email);
			},
			checkFields(){
				this.campaignForm.client = window.store.state.AddCampaign.clientID;
				setTimeout(() => {
					this.resetErrors()
				}, 3000);
				this.resetErrors();
				if(this.campaignForm.customer_information.length < 2 || this.campaignForm.customer_information.length > 250){
					this.errorForm.customer_information = true;
					// return false;
					this.error = true;
				}
				if(this.campaignForm.contact_name.length < 1 || this.campaignForm.contact_name.length > 250){
					this.errorForm.contact_name = true;
					// return false;
					this.error = true;
				}
				if(this.campaignForm.name.length < 2 || this.campaignForm.name.length > 250){
					this.errorForm.name = true;
					// return false;
					this.error = true;
				}
				// for(var name in this.allCampaigns){
				// 	if (this.allCampaigns[name].name.toLowerCase() == this.campaignForm.name.toLowerCase()) {
				// 		this.errorForm.name = true;
				// 		this.error = true;
				// 	}
				// }
				if (this.formOrder.length > 0) {
					this.campaignForm.order = this.formOrder;
				}
				// if(this.campaignForm.start_date.length != 10){
				// 	this.errorForm.start_date = true;
				// 	return false;
				// }
				// if(this.campaignForm.end_date.length != 10){
				// 	this.errorForm.end_date = true;
				// 	return false;
				// }
				// if(this.campaignForm.campaign_type.length < 2 || this.campaignForm.campaign_type.length > 250){
				// 	this.errorForm.campaign_type = true;
				// 	return false;
				// }
				// if(this.campaignForm.order.length < 1 || this.campaignForm.order.length > 250){
				// 	this.errorForm.order = true;
				// 	return false;
				// }
				// if(this.campaignForm.guarantees.length < 2 || this.campaignForm.guarantees.length > 250){
				// 	this.errorForm.guarantees = true;
				// 	return false;
				// }
				// if(this.campaignForm.details.length < 2 || this.campaignForm.details.length > 250){
				// 	this.errorForm.details = true;
				// 	return false;
				// }
				if (!this.validEmail(this.campaignForm.email)) {
					this.errorForm.email = true;
					// return false;
					this.error = true;
				}
				// return true;
			},
			goBack(){
				this.error = false;
				this.startDate = '';
				this.endDate = '';
				this.formOrder = '';
				this.campaignForm = {
					client: '',
					customer_information: '',
					contact_name: '',
					email: '',
					name: '',
					start_date: '',
					end_date: '',
					campaign_type: '',
					guarantees: '',
					details: '',
					note: '',
					kind: 'contract',
					pacing_type: 1,
					part_of_the_map: 3,
					integration_type: 6
				}
				window.AddC.deleteData();
				setTimeout(() => {this.success = false;})
			},
			saveCampaign(){
				this.disableBTN = true;
				this.checkFields();
				if (!this.error) {
					window.api.post('campaign', this.campaignForm)
					.then(newCampaign => {
						this.success = true;
						if (newCampaign) {
							var activeCampaign = {
								id: newCampaign.data.id,
								name: newCampaign.data.name,
								clientName: window.store.state.AddCampaign.clientName,
								kind: newCampaign.data.kind,
								pacing: null,
								integration: null,
								client: newCampaign.data.id,
							}
							setTimeout(() => {
								this.goBack();
							}, 2500);
							this.setSC(activeCampaign);
							this.$root.$refs.LS.selectClient();
						}
					})
					.catch(newCampaignError => {
						this.errorForm = newCampaignError.response.data;
					})
				}
				setTimeout(() => {
					this.disableBTN = false;
				}, 1000)
			}
		}
	}
</script>