<template lang="pug">
	div.card-box
		.card(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="Assets: Performance & Control", slug="Assets", v-model="cardStatus")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.assets.length)}")
				p Optimize lead engagement with funnel-relevant content assets.

			div(v-if="GetSettings.assets.length")
				.assets-table
					.t-head
						.cl
							p Assets ({{GetSettings.assets.length}})

						.cl
							p Leads

						.cl
							p %

						.cl
							p Landing Page
							
						.cl
							p Titles Captured with the Assets

					.t-row(v-for="(item, index) in GetSettings.assets", v-bind:class="{'active': item.state == 'running'}")
						.cl
							.action-play-pause(
								v-if="item.state && !item.new",
								v-bind:class="{played: item.state == 'running'}"
								v-on:click="changeAssetStatus(index)"
							)
								.play
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
										path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
								.pause
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(width="20" height="20" rx="3").f-f
										rect(x="7" y="5" width="2" height="10" rx="1").f-t
										rect(x="11" y="5" width="2" height="10" rx="1").f-t

							p {{ item.name }}

						.cl
							//- p(v-if="item.percent") {{ Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(0) }}
							p(v-if="item.percent") {{ item.leads_generated }}
							p(v-else) -

						.cl
							p(v-if="item.percent") {{ Number(item.percent).toFixed(0) }}%
							p(v-else) -

						.cl
							a(:href="item.landing_page", v-if="item.landing_page", target="_blank").link Open
								.ico
									svg(xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none")
										path(d="M7 1H2C1.44772 1 1 1.44772 1 2V13C1 13.5523 1.44772 14 2 14H13C13.5523 14 14 13.5523 14 13V8" stroke-linecap="round").stroke
										path(d="M14.5 1C14.5 0.723858 14.2761 0.5 14 0.5L9.5 0.500001C9.22386 0.5 9 0.723858 9 1C9 1.27614 9.22386 1.5 9.5 1.5L13.5 1.5L13.5 5.5C13.5 5.77614 13.7239 6 14 6C14.2761 6 14.5 5.77614 14.5 5.5L14.5 1ZM8.35355 7.35355L14.3536 1.35355L13.6464 0.646447L7.64645 6.64645L8.35355 7.35355Z").fill

							.link(v-else-if="item.landing_page == false", v-on:click="showRequestedPopup()").not-active.pointer Open
								.ico
									svg(xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none")
										path(d="M7 1H2C1.44772 1 1 1.44772 1 2V13C1 13.5523 1.44772 14 2 14H13C13.5523 14 14 13.5523 14 13V8" stroke-linecap="round").stroke
										path(d="M14.5 1C14.5 0.723858 14.2761 0.5 14 0.5L9.5 0.500001C9.22386 0.5 9 0.723858 9 1C9 1.27614 9.22386 1.5 9.5 1.5L13.5 1.5L13.5 5.5C13.5 5.77614 13.7239 6 14 6C14.2761 6 14.5 5.77614 14.5 5.5L14.5 1ZM8.35355 7.35355L14.3536 1.35355L13.6464 0.646447L7.64645 6.64645L8.35355 7.35355Z").fill

							.add-t(v-else, v-on:click="showRequestedPopup()") Add
								.ico
									svg(width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg")
										circle(cx="7.5" cy="7.5" r="7" stroke="#15C179")
										line(x1="7.5" y1="4" x2="7.5" y2="11" stroke="#15C179")
										line(x1="4" y1="7.5" x2="11" y2="7.5" stroke="#15C179")

						.cl
							DropList(
								:list="item.titles",
								:default="item.titles[0].name",
								type="tooltip",
								v-if="item.titles.length"
							)
							p(v-else) Titles captured with this asset
							//- p Titles captured with this asset

						.cl
							.remove-row(v-on:click="removeRow(index)", v-if="!item.percent")

				.chart-container
					.title
						p % of Leads by Asset Downloaded

					.chart
						.legends
							.single(v-for='item in GetSettings.assets', v-if="item.state")
								p {{ item.name }}

						.chart-draw
							.single-line(
								v-for='item in GetSettings.assets',
								v-if="item.state"
								:style='{width: Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(2) / maxNumber * 100 + "%"}'
							)
								//- Number(generatedLeads * (item.percents / 100)).toFixed(0)
								p(
									v-bind:class="{'push': chartWidth * Number(Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(0) / maxNumber * 100).toFixed(2) / 100 < 50 }"
								) {{ Number(item.percent).toFixed(0) }}%

							.chart-info(ref="chart")
								.single
									p 0

								.single
									p {{ maxNumber / maxDivide * 1 }}

								.single
									p {{ maxNumber / maxDivide * 2 }}

								.single
									p {{ maxNumber / maxDivide * 3 }}

								.single
									p {{ maxNumber / maxDivide * 4 }}

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Optimize lead engagement with funnel-relevant content assets.
				
			button.btn.add-new-btn(type="button", v-on:click="addAsset()") ADD A NEW ASSET

		.popup.add-asset-p(v-if="popupAddAsset")
			.close(v-on:click="showHidePopups()")
			.inside-form
				.drop-zone(
					@drop="drop",
					@dragover="dragover",
					@dragleave="dragleave"
				)
					input(
						type="file",
						v-on:change="onChange",
						ref="file").file-picker
					div(v-if="assetFile.length > 0")
						p {{ assetFile[0].name }}
					div(v-else)
						p BROWSE FILE
						span or
							br
							| Drag and Drop file here

				label Name
				input(type="text", placeholder="Text here...", v-model="newAssetName", v-bind:class="{error: newAssetNameError}", autocomplete="xz").inp

				button.btn.simple(type="button", v-on:click="saveAsset()") Apply

		.popup.add-asset-lp(v-if="popupAddLP")
			.close(v-on:click="showHidePopups()")
			.inside-form
				label Landing Page
				input(type="text", placeholder="Text here...", v-model="lpName", autocomplete="xz").inp

				button.btn.simple(type="button", v-on:click="updatedLP()") Apply

		.popup.asset-requested(v-if="popupRequested")
			.close(v-on:click="showHidePopups()")
			.inside-form
				p Your request is submitted. 
					br
					| Our Client Success Manager 
					br
					| will update the campaign shortly.

				button.btn.simple(type="button", v-on:click="showHidePopups()") OK

</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'Assets',
		components:{
			CardHeader,
			DropList
		},
		data() {
			return {
				generatedLeads: 0,
				cardStatus: true,
				defaultTitle: 'Titles captured with this asset',
				maxNumber: 100,
				maxDivide: 4,
				popupAddAsset: false,
				fileDropHover: false,
				assetFile: [],
				newAssetName: '',
				popupAddLP: false,
				keyLP: '',
				lpName: '',
				chartWidth: 0,
				newAssetNameError: false,
				popupRequested: false
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		created(){
			this.setLeads();
			this.sortFunc(this.GetSettings.assets);
			this.setMaxInt();
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "Assets") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		watch: {
			'GetSettings.total_main': function () {
				this.generateLeads();
				// this.setMaxInt();
			}
		},
		mounted() {
			// window.addEventListener('resize', this.handleResize);
			this.handleResize();
		},
		methods: {
			...mapMutations(['updateSettings']),
			generateLeads(){
				if(this.cardStatus){
					for(var item in this.GetSettings.assets){
						if(this.GetSettings.assets[item].state == 'running'){
							var lg =  Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.assets[item].percent / 100)).toFixed(0);
							if (lg <= 0) {
								lg = 0;
							}
							this.GetSettings.assets[item].leads_generated = lg;
						}else{
							if (this.GetSettings.assets[item].leads_generated > 0) {
								this.GetSettings.assets[item].percent = this.GetSettings.assets[item].leads_generated / this.GetSettings.total_main.leads_generated * 100;
							}
						}
					}
				}else{
					for(var single in this.GetSettings.assets){
						this.GetSettings.assets[single].percent = this.GetSettings.assets[single].leads_generated / this.GetSettings.total_main.leads_generated * 100;
					}
				}
				this.updateSettings(['assets', this.GetSettings.assets]);
				this.sortFunc(this.GetSettings.assets);
				this.setMaxInt();
			},
			setLeads(){
				for(var single in this.GetSettings.assets){
					this.GetSettings.assets[single].leads_generated = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.assets[single].percent / 100)).toFixed(0);
				}
				this.updateSettings(['assets', this.GetSettings.assets]);
			},
			handleResize() {
				if(this.GetSettings.assets.length){
					this.chartWidth = this.$refs.chart.clientWidth;
				}
			},
			sortFunc: function (obj){
				return obj.sort((a, b) => b.percent - a.percent);
			},
			setMaxInt(){
				// console.log(this.GetSettings.assets)
				var max = this.maxNumber;
				for (var item in this.GetSettings.assets) {
					var leads = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.assets[item].percent / 100)).toFixed(0);
					var num = parseInt(leads);
					if (max < num && !isNaN(num)) {
						max = num
					}
				}

				var r = parseInt(max.toString()[0]);
				check();
				var x;
				function check(){
					x = r * 100;
					if(x < max){
						r = r + 1;
						check();
					}
				}
				this.maxNumber = x;
			},
			changeAssetStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.assets.[index].state == 'running'){
						this.GetSettings.assets.[index].state = 'pause'
					}else{
						this.GetSettings.assets.[index].state = 'running'
					}
				}
			},
			showHidePopups(){
				this.keyLP = '';
				this.assetFile = [];
				this.newAssetName = '';
				this.lpName = '';
				this.popupRequested = '';
				this.popupAddAsset = false;
				this.popupAddLP = false;
				this.$emit('input', false);
			},
			addAsset(){
				if (this.cardStatus) {
					this.popupAddAsset = !this.popupAddAsset;
					this.$emit('input', this.popupAddAsset);
				}
			},
			onChange() {
				this.assetFile = [...this.$refs.file.files];
				this.newAssetName = this.assetFile[0].name.split('.').slice(0, -1).join('.');
			},
			drop(event) {
				event.preventDefault();
				this.$refs.file.files = event.dataTransfer.files;
				this.onChange();
			},
			dragover() {
				this.fileDropHover = true;
			},
			dragleave(){
				this.fileDropHover = false;
			},
			saveAsset(){
				this.newAssetNameError = false;
				var key =  Object.keys(this.GetSettings.assets).length;
				var pattern = {
					state: "pause",
					name: '',
					leads: '',
					percent: '',
					landing_page: null,
					titles: '',
					new: true
				}
				if (this.newAssetName.length < 2) {
					this.newAssetNameError = true;
				}
				setTimeout(() => {
					this.newAssetNameError = false;
				}, 2000)
				if (!this.newAssetNameError) {
					pattern.name = this.newAssetName;
					this.$set(this.GetSettings.assets, key, pattern);
					this.updateSettings(['assets', this.GetSettings.assets]);
					this.showHidePopups();
				}
			},
			addLP(index){
				if (this.cardStatus) {
					this.keyLP = index;
					this.popupAddLP = !this.popupAddLP;
					this.$emit('input', this.popupAddLP);
				}
			},
			updatedLP(){
				this.GetSettings.assets[this.keyLP].landing_page = this.lpName;
				this.showHidePopups();
			},
			showRequestedPopup(){
				if (this.cardStatus) {
					this.popupRequested = true;
					this.$emit('input', this.popupRequested);
				}
			},
			removeRow(index){
				this.GetSettings.assets.splice(index, 1);
				this.updateSettings(['assets', this.GetSettings.assets]);
			}
		}
	}
</script>