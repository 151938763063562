import { Doughnut } from "vue-chartjs";
export default {
	extends: Doughnut,
	props: ["data", "options"],
	methods: {
		update() {
			this.$data._chart.update()
		}
	},
	mounted() {
		this.renderChart(this.data, this.options);
	}
};