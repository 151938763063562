// for selected campaign
import Vue from 'vue';

export default{
	actions: {
	},
	mutations: {
		setData(state, data){
			state.dashboard = data;
		},
		updateData(state, data){
			Vue.set(state.dashboard, data[0], data[1])
		},
	},
	state: {
		dashboard: null,
	},
	getters:{
		GetDashboard(state){
			return state.dashboard
		},
	}
}