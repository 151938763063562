<template lang="pug">
	div.card-box
		.card.abm(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="Account Based Marketing (ABM)", slug="ABM", v-model="cardStatus", file="https://infusemedia.com/emea/what-we-do/account-based-marketing/")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.abms.length)}")
				p Reach, engage, and deliver leads from your target accounts.

			div(v-if="GetSettings.abms.length")
				.abm-top
					.goal(v-on:click="showPopupGoal()")
						span Goal
						p {{ GetSettings.abm_goal_percent }}%
							b.orange ({{ goalNumber }})

					//- .goal
					//- 	span Goal
					//- 	.goal-row
					//- 		p {{ GetSettings.abm_goal_percent }}%
					//- 		.in-row
					//- 			p.invisible
					//- 				b.orange ({{goalNumber}})
					//- 			.field-absolute
					//- 				.error-tooltip.up(v-bind:class="{show: leadGoalError}")
					//- 					span The lead goal number can not be smaller than the leads generated within this section or larger than the campaign lead goal.
					//- 				p
					//- 					b.orange (
					//- 				input(
					//- 					type="text",
					//- 					v-model="prevNumber",
					//- 					ref="goalEdit"
					//- 					v-on:input="checkGoal()"
					//- 					v-bind:class="{error: leadGoalError}"
					//- 					v-click-outside="handleFocusOut",
					//- 					@keypress="onlyNumber",
					//- 					autocomplete="xz"
					//- 				).goal-inp

					//- 				p
					//- 					b.orange )


					.done
						span Done
							
						p(v-if="!isNaN(donePercent)") {{ Number(donePercent).toFixed(0)}}%
							b.green ({{ doneNumber }})

						p(v-else) 0%
							b.green (0)

					.goal-requested(v-if="GetSettings.goal_requested")
						span Goal Requested
						p {{ GetSettings.goal_requested }}

					//- .goal
					//- 	span Goal
					//- 	.goal-row
					//- 		p {{ GetSettings.abm_goal_percent }}%
					//- 		.in-row
					//- 			p.invisible
					//- 				b.orange ({{goalNumber}})
					//- 			.field-absolute
					//- 				.error-tooltip.up(v-bind:class="{show: leadGoalError}")
					//- 					span The lead goal number can not be smaller 
					//- 						br
					//- 						| than the generated leads number.
					//- 				p
					//- 					b.orange (
					//- 				input(
					//- 					type="text",
					//- 					v-model="prevNumber",
					//- 					ref="goalEdit"
					//- 					v-on:input="checkGoal()"
					//- 					v-bind:class="{error: leadGoalError}"
					//- 					v-click-outside="handleFocusOut",
					//- 					@keypress="onlyNumber",
					//- 					autocomplete="xz"
					//- 				).goal-inp

					//- 				p
					//- 					b.orange )

				.abm-table
					.t-head
						.cl
							p List

						.cl
							p Accounts

						.cl
							p Leads

						.cl
							.chart-range(ref="chart")
								.single
									p 0

								.single
									p {{ maxNumber / maxDivide * 1 }}

								.single
									p {{ maxNumber / maxDivide * 2 }}

								.single
									p {{ maxNumber / maxDivide * 3 }}

								.single
									p {{ maxNumber / maxDivide * 4 }}

								.single
									p {{ maxNumber / maxDivide * 5 }}

					.t-row(v-for="(item, index) in GetSettings.abms", v-bind:class="{'active': item.state == 'running'}", v-if="GetSettings.abms.length")
						.cl
							.action-play-pause(
								v-if="item.state",
								v-bind:class="{played: item.state == 'running'}"
								v-on:click="changeABMStatus(index)"
							)
								.play
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
										path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
								.pause
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(width="20" height="20" rx="3").f-f
										rect(x="7" y="5" width="2" height="10" rx="1").f-t
										rect(x="11" y="5" width="2" height="10" rx="1").f-t

							.in-row
								p.trim-text(:title="item.title") {{ item.title }}
								.file-link

						.cl
							p(v-if="item.accounts_value != 0 && !isNaN(item.accounts_value)") {{ Number(item.accounts_value).toLocaleString('en-US') }}
							p(v-else) -

						.cl
							//- p {{ Number(GetSettings.abm_goal_percent / 100 * GetSettings.total_main.leads_goal * (item.percent / 100)).toFixed(0) }}

							p(v-if="!isNaN(item.leads_generated * 100) && item.leads_generated != 0") {{ Number(item.leads_generated).toFixed(0) }}
							p(v-else) -

						.cl
							.fill-line
								.lines
									.s-line
									.s-line
									.s-line
									.s-line
									.s-line
									.s-line

								//- .fill(:style='{width: goalNumber * (item.percent / 100) / maxNumber * 100 + "%"}')
								//- 	p(
								//- 		v-bind:class="{'push': chartWidth * Number(goalNumber * (item.percent / 100) / maxNumber * 100).toFixed(2) / 100 < 40 }",
								//- 		v-if="item.percent > 0"
								//- 	) {{ Number(item.percent).toFixed(0) }}% 

								.fill(:style='{width: item.leads_generated / maxNumber * 100 + "%"}')
									p(
										v-bind:class="{'push': (item.leads_generated / maxNumber) * chartWidth < 40 }",
										v-if="item.percent > 0"
									) {{ Number(item.percent).toFixed(0) }}% 

						.cl
							.remove-row(v-on:click="removeRow(index)", v-if="!item.percent")

					.t-row(v-bind:class="{'active': GetSettings.abm_look_a_like_state == 'running'}")
						.cl
							.action-play-pause(
								v-bind:class="{played: GetSettings.abm_look_a_like_state == 'running'}"
								v-on:click="changeABMLAStatus()"
							)
								.play
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
										path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
								.pause
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(width="20" height="20" rx="3").f-f
										rect(x="7" y="5" width="2" height="10" rx="1").f-t
										rect(x="11" y="5" width="2" height="10" rx="1").f-t
							.in-row
								p Look-alike

						.cl
							p -

						.cl
							p(v-if="GetSettings.abm_look_a_like") {{ GetSettings.abm_look_a_like }}
							p(v-else) -

						.cl
							.fill-line
								.lines
									.s-line
									.s-line
									.s-line
									.s-line
									.s-line
									.s-line

								.fill-orange(:style='{width: GetSettings.abm_look_a_like / maxNumber * 100 + "%"}')

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Reach, engage, and deliver leads from your target accounts.

			button.btn.add-new-btn(type="button", v-on:click="addList()") ADD A NEW LIST

		.popup.add-asset-p(v-if="popupAddList")
			.close(v-on:click="showHidePopups()")
			.inside-form
				.drop-zone(
					@drop="drop",
					@dragover="dragover",
					@dragleave="dragleave"
				)
					input(
						type="file",
						v-on:change="onChange",
						ref="file").file-picker
					div(v-if="listFile.length > 0")
						p {{ listFile[0].name }}
					div(v-else)
						p BROWSE FILE
						span or
							br
							| Drag and Drop file here

				input(
					type="text",
					placeholder="Number of Accounts in the List",
					v-model="popupAccounts",
					autocomplete="xz",
					v-bind:class="{error: nameError}"
				).inp

				button.btn.simple(type="button", v-on:click="saveList()") Apply

		.popup.change-goal(v-if="popupAddGoal")
			.close(v-on:click="showHidePopups()")
			.inside-form
				label Request your goal
				input(type="text", placeholder="Text here...", v-model="newGoal", v-bind:class="{error: newGoalError}", autocomplete="xz").inp

				button.btn.simple(type="button", v-on:click="saveGoal()") Apply

		.popup.popup-requested(v-if="popupRequested")
			.close(v-on:click="showHidePopups()")
			.inside-form
				p Your request is submitted. 
					br
					| Our Client Success Manager 
					br
					| will update the campaign shortly.

				button.btn.simple(type="button", v-on:click="showHidePopups()") OK

</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'ABM',
		components:{
			CardHeader,
			DropList
		},
		data() {
			return {
				cardStatus: true,
				statistic:{
					goal: 360,
					done: 296
				},
				lookAlike: false,
				newABMList:[
					{
						id: 0,
						name: '',
						list: '',
						status: 'play',
						accounts: '',
						leads: 300,
					}
				],
				maxDivide: 5,
				maxNumber: 100,
				chartWidth: 0,
				popupAddList: false,
				listFile: [],
				popupFileName: '',
				popupAccounts: '',
				totalLeads: 0,
				goalNumber: 0,
				prevNumber: 0,
				doneNumber: 0,
				donePercent: 0,
				leadGoalError: false,
				nameError: false,
				popupAddGoal: false,
				newGoalError: false,
				newGoal: '',
				popupRequested: false
			}
		},
		created(){
			this.goalNumber = Number(this.GetSettings.abm_goal_percent / 100 * this.GetSettings.total_main.leads_goal).toFixed(0);
			this.prevNumber = Number(this.GetSettings.abm_goal_percent / 100 * this.GetSettings.total_main.leads_goal).toFixed(0);
			// this.donePercent = Number(this.doneNumber).toFixed(0) / Number(this.goalNumber).toFixed(0) * 100;
			this.setLeads();
			this.setMaxInt();
			this.calcTotal();
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "ABM") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		watch: {
			'GetSettings.total_main': function () {
				this.generateLeads();
			}
		},
		mounted() {
			this.handleResize();
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			generateLeads(){
				var done = this.doneNumber
				if(this.cardStatus){
					if ((+this.doneNumber + +this.GetSettings.new_velocity) < this.goalNumber) {
						this.doneNumber = +this.doneNumber + +this.GetSettings.new_velocity;
					}else{
						this.doneNumber = this.goalNumber;
					}
					done = this.doneNumber
					this.donePercent = Number(this.doneNumber).toFixed(0) / Number(this.goalNumber).toFixed(0) * 100;

					for(var item in this.GetSettings.abms){
						if(this.GetSettings.abms[item].state == 'running'){
							this.GetSettings.abms[item].leads_generated = Number((this.GetSettings.abms[item].percent / 100) * (this.GetSettings.abm_goal_percent / 100) * this.GetSettings.total_main.leads_generated).toFixed(0);
						}else{
							this.GetSettings.abms[item].percent = this.GetSettings.abms[item].leads_generated / done * 100;
						}
					}
					this.updateSettings(['abms', this.GetSettings.abms]);
				}else{
					for(var single in this.GetSettings.abms){
						this.GetSettings.abms[single].percent = this.GetSettings.abms[single].leads_generated / done * 100;
					}
				}
				
				this.sortFunc(this.GetSettings.abms);
				this.calcTotal();
			},
			setLeads(){
				// var done = this.doneNumber;
				for(var single in this.GetSettings.abms){
					var percent = this.GetSettings.abms[single].percent / 100;
					if (isNaN(percent)) {
						percent = 0;
					}
					// this.GetSettings.abms[single].leads_generated = Number(done * percent).toFixed(0);
					// percentDone += parseInt(this.GetSettings.abms[single].percent);
					this.GetSettings.abms[single].leads_generated = Number((this.GetSettings.abms[single].percent / 100) * (this.GetSettings.abm_goal_percent / 100) * this.GetSettings.total_main.leads_generated);
				}
				this.updateSettings(['abms', this.GetSettings.abms]);
				this.calcTotal();
				this.sortFunc(this.GetSettings.abms);
			},
			sortFunc: function (obj){
				return obj.sort((a, b) => b.percent - a.percent );
			},
			handleResize() {
				if(this.GetSettings.abms.length){
					this.chartWidth = this.$refs.chart.clientWidth;
				}
			},
			calcTotal(){
				this.doneNumber = 0;
				for (var item in this.GetSettings.abms) {
					this.doneNumber += parseInt(this.GetSettings.abms[item].leads_generated);
				}
				this.donePercent = Number(this.doneNumber / this.goalNumber * 100).toFixed(0);
			},
			setMaxInt(){
				var max = this.maxNumber;
				for (var item in this.GetSettings.abms) {
					var num = parseInt(this.GetSettings.abm_goal_percent / 100 * this.GetSettings.total_main.leads_goal * (this.GetSettings.abms[item].percent / 100));
					if (max < num && !isNaN(num)) {
						max = num
					}
					this.totalLeads += parseInt(this.GetSettings.abms[item].accounts_value * (this.GetSettings.abms[item].percent / 100));
				}

				var r = parseInt(max.toString()[0]);
				check();
				var x;
				function check(){
					x = r * 100;
					if(x < max){
						r = r + 1;
						check();
					}
				}
				if (x < this.GetSettings.abm_look_a_like) {
					// this.maxNumber = (parseInt(this.GetSettings.abm_look_a_like.toString()[0]) + 1) * 100;
					this.maxNumber = Number(parseInt(this.GetSettings.abm_look_a_like))
				}else{
					this.maxNumber = x;
				}
			},
			changeABMStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.abms.[index].state == 'running'){
						this.GetSettings.abms.[index].state = 'pause'
					}else{
						this.GetSettings.abms.[index].state = 'running'
					}
				}
			},
			changeABMLAStatus(){
				if (this.cardStatus) {
					if(this.GetSettings.abm_look_a_like_state == 'running'){
						this.GetSettings.abm_look_a_like_state = 'pause'
					}else{
						this.GetSettings.abm_look_a_like_state = 'running'
					}
				}
			},
			showHidePopups(){
				this.popupFileName = '';
				this.popupAccounts = '';
				this.listFile = [];
				this.popupAddGoal = false;
				this.newGoalError = false;
				this.newGoal = '';
				this.popupAddList = false;
				this.popupRequested = false;
				this.$emit('input', false);
			},
			onChange() {
				this.listFile = [...this.$refs.file.files];
				this.popupAccounts = this.listFile[0].name.replace(/[^0-9]/g,'');
				this.popupFileName = this.listFile[0].name.split('.').slice(0, -1).join('.');
			},
			drop(event) {
				event.preventDefault();
				this.$refs.file.files = event.dataTransfer.files;
				this.onChange();
			},
			dragover() {
				this.fileDropHover = true;
			},
			dragleave(){
				this.fileDropHover = false;
			},
			addList(){
				if (this.cardStatus) {
					this.popupAddList = !this.popupAddList;
					this.$emit('input', this.popupAddList);
				}
			},
			saveList(){
				// if(this.popupAccounts.length < 1){
				// 	this.nameError = true;
				// 	setTimeout(() => {
				// 		this.nameError = false;
				// 	}, 2000);
				// }else{
					var key =  Object.keys(this.GetSettings.abms).length;
					var pattern = {
						title: '',
						list: '',
						state: 'pause',
						accounts_value: '',
						leads_generated: 0,
						percent: 0
					}
					pattern.title = this.popupFileName;
					pattern.accounts_value = parseInt(this.popupAccounts);
					if (isNaN(pattern.accounts_value)) {
						pattern.accounts_value = '-';
					}
					this.$set(this.GetSettings.abms, key, pattern);
					this.updateSettings(['abms', this.GetSettings.abms]);
					this.showHidePopups();
					this.handleResize();
				// }
			},
			onlyNumber ($event) {
				let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
					$event.preventDefault();
				}
			},
			checkGoal(){
				if (this.prevNumber < this.doneNumber || this.prevNumber > this.GetSettings.total_main.leads_goal) {
					this.leadGoalError = true;
				}else{
					this.leadGoalError = false;
				}
			},
			handleFocusOut() {
				if (this.prevNumber < this.doneNumber || this.prevNumber > this.GetSettings.total_main.leads_goal) {
					this.prevNumber = this.goalNumber
				}else{
					this.goalNumber = this.prevNumber
					this.GetSettings.abm_goal_percent = Number(this.prevNumber / this.GetSettings.total_main.leads_goal * 100).toFixed(0);
					this.updateSettings(['abm_goal_percent', this.GetSettings.abm_goal_percent]);
				}
				this.leadGoalError = false;
				this.handleResize();
				this.calcTotal();
			},
			removeRow(index){
				this.GetSettings.abms.splice(index, 1);
				this.updateSettings(['abms', this.GetSettings.abms]);
			},
			showPopupGoal(){
				if (this.cardStatus) {
					this.popupAddGoal = !this.popupAddGoal;
					this.$emit('input', this.popupAddGoal);
				}
			},
			saveGoal(){
				this.newGoalError = false;
				if (this.newGoal.length < 1) {
					this.newGoalError = true;
					setTimeout(() => {
						this.newGoalError = false;
					}, 2000)
				}else{
					this.newGoal = this.newGoal + ' ';
					this.newGoal = this.newGoal.substr(0,this.newGoal.indexOf(' '));

					if (this.newGoal.slice(-1) != '%') {
						this.newGoal = this.newGoal + '%';
					}
					this.updateSettings(['goal_requested', this.newGoal]);
					this.popupAddGoal = false;
					this.popupRequested = true;
				}
			}
		}
	}
</script>