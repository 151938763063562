export default{
	init(){
		window.store.commit("setCampaign");
	},
	setData(data){
		localStorage.setItem('clientID', data.clientID);
		localStorage.setItem('clientName', data.clientName);
		localStorage.setItem('status', data.status);
		this.init();
	},
	deleteData(){
		localStorage.removeItem('clientID');
		localStorage.removeItem('clientName');
		localStorage.removeItem('status');
		this.init();
	}
}