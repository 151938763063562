<template lang="pug">
	.forgot-password-page(v-if="showPage")
		form.forgot-password-form
			.logo
				img(src="../../assets/logo.svg", alt="")

			.actions-fields(v-if="!send")
				.field
					input(type="password", placeholder="Password", v-model="passwordForm.password", v-on:change="checkPassword()").inp
				.field
					input(type="password", placeholder="Confirm password", v-model="passwordConfirm", v-on:change="checkPassword()").inp

				span.error-message(v-if="error") Passwords do not match.
				div(v-if="errorMess")
					span.error-message.to-left(v-for="message in errorMess") {{message}}

				.login-action
					button.btn.simple(type="button", v-on:click="resetPassword()") Save password

			.check-mail-message(v-else)
				p Please wait
			
</template>

<script>
	export default {
		name: 'NewPassword',
		data() {
			return {
				errorMess: false,
				passwordForm: {
					password: '',
					token: null
				},
				passwordConfirm: '',
				error: false,
				showPage: false,
				send: false
			}
		},
		created(){
			this.passwordForm.token = new URLSearchParams(window.location.search).get('token');
			if (this.passwordForm.token) {
				this.validateToken();
				// this.showPage = true;
			}
		},
		methods: {
			validateToken(){
				let tokenForm = new FormData();
				tokenForm.append('token', this.passwordForm.token);
				window.api.post("password_reset/validate_token/", tokenForm)
					.then(() => {
						this.showPage = true;
					})
					.catch(error => {
						console.log(error);
						alert('Some error. Please try later.');
						this.$router.push('/forgot-password');
					})
			},
			checkPassword(){
				if(this.passwordForm.password > 4 && this.passwordConfirm > 4){
					console.log(this.passwordForm.password == this.passwordConfirm)
					if(this.passwordForm.password == this.passwordConfirm){
						this.error = false;
					}else{
						this.error = true;
					}
				}
			},
			resetPassword(){
				if(!this.error){
					window.api.post("password_reset/confirm/", this.passwordForm)
						.then(() => {
							this.send = true;
							setTimeout(() => {
								this.$router.push('/login');
							}, 3000);
						})
						.catch(error => {
							this.errorMess = error.response.data.password;
							setTimeout(() => {
								this.errorMess = false;
							}, 4000);
						})
				}
			}
		}
	}
</script>