<template lang="pug">
	.mobile-page
		.logo-mobile
			img(src="@/assets/logo-dashboard.svg", alt="")

		.hours
			p Please use a desktop or laptop
				br
				| computer for this platform.

			img(src="@/assets/Hour.png", alt="")

		.bottom
			p © 2023 INFUSEmedia 
				br
				| All Rights Reserved
			
</template>

<script>
	export default {
		name: 'Mobile'
	}
</script>