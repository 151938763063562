<template lang="pug">
	.audience-page
		.main
			iframe(src="https://app.powerbi.com/view?r=eyJrIjoiMjkyZWQxOTYtMGU4Zi00MWQ2LTk1OTgtMDdiMzVmY2JlOWZkIiwidCI6IjJhOTM4YWI2LThjYzItNGMxNy05NWUwLWIzOGFmYmUxM2U4NyIsImMiOjN9&pageName=ReportSection89678b0d578d9011475b%22")

</template>

<script>
	export default {
		name: 'Audience',
		data() {
			return {
			}
		},
		created(){
		},
		mounted(){
		}
	}
</script>