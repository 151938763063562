import Vue from 'vue'

// import axios from 'axios'
// import VueAxios from 'vue-axios'
// Vue.use(VueAxios, axios);

import App from './App.vue'
import router from './router'
Vue.use(router);
window.router = router;

// import VueRouter from 'vue-router'
// Vue.use(VueRouter)

import Vuex from 'vuex';
Vue.use(Vuex);
window.Vuex = Vuex;

import state from '@/store/state';
import mutation from '@/store/mutation';
Vue.use(mutation);

import Auth from '@/helpers/Auth'
Vue.use(Auth)
window.Auth = Auth;

import Token from '@/helpers/Token'
Vue.use(Token)
window.Token = Token;

import AddC from '@/helpers/AddCampaign'
Vue.use(AddC)
window.AddC = AddC;

import AddDD from '@/helpers/AddDealDesk'
Vue.use(AddDD)
window.AddDD = AddDD;

// import SelectC from '@/helpers/SelectCampaign'
// Vue.use(SelectC)
// window.SelectC = SelectC;

import apiClient from './api-config'
window.api = apiClient;

import * as moment from 'moment';
Vue.use(moment);
window.moment = moment;

import ClickOutside from 'vue-click-outside'
Vue.directive('click-outside', ClickOutside)

// Vue.use(axios);
// import { apiClient } from './api-config'
// Vue.use(apiClient)
// window.apiClient = apiClient;

// const api_url = 'http://95.142.38.46:8081/api/';
// window.api_url = api_url;

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

import rs from './store/modules/right-sidebar'
import SelectedCampaign from './store/modules/select-campaign'
import SettingsPage from './store/modules/settings'
import DashboardPage from './store/modules/dashboard'
import RouterScroll from './store/modules/router-scroll'

const store = new Vuex.Store({
	state,
	mutations: {
		...mutation
	},
	getters: {
		AddCampaign: state => {
			return state.AddCampaign.status
		},
		AddDealDesk: state => {
			return state.AddDealDesk.status
		},
		// SelectedCampaign: state => {
		// 	return state.SelectedCampaign.id
		// }
	},
	modules: {
		rs,
		SelectedCampaign,
		SettingsPage,
		RouterScroll,
		DashboardPage
	}
})

window.store = store;

new Vue({
	router,
	store,
	render: function (h) { return h(App) },
	beforeCreate(){
		Auth.init();
		Token.init();
	},
	created(){
		var interval = 1000 * 60 * 5;
		this.checkLoginTime();
		setInterval(() => {
			this.checkLoginTime();
		}, interval);
	},
	methods: {
		checkLoginTime(){
			var timestamp = new Date().getTime();

			var hours = (timestamp / (1000 * 60 * 60)).toFixed(0);
			var hours_before = localStorage.getItem('hours');

			var day_before = localStorage.getItem('day');
			var today = (timestamp / (1000 * 60 * 60 * 24)).toFixed(0);

			var remember_me = (localStorage.getItem('remember_me') === 'true');
			var diff = 3;
			if (remember_me) {
				diff = 12;
			}

			var allowPage = this.$route.name == 'Forgot' || this.$route.name == 'NewPassword';

			if ((day_before != today || hours >= (+hours_before + +diff)) && !allowPage) {
				window.Token.deletetoken();
				window.Auth.logout();
				// document.location.href="/login/";
				this.$router.push(window.baseURL+'login');
			}
		}
	}
}).$mount('#app')
