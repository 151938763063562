<template lang="pug">
	.forgot-password-page
		form.forgot-password-form
			.logo
				img(src="../../assets/logo.svg", alt="")

			.actions-fields(v-if="!send")
				.field
					input(type="input", placeholder="Email", v-model="forgotForm.email").inp

				span.error-message(v-if="error") Email not found

				.more-actions
					router-link(to="/login").login Back to login

				.login-action
					button.btn.simple(type="button", v-on:click="resetPassword()") Reset Password

			.check-mail-message(v-else)
				p We've sent a link to reset your password to the email.

				.more-actions
					router-link(to="/login").login Back to login

		//- .login-bottom
		//- 	.infuse-logo
		//- 		img(src="../../assets/infuse-logo.svg", alt="")
		//- 	p.copyright © 2021 by INFUSEmedia, All Rights Reserved
			
</template>

<script>
	export default {
		name: 'Forgot',
		data() {
			return {
				forgotForm: {
					email: ''
				},
				error: false,
				send: false
			}
		},
		methods: {
			resetPassword(){
				window.api.post("password_reset/", this.forgotForm)
					.then(() => {
						this.send = true;
					})
					.catch(error => {
						this.error = true;
						setTimeout(() => {
							this.error = false;
						}, 2000);
						console.log(error)
					})
			},
		}
	}
</script>