<template lang="pug">
	div.card-box.set-pt
		.card.no-p-lr
			.campaign-info
				h4 History of the Partnership

				.in-row
					.column
						p(v-if="GetSettings.client.client_since")
							span Our Client since:
							| {{ moment(GetSettings.client.client_since).format('MMMM')}} {{ moment(GetSettings.client.client_since).format('D') }}, {{ moment(GetSettings.client.client_since).format('YYYY') }}
						p(v-else)
							span Our Client since:
							| {{ moment().format('MMMM')}} {{ moment().format('D') }}, {{ moment().format('YYYY') }}

						p
							span Total Campaigns:
							| {{ GetSettings.client.total_campaigns }}

						p
							span Current Campaigns:
							| {{ GetSettings.client.current_campaigns }}

					.column
						p
							span Total Leads Generated:
							| {{ GetSettings.client.leads_generated }}

						p
							span Current Lead Goal:
							| {{ total.leads_goal }}

			.campaign-statistic#fill-here
				.for-table
					p Campaign:

				.descr
					.error-tooltip.down(v-bind:class="{show: limitExceeding}")
						span You are exceeding the limit

					.s-date
						p
							span Start Date: 

						date-picker(
							v-model="startDate",
							type="date",
							change="selectStartDate()",
							:disabled-date="notBeforeToday",
							format="MMMM D, YYYY",
							popup-class="mx-custom",
							:append-to-body="false",
							:editable="false"
						).datepicker

					CampaignList(:list="campaignName", :default="GetSettings.name")

					.s-date
						p
							span End Date: 

						date-picker(
							v-model="endDate",
							type="date",
							change="selectEndDate()",
							:disabled-date="notBeforeToday",
							format="MMMM D, YYYY",
							popup-class="mx-custom",
							:append-to-body="false",
							:editable="false",
						).datepicker

				.table
					.t-head
						.cl
							p Campaign Type

						.cl
							p Lead Goal

						.cl
							p Percent Completion

						.cl
							p Generated Leads

						.cl
							p Remaining Leads

						.action(v-on:click="addNewType()")
							.add

					.t-row.addNew(v-if="addType")
						.cl
							DropBoxInp(
								:list="campaignType",
								v-model="newCM.type_name",
								:default="newCM.type_name.name",
								v-bind:class="{error: newCMerrors.type_name}"
							)

						.cl
							input(
								type="text",
								placeholder="Number of leads...",
								autocomplete="xz",
								v-model="newCM.leads_goal",
								v-bind:class="{error: newCMerrors.leads_goal}"
								@keypress="onlyNumber"
							).inp

						.cl
							DropBox(
								:list="funnel",
								v-model="newCM.grade",
								:default="funnelDefault",
								v-bind:class="{error: newCMerrors.grade}"
							).sq

						.cl
							.btn-row
								input(type="button", value="Save", v-on:click="addNewCM()").s-btn
								button(v-on:click="disableNewCM()").s-btn.delete


					//- .t-row(v-for="(item, index) in customCM", v-bind:class="{ activeEdit: editIndex == index}")
					//- 	.cl
					//- 		p {{ item.type }}

					//- 	.cl
					//- 		.with-act
					//- 			.for-inp
					//- 				.overinp
					//- 				input(
					//- 					type="Text",
					//- 					v-model.trim="item.lead_goal",
					//- 					v-on:blur="editLeadGoal()",
					//- 					:ref="'goal_'+index",
					//- 					v-on:input="checkLeadsInp(index, 'custom')",
					//- 					v-bind:class="{error: leadGoalError == index}"
					//- 				).s-inp
									
					//- 				.error-tooltip.up(v-bind:class="{show: leadGoalError == index}")
					//- 					span The lead goal number can not be smaller 
					//- 						br
					//- 						| than the generated leads number.

					//- 			.minus(v-on:click="editDataShow(index)")

					//- 			.plus(v-on:click="editDataShow(index)")

					//- 		//- .s-btn.update(v-on:click="editDataShow('')") update

					//- 	.cl
					//- 		p {{ item.percent_completion }}%

					//- 	.cl
					//- 		p {{ item.generated_leads }}

					//- 	.cl
					//- 		p {{ item.remaining_leads }}

					//- 	.action
					//- 		.settings(v-bind:class='item.priority', v-on:click="editDataShow(index)")


					.t-row(v-for="(item, index) in GetSettings.targets", v-bind:class="{ activeEdit: editIndex === index}")
						.cl
							p {{ item.type_name }}

						.cl
							.with-act
								.for-inp
									.overinp
									input(
										type="Text",
										v-model="item.leads_goal",
										v-on:blur="editLeadGoal()",
										:ref="'goal_'+index",
										v-on:input="checkLeadsInp(index)",
										autocomplete="xz",
										v-bind:class="{error: leadGoalError === index}",
										@keypress="onlyNumber"
									).s-inp
									
									.error-tooltip.up(v-bind:class="{show: leadGoalError === index}")
										span The lead goal number can not be smaller 
											br
											| than the generated leads number.

								.minus(v-on:click="editDataShow(index)")

								.plus(v-on:click="editDataShow(index)")

						.cl
							p(v-if="item.percent_completion || isNaN(item.percent_completion)") {{ Number(item.percent_completion).toFixed(0) }}%
							p(v-else) -

						.cl
							p(v-if="(item.leads_goal * (item.percent_completion / 100)) != 0") {{ item.leads_generated }}
							p(v-else) -

						.cl.set-flex
							p(v-if="(item.leads_goal - item.leads_goal * (item.percent_completion / 100)) != 0") {{ item.remaining_leads }}
							p(v-else) -

							.remove-target(v-on:click="removeTarget(index)", v-if="GetSettings.targets.length > 1 && (item.leads_goal * (item.percent_completion / 100)) == 0")

						.action
							.settings(v-bind:class='item.grade', v-on:click="editDataShow(index)")

					.t-row.sum
						.cl
							p
								b Total

						.cl
							p
								b {{ total.leads_goal }}

						.cl
							p
								b {{ total.percent_completion }}%

						.cl
							p
								b {{ total.leads_generated }}

						.cl
							p
								b {{ total.remaining_leads }}

			.some-action
				p
					span Integration: 
				//- 	| Integrate
				DropList(:list="integration", :default="GetSettings.integration_type.name", v-model="integration_type", v-if="GetSettings.integration_type")

				.action(v-on:click="showHidePopupIntegration()")

				p
					span Pacing:

				DropList(:list="pacing", :default="GetSettings.pacing_type.name", v-model="pacing_type", v-if="GetSettings.pacing_type")

			.chart-other
				.for-chart
					.chart
						pie-chart(:data="chartData", :options="chartOptions", ref="doughnutUpdate")

					.legend
						.inline
							.fill.blue
							p Delivered
							.line
							p.fix {{chartDataPercent[0]}}%

						.inline
							.fill.orange
							p In Process of Validation
							.line
							p.fix {{chartDataPercent[1]}}%

						.inline
							.fill.green
							p Remaining
							.line
							p.fix {{chartDataPercent[2]}}%

				.targeted-and-engaged
					.targeted
						//- b(v-on:click="changeScroll('JobTitle')").scroll-jt Audience Targeted:
						a(href="./audience", target="_blank") Audience Targeted:
						span {{GetSettings.total_audience.toLocaleString('en-US')}}
						p Contacts in our database 
							br
							| fit the criteria.

					.engaged
						b Engaged Contacts:
						//- span(v-if="GetSettings.engagement_in_process_value") {{ GetSettings.engagement_in.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0}) }}
						span(v-if="GetSettings.engagement_in_process_value") {{ ((total.leads_generated - originalGenerated) + +GetSettings.engagement_in_process_value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0}) }}
						span(v-else) 0
						p Showed interest 
							br
							| in this campaign.

				.types
					div
					.single(v-bind:class="{empty: emptyTop}")
						p TOP
						.line
						.for-btn.top-bt(v-on:click="addNewType('Top')")
							.t-btn
								.plus

					.single(v-bind:class="{empty: emptyMiddle}")
						p MIDDLE
						.line
						.for-btn.middle-bt(v-on:click="addNewType('Middle')")
							.t-btn
								.plus

					.single(v-bind:class="{empty: emptyBottom}")
						p BOTTOM
						.line
						.for-btn.bottom-bt(v-on:click="addNewType('Bottom')")
							.t-btn
								.plus

		.popup.integrate(v-if="popupIntegration")
			.close(v-on:click="showHidePopupIntegration()")
			.stat
				.single
					span Input:
					p {{ GetSettings.total_main.leads_generated }}

				.single
					span Pending:
					p {{ GetSettings.pending }}

				.single
					span Accepted:
					p.orange {{ GetSettings.total_main.leads_generated - GetSettings.pending }}

				.single
					span Remaining:
					p.green {{ GetSettings.total_main.remaining_leads + GetSettings.pending }}

</template>

<script>
	import PieChart from "../components/PieChart.js";
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	import DropList from "../components/drop-list.vue";
	import CampaignList from "../components/campaign-list.vue";
	import DropBox from "../components/drop-box.vue";
	import DropBoxInp from "../components/drop-box-inp.vue";
	import moment from 'moment';

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'Campaign',
		components: {
			PieChart,
			DatePicker,
			DropList,
			CampaignList,
			DropBox,
			DropBoxInp
		},
		data() {
			return {
				// allData: this.$store.getters.GetSettings,
				// editIndex: '',
				pending: 100,
				editIndex: Number,
				addType: false,
				// startDate: new Date(window.moment(this.$store.getters.GetSettings.start_date, "MM/DD/YYYY")),
				// endDate: new Date(window.moment(this.$store.getters.GetSettings.end_date, "MM/DD/YYYY")),
				startDate: new Date(window.moment(this.$store.getters.GetSettings.start_date, "MM/DD/YYYY")),
				endDate: new Date(window.moment(this.$store.getters.GetSettings.end_date, "MM/DD/YYYY")),
				campaignName: [Object, Array],
				campaignType: [Object, Array],
				funnel:{
					0: {name: 'Top'},
					1: {name: 'Middle'},
					2: {name: 'Bottom'}
				},
				funnelDefault: 'Funnel',
				newCM: {
					type_name: {
						id: '',
						name: ''
					},
					grade:{
						name: ''
					},
					leads_goal: '',
					priority: '',
					remaining_leads: 0
				},
				newCMerrors:{
					type_name: false,
					leads_goal: false,
					grade: false
				},
				customCM:{ },
				allCM: {},
				integration: '',
				pacing:{
					0: {name: 'Front-Load'},
					1: {name: 'Even'}
				},
				pacingVal: this.$store.getters.GetSettings.pacing_type,
				defaultPacing: '',
				total:{
					leads_goal: Number,
					percent_completion: Number,
					leads_generated: Number,
					remaining_leads: Number
				},
				boxesState:{
					ct: false,
					leads: false
				},
				chartOptions: {
					cutoutPercentage: 72,
					tooltips:{
						enabled: false
					}
				},
				chartData: {
					datasets: [
						{
							backgroundColor: ["#014165", "#FC9200", "#16AAC4"],
							data: [0, 0, 0],
							borderWidth: 0
						}
					]
				},
				chartDataPercent: [0, 0, 0],
				emptyTop: true,
				emptyMiddle: true,
				emptyBottom: true,
				limitExceeding: false,
				leadGoalError: Number,
				popupIntegration: false,
				beforeGoal: Number,
				integration_type: Object,
				pacing_type: Object,
				percentsPerMinuts: 0,
				originalGenerated: 0,
				TAS: {
					ABM: {
						delta: 0,
						defaultStatus: null
					},
					SuppressionList: {
						delta: 0,
						defaultStatus: null
					},
					JobTitle: {
						delta: 0,
						defaultStatus: null
					},
					Industries: {
						delta: 0,
						defaultStatus: null
					},
					Geo: {
						delta: 0,
						defaultStatus: null
					},
					Revenue: {
						delta: 0,
						defaultStatus: null
					},
					CompanySize: {
						delta: 0,
						defaultStatus: null
					},
					ITCurated: 0
				}
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		watch: {
			startDate() {
				this.selectStartDate();
			},
			endDate() {
				this.selectEndDate();
			},
			integration_type:{
				handler: function() {
					this.updateSettings(['integration_type', this.integration_type]);
				},
				deep: true
			},
			pacing_type:{
				handler: function() {
					this.updateSettings(['pacing_type', this.pacing_type]);
				},
				deep: true
			},
			'GetSettings.trigger': function () {
				this.changeTA();
			}
			// GetSettings: {
			// 	handler: function() {
			// 		if (this.leadGoalError) {
			// 			this.calcTotal();
			// 		}
			// 	},
			// 	deep: true
			// },
			// pacingVal: {
			// 	handler: function() {
			// 		if (this.leadGoalError) {
			// 			this.calcTotal();
			// 		}
			// 	},
			// 	deep: true
			// }
		},
		updated(){
			this.navLi = document.querySelectorAll(".settings-sidebar .scrolly-viewport .item");
		},
		created(){
			this.moment = moment;
			for (var single in this.GetSettings.targets) {
				this.GetSettings.targets[single].percent_completion = Math.round(+this.GetSettings.targets[single].leads_generated / +this.GetSettings.targets[single].leads_goal * 100);
				this.GetSettings.targets[single].remaining_leads = +this.GetSettings.targets[single].leads_goal - +this.GetSettings.targets[single].leads_generated;
			}
			this.createTASector();
			this.defaultPacing = this.pacingVal;
			this.getClientsCampaign();
			this.calcOriginal();
			this.calcTotal();
			window.api.get('/references/integration_types/')
				.then(integration => {
					this.integration = this.sortIntegration(integration.data);
				})
				.catch(integrationError => {
					console.log(integrationError);
				})
			window.api.get('/references/pacing/')
				.then(pacing => {
					this.pacing = pacing.data
				})
				.catch(pacingError => {
					console.log(pacingError);
				})
			window.api.get('/references/types/')
				.then(types => {
					this.campaignType = types.data
				})
				.catch(typesError => {
					console.log(typesError);
				})
			if (!this.GetSettings.pending) {
				this.GetSettings.pending = 0;
				this.updateSettings(['pending', this.GetSettings.pending]);
			}
			if (!this.GetSettings.velocity || this.GetSettings.velocity < 0) {
				this.GetSettings.velocity = 0;
				this.updateSettings(['velocity', this.GetSettings.velocity]);
			}
			// this.createPercent();
			// setInterval(() => this.generateLeads(), 15000)
			setInterval(() => {
				if(this.GetSettings.state == 'running'){
					this.generateLeads();
				}
			}, 3600000)
			// }, 60000)
		},
		methods: {
			...mapMutations(['updateSettings', 'setTarget']),
			changeScroll(id){
				this.navLi.forEach((li) => {
					li.classList.remove("active");
					if (li.getAttribute('my-key') == id) {
						li.classList.add("active");
					}
				});
				this.setTarget(id);
			},
			// createPercent(){
			// 	this.percentsPerMinuts = 0;
			// 	var count = 0;
			// 	for(var item in this.GetSettings.targets){
			// 		if (this.GetSettings.targets[item].percent_completion < 99) {
			// 			count += 1;
			// 		}
			// 	}
			// 	if(count != 0){
			// 		this.percentsPerMinuts = this.GetSettings.velocity / count;
			// 	}
			// },
			generateLeads(){
				var max = 0;
				var next = 0;
				var stringVelocity = Number(this.GetSettings.new_velocity / this.GetSettings.targets.length).toFixed(0)
				var percent;
				if (this.GetSettings.maximum_campaign_completeness) {
					percent = this.GetSettings.maximum_campaign_completeness;
				}else{
					percent = 99;
				}
				percent = percent / 100;
				for(var item in this.GetSettings.targets){
					max = Number(+this.GetSettings.targets[item].leads_goal * percent).toFixed(0);
					next = Number(+this.GetSettings.targets[item].leads_generated + +stringVelocity).toFixed(0);
					var check = max - next;
					if(next < 0){
						next = 0;
					}
					if (check > 0) {
						this.GetSettings.targets[item].leads_generated = next;
						this.GetSettings.targets[item].remaining_leads = Number(this.GetSettings.targets[item].leads_goal - next).toFixed(0);
						this.GetSettings.targets[item].percent_completion = Number(this.GetSettings.targets[item].leads_generated / this.GetSettings.targets[item].leads_goal * 100).toFixed(0);
					}else{
						this.GetSettings.targets[item].leads_generated = max;
						this.GetSettings.targets[item].remaining_leads = this.GetSettings.targets[item].leads_goal - max;
						this.GetSettings.targets[item].percent_completion = Number(this.GetSettings.targets[item].leads_generated / this.GetSettings.targets[item].leads_goal * 100).toFixed(0);
					}
				}
				this.updateSettings(['targets', this.GetSettings.targets]);
				this.calcTotal();
			},
			//
			onlyNumber ($event) {
				let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
					$event.preventDefault();
				}
			},
			notBeforeToday(date) {
				return date < window.moment().add(-1, 'day');
			},
			selectStartDate(){
				var start_date = window.moment(this.startDate).format("MM/DD/YYYY");
				this.updateSettings(['start_date', start_date]);
				if (this.startDate.getTime() >= this.endDate.getTime()) {
					// this.updateSettings(client.data, );
					this.endDate = new Date(window.moment(this.startDate).add(1, 'day'))
					var end_date = window.moment(this.endDate).format("MM/DD/YYYY");
					this.updateSettings(['end_date', end_date]);
				}
			},
			selectEndDate(){
				var end_date = window.moment(this.endDate).format("MM/DD/YYYY");
				this.updateSettings(['end_date', end_date]);
			},
			getClientsCampaign(){
				window.api.get('campaign?client='+this.GetSettings.client.id)
					.then(campaigns => {
						this.campaignName = campaigns.data;
					})
					.catch(campaignsError => {
						console.log(campaignsError);
					})
			},
			sortFunc: function (obj){
				return obj.sort((a, b) => b.grade.localeCompare(a.grade));
			},
			sortIntegration: function (obj){
				return obj.sort((a, b) => a.id - b.id);
			},
			addNewCM(){
				this.newCMerrors.type_name = false;
				this.newCMerrors.leads_goal = false;
				this.newCMerrors.grade = false;
				if (this.newCM.type_name.name.length < 1 || !this.newCM.type_name.name) {
					this.newCMerrors.type_name = true;
				}
				if (this.newCM.leads_goal < 1) {
					this.newCMerrors.leads_goal = true;
				}
				if (this.newCM.grade.name.length <= 1) {
					this.newCMerrors.grade = true;
				}
				setTimeout(() => {
					this.newCMerrors.type_name = false;
					this.newCMerrors.leads_goal = false;
					this.newCMerrors.grade = false;
				}, 1500)
				if (!this.newCMerrors.type_name && !this.newCMerrors.leads_goal && !this.newCMerrors.grade) {
					var searchRes = false;
					for(var search in this.campaignType){
						if (this.campaignType[search].name == this.newCM.type_name.name) {
							searchRes = true;
							this.newCM.type_name.id = this.campaignType[search].id;
						}
					}

					var newCM = {
						type_name: this.newCM.type_name.name,
						pos_type_name: this.newCM.type_name.name,
						leads_goal: this.newCM.leads_goal,
						percent_completion: 0,
						leads_generated: 0,
						remaining_leads: this.newCM.leads_goal,
						grade: this.newCM.grade.name
					}

					if (searchRes) {
						newCM.campaign_pos_type = this.newCM.type_name.id;
					}

					var key = this.GetSettings.targets.length;
					
					var index = this.GetSettings.targets.findIndex(element => element.grade == newCM.grade);
					if (index >= 0) {
						this.GetSettings.targets.splice(index, 0, newCM);
					}else{
						this.GetSettings.targets.splice(key, 0, newCM);
					}

					this.sortFunc(this.GetSettings.targets);
					this.updateSettings(['targets', this.GetSettings.targets]);
					this.calcTotal();
					this.newCM = {
						type_name: {
							id: '',
							name: ''
						},
						pos_type_name: '',
						campaign_pos_type: '',
						leads_goal: '',
						percent_completion: '',
						leads_generated: '',
						grade:{
							name: ''
						},
						remaining_leads: 0
					}
					this.addType = false;
				}
			},
			disableNewCM(){
				this.newCM = {
					type_name: '',
					leads_goal: '',
					percent_completion: '',
					leads_generated: '',
					grade: '',
					remaining_leads: 0
				}
				this.addType = false;
			},
			editDataShow(index){
				this.beforeGoal = this.GetSettings.targets[index].leads_goal;
				this.editIndex = index;
				this.$refs.['goal_'+index][0].focus();
				this.updateSettings(['state', 'pause']);
			},
			editLeadGoal(){
				if (+this.GetSettings.targets[this.editIndex].leads_goal < +this.GetSettings.targets[this.editIndex].leads_generated || +this.GetSettings.targets[this.editIndex].leads_goal < 1) {
					if (this.GetSettings.targets[this.editIndex].leads_goal < 1 && +this.GetSettings.targets[this.editIndex].leads_goal.length > 0) {
						this.GetSettings.targets[this.editIndex].leads_goal = 1;
						this.GetSettings.targets[this.editIndex].remaining_leads = this.GetSettings.targets[this.editIndex].leads_goal - this.GetSettings.targets[this.editIndex].leads_generated;
					}else{
						this.GetSettings.targets[this.editIndex].leads_goal = this.beforeGoal;
						this.GetSettings.targets[this.editIndex].percent_completion = Math.round(this.GetSettings.targets[this.editIndex].leads_generated / this.GetSettings.targets[this.editIndex].leads_goal * 100);
						this.GetSettings.targets[this.editIndex].remaining_leads = this.GetSettings.targets[this.editIndex].leads_goal - this.GetSettings.targets[this.editIndex].leads_generated;
					}
				}
				this.$refs.['goal_'+this.editIndex][0].blur();
				this.updateSettings(['targets', this.GetSettings.targets]);
				this.editIndex = Number;
				this.beforeGoal = Number;
				this.updateSettings(['state', 'running']);
				this.calcTotal();
			},
			checkLeadsInp(index){
				if (+this.GetSettings.targets[this.editIndex].leads_goal < +this.GetSettings.targets[this.editIndex].leads_generated || +this.GetSettings.targets[this.editIndex].leads_goal < 1) {
					this.leadGoalError = index;
				}else{
					this.GetSettings.targets[this.editIndex].percent_completion = Math.round(this.GetSettings.targets[this.editIndex].leads_generated / this.GetSettings.targets[this.editIndex].leads_goal * 100);
					this.GetSettings.targets[this.editIndex].remaining_leads = this.GetSettings.targets[this.editIndex].leads_goal - this.GetSettings.targets[this.editIndex].leads_generated;
					this.leadGoalError = Number;
					this.calcTotal();
				}
			},
			updateDoughnut() {
				this.$refs.doughnutUpdate.update();
			},
			addNewType(type){
				var count = this.GetSettings.targets.length;
				if(count == 10){
					this.limitExceeding = true;
					setTimeout(() => {
						this.limitExceeding = false;
					}, 1500)
				}else{
					if (type) {
						this.addType = true;
						this.newCM.grade = {name: type };
						this.funnelDefault = type;
						setTimeout(() => {
							document.getElementById('fill-here').scrollIntoView({block: "start", behavior: "smooth"});
						}, 250)
					}else{
						this.addType = !this.addType;
						this.newCM.grade = {name: ''};
						this.funnelDefault = 'Funnel';
					}
				}
			},
			changeTA(){

				var baseAudience = this.GetSettings.audience_targeted;

				var zero = 0;

				for (var single in this.GetSettings.sections) {
					var sectorDelta = 0;

					if(this.TAS[this.GetSettings.sections[single].slug]){
						sectorDelta = this.TAS[this.GetSettings.sections[single].slug].delta;

						if (!this.GetSettings.sections[single].enabled) {
							if (this.TAS[this.GetSettings.sections[single].slug].defaultStatus != this.GetSettings.sections[single].enabled) {
								if (sectorDelta > 0) {
									baseAudience = baseAudience - Math.abs(sectorDelta);
								}else{
									baseAudience = baseAudience + Math.abs(sectorDelta);
								}
							}
						}else{
							if (this.TAS[this.GetSettings.sections[single].slug].defaultStatus != this.GetSettings.sections[single].enabled) {
								if (sectorDelta > 0) {
									baseAudience = baseAudience + Math.abs(sectorDelta);
								}else{
									baseAudience = baseAudience - Math.abs(sectorDelta);
								}
							}
						}
					}
				}

				zero += baseAudience;

				this.updateSettings(['total_audience', zero]);
			},
			createTASector(){
				this.TAS.ABM = {
					delta: Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'ABM').delta_ta_sector) / 100)).toFixed(0),
					defaultStatus: this.GetSettings.sections.find(item => item.slug === 'ABM').enabled
				};

				this.TAS.SuppressionList = {
					delta: Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'SuppressionList').delta_ta_sector) / 100)).toFixed(0),
					defaultStatus: this.GetSettings.sections.find(item => item.slug === 'SuppressionList').enabled
				};

				this.TAS.JobTitle = {
					delta: Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'JobTitle').delta_ta_sector) / 100)).toFixed(0),
					defaultStatus: this.GetSettings.sections.find(item => item.slug === 'JobTitle').enabled
				};

				this.TAS.Industries = {
					delta: Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'Industries').delta_ta_sector) / 100)).toFixed(0),
					defaultStatus: this.GetSettings.sections.find(item => item.slug === 'Industries').enabled
				};

				this.TAS.Geo = {
					delta: Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'Geo').delta_ta_sector) / 100)).toFixed(0),
					defaultStatus: this.GetSettings.sections.find(item => item.slug === 'Geo').enabled
				};

				this.TAS.Revenue = {
					delta: Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'Revenue').delta_ta_sector) / 100)).toFixed(0),
					defaultStatus: this.GetSettings.sections.find(item => item.slug === 'Revenue').enabled
				};

				this.TAS.CompanySize = {
					delta: Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'CompanySize').delta_ta_sector) / 100)).toFixed(0),
					defaultStatus: this.GetSettings.sections.find(item => item.slug === 'CompanySize').enabled
				};

				this.TAS.ITCurated = {
					delta: Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'ITCurated').delta_ta_sector) / 100)).toFixed(0),
					defaultStatus: this.GetSettings.sections.find(item => item.slug === 'ITCurated').enabled
				};

				this.updateSettings(['TAS', this.TAS]);

				var baseAudience = this.GetSettings.audience_targeted;

				this.updateSettings(['total_audience', baseAudience]);

				var maxAudience = 0;
				for(var item in this.TAS){
					if ((!this.TAS[item].defaultStatus && this.TAS[item].delta > 0) || (this.TAS[item].defaultStatus && this.TAS[item].delta < 0)) {
							var dl = Math.abs(this.TAS[item].delta)
							maxAudience = +maxAudience + +dl;
					}
				}

				this.updateSettings(['max_audience', (+maxAudience + +baseAudience)]);

				var velocity = this.GetSettings.base_velocity;

				for (var single in this.GetSettings.sections) {
					if (this.GetSettings.sections[single].enabled) {
						velocity += this.GetSettings.sections[single].delta_v_sector;
					}
				}

				if (velocity < 0) {
					velocity = 0;
				}

				this.updateSettings(['new_velocity', velocity]);
			},
			// createTASector(){
			// 	var TAS = {
			// 		ABM: 0,
			// 		SuppressionList: 0,
			// 		JobTitle: 0,
			// 		Industries: 0,
			// 		Geo: 0,
			// 		Revenue: 0,
			// 		CompanySize: 0,
			// 		ITCurated: 0
			// 	};
			// 	// var abm = this.GetSettings.sections.find(item => item.slug === 'ABM');
			// 	// TAS.ABM = this.audience_targeted * abm / 100;
			// 	TAS.ABM = Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'ABM').delta_ta_sector) / 100)).toFixed(0);
			// 	TAS.SuppressionList = Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'SuppressionList').delta_ta_sector) / 100)).toFixed(0);
			// 	TAS.JobTitle = Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'JobTitle').delta_ta_sector) / 100)).toFixed(0);
			// 	TAS.Industries = Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'Industries').delta_ta_sector) / 100)).toFixed(0);
			// 	TAS.Geo = Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'Geo').delta_ta_sector) / 100)).toFixed(0);
			// 	TAS.Revenue = Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'Revenue').delta_ta_sector) / 100)).toFixed(0);
			// 	TAS.CompanySize = Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'CompanySize').delta_ta_sector) / 100)).toFixed(0);
			// 	TAS.ITCurated = Number(this.GetSettings.audience_targeted * ((this.GetSettings.sections.find(item => item.slug === 'ITCurated').delta_ta_sector) / 100)).toFixed(0);
			// 	this.updateSettings(['TAS', TAS]);

			// 	var maxAudience = 0;
			// 	for(var item in TAS){
			// 		if (TAS[item] > 0) {
			// 			maxAudience += +maxAudience + +TAS[item];
			// 		}
			// 	}

			// 	this.updateSettings(['max_audience', (+maxAudience + +this.GetSettings.audience_targeted)]);

			// 	// var currentAudience = 0;
			// 	var baseAudience = this.GetSettings.audience_targeted;

			// 	var velocity = this.GetSettings.base_velocity;
			// 	var zero = 0;

			// 	for (var single in this.GetSettings.sections) {
			// 		if (this.GetSettings.sections[single].enabled) {
			// 			velocity += this.GetSettings.sections[single].delta_v_sector;
			// 		}

			// 		var sectorDelta = 0;

			// 		if(TAS[this.GetSettings.sections[single].slug]){
			// 			sectorDelta = TAS[this.GetSettings.sections[single].slug];

			// 			if (this.GetSettings.sections[single].enabled) {
			// 				baseAudience += +sectorDelta;
			// 			}
			// 		}
			// 	}

			// 	zero += baseAudience;

			// 	this.updateSettings(['total_audience', zero]);
			// 	this.updateSettings(['new_velocity', velocity]);
			// },
			calcOriginal(){
				for (var single in this.GetSettings.targets) {
					this.originalGenerated += +this.GetSettings.targets[single].leads_generated
				}
			},
			calcTotal(){
				this.total = {
					leads_goal: 0,
					percent_completion: 0,
					leads_generated: 0,
					remaining_leads: 0
				}

				for (var single in this.GetSettings.targets) {
					this.total.leads_goal += +this.GetSettings.targets[single].leads_goal;

					this.total.leads_generated += +this.GetSettings.targets[single].leads_generated

					this.total.remaining_leads += +this.GetSettings.targets[single].remaining_leads

					if (this.GetSettings.targets[single].grade == "Top") {
						this.emptyTop = false;
					}
					if (this.GetSettings.targets[single].grade == "Middle") {
						this.emptyMiddle = false;
					}
					if (this.GetSettings.targets[single].grade == "Bottom") {
						this.emptyBottom = false;
					}
				}
				// this.total.percent_completion = Math.round(this.total.leads_generated / this.total.leads_goal * 100);
				var pc = Math.round(this.total.leads_generated / this.total.leads_goal * 100);
				if (isNaN(pc)) {
					pc = 0;
				}
				this.total.percent_completion = pc;

				this.updateSettings(['total_main', this.total]);

				var Delivered = Math.round(this.total.leads_generated / this.total.leads_goal * 100);
				if (isNaN(Delivered)) {
					Delivered = 0;
				}
				var sum = Math.round(this.total.remaining_leads / this.total.leads_goal * 100)
				var percent;
				if (this.defaultPacing == 'even') {
					percent = 30;
				}else{
					percent = 40;
				}

				var UnderVerification;
				var Remaining;

				if(this.GetSettings.teams.length > 0){
					UnderVerification = Math.round(sum * percent / 100);
					Remaining = Math.round(sum - UnderVerification);
				}else{
					UnderVerification = 0;
					Remaining = 100;
				}

				this.chartDataPercent = [Delivered, UnderVerification, Remaining];
				this.updateSettings(['doughnutPercents', [Delivered, UnderVerification, Remaining]]);
				this.chartData.datasets[0].data = this.chartDataPercent;
				this.$nextTick(function () {
					this.updateDoughnut();
				});
			},
			showHidePopupIntegration(){
				this.popupIntegration = !this.popupIntegration;
				this.$emit('input', this.popupIntegration);
			},
			removeTarget(index){
				this.GetSettings.targets.splice(index, 1);
				this.updateSettings(['targets', this.GetSettings.targets]);
				this.calcTotal();
			}
		}
	}
</script>