<template lang="pug">
	.integrate-popup
		.close(v-on:click="hide()")
		h4 Integrated with:
		.select-list
			.single-item
				input(type="radio", name="integrate-with").ch#ch-salesforce
				label(for="ch-salesforce").check-container
					.name
						h6 Salesforce

						.ico

					.logo-i
						.salesforce

			.single-item
				input(type="radio", name="integrate-with").ch#ch-marketo
				label(for="ch-marketo").check-container
					.name
						h6 Marketo

						.ico

					.logo-i
						.marketo

			.single-item
				input(type="radio", name="integrate-with").ch#ch-hubspot
				label(for="ch-hubspot").check-container
					.name
						h6 HubSpot

						.ico

					.logo-i
						.hubspot

			.single-item
				input(type="radio", name="integrate-with").ch#ch-sharpspring
				label(for="ch-sharpspring").check-container
					.name
						h6 SharpSpring

						.ico

					.logo-i
						.sharpspring

			.single-item.custom-item
				input(type="radio", name="integrate-with").ch#ch-custom
				label(for="ch-custom").check-container
					.name
						h6 Custom 
							br
							| Integration

						.ico

					.logo-i
						p {{ $store.state.SelectedCampaign.campaignClient }}
						.custom

			router-link(to="/settings", target="_blank").for-btn
				button.btn.arrow(type="button")

</template>

<script>
	export default {
		name: 'IntegratePopup',
		data(){
			return{
				selectedCampaign: Object
			}
		},
		methods: {
			hide(){
				this.$emit('input', false);
			}
		}
	}
</script>