<template lang="pug">
	.settings-page(v-bind:class="{blur: blurPage}", v-if="load")
		.scroll-set#test
			Header(v-model='blurPage')
			Campaign#Campaign(v-model='blurPage')
			Assets#Assets(v-model='blurPage')
			IntentFeed#IntentFeed(v-model='blurPage')
			ABM#ABM(v-model='blurPage')
			Suppression#SuppressionList(v-model='blurPage')
			JobTitles#JobTitle(v-model='blurPage')
			Industries#Industries(v-model='blurPage')
			GeographicalLocations#Geo(v-model='blurPage')
			Revenue#Revenue(v-model='blurPage')
			CompanySize#CompanySize(v-model='blurPage')
			<!-- BantQustions#BANT(v-model='blurPage') -->
			CustomQuestions#CQ(v-model='blurPage')
			InstallBase#InstallBase(v-model='blurPage')
			ITCurated#ITCurated(v-model='blurPage')
			TacticsActivated#Tactics
			FairTrade#Fair-TradeLeadQualification(v-model='blurPage')
			div(id="LeadCascadeProgram'")
			LCP#LeadCascadeProgram(v-model='blurPage')
			Nurturing#Nurturing(v-model='blurPage')
			LV#lets_verify
			Creatives#Creatives(v-model='blurPage')
			Footer

</template>

<script>
	import { Scrolly, ScrollyViewport, ScrollyBar } from 'vue-scrolly';
	import Header from './components/header'
	import Campaign from "./campaign/";
	import Assets from "./assets/";
	import IntentFeed from "./intent-feed/"
	import ABM from "./abm/"
	import Suppression from "./suppression-list/"
	import JobTitles from "./job-titles/"
	import Industries from "./industries/"
	import GeographicalLocations from "./geographical-locations/"
	import Revenue from "./revenue/"
	import CompanySize from './company-size/'
	import BantQustions from './bant-questions/'
	import CustomQuestions from './custom-questions/'
	import InstallBase from './install-base/'
	import ITCurated from './itcurated/'
	import TacticsActivated from './tactics-activated/'
	import FairTrade from './fair-trade/'
	import LCP from './lead-cascade-program/'
	import Nurturing from './nurturing/'
	import LV from './lets-verify/'
	import Creatives from './creatives/'
	import Footer from './components/footer'

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'Settings',
		components: {
			Scrolly,
			ScrollyViewport,
			ScrollyBar,
			Header,
			Campaign,
			Assets,
			IntentFeed,
			ABM,
			Suppression,
			JobTitles,
			Industries,
			GeographicalLocations,
			Revenue,
			CompanySize,
			BantQustions,
			CustomQuestions,
			InstallBase,
			ITCurated,
			FairTrade,
			LCP,
			Nurturing,
			TacticsActivated,
			LV,
			Creatives,
			Footer,
			settingsData: []
		},
		data() {
			return {
				blurPage: false,
				cliendID: false,
				campaignID: false,
				client: [],
				settings: [],
				load: false,
				sections: '',
				startSearch: true
			}
		},
		computed: mapGetters(['GetSettings', 'GetTarget']),
		// computed: {
		// 	GetSettings(){
		// 		return this.$store.getters.GetSettings;
		// 	},
		// 	// GetClient(){
		// 	// 	return this.$store.getters.GetClient.client;
		// 	// },
		// },
		watch: {
			GetSettings(){
				this.settings = this.$store.state.GetSettings;
			},
			'GetSettings.changeCampaign': function () {
				if (this.GetSettings.changeCampaign) {
					// this.load = true;
					this.updateSettings(['changeCampaign', false]);
					this.campaignID = localStorage.getItem('campaign_id');
					this.getSettings();
					this.load = false;
				}
			},
			// load: function(){
			// 	this.setScroll();
			// },
			'GetTarget': function () {
				this.scroll();
				// this.setMaxInt();
			}
			// GetClient(){
			// 	this.client = this.$store.state.GetClient.client;
			// },
		},
		created: function(){
			if (localStorage.getItem('cliend_id')) {
				this.cliendID = localStorage.getItem('cliend_id');
				// this.getClient();
			}
			if (localStorage.getItem('campaign_id')) {
				this.campaignID = localStorage.getItem('campaign_id');
				this.getSettings();
			}
			
			if (localStorage.getItem('route')) {
				this.route = localStorage.getItem('route');
			}

			this.scrollInterval = setInterval(this.scrollToSection, 250);
		},
		mounted() {
			// this.$nextTick(() => {
			// 	const el = document.querySelector(this.$route.hash);
			// 	el && el.scrollIntoView();
			// })
			window.addEventListener('scroll', this.handleScroll);
			window.addEventListener('wheel', this.handleScroll);
		},
		updated(){
			this.sections = document.querySelectorAll(".card-box");
			this.navLi = document.querySelectorAll(".settings-sidebar .scrolly-viewport .item");
		},
		methods: {
			...mapMutations(['updateSettings']),
			sortClients: function (obj){
				return obj.sort(function(a, b) {
					var nameA = a.name.toUpperCase();
					var nameB = b.name.toUpperCase();
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					return 0;
				})
			},
			handleScroll () {
				if(this.startSearch){
					this.startSearch = false;
					var offset = window.innerHeight / 3;
					var scroll = document.getElementById('test').scrollTop;
					var current = "";
					this.sections.forEach((section) => {
						const sectionTop = document.getElementById(section.id).offsetTop;
						if (scroll >= sectionTop - offset){
							current = section.getAttribute("id");
						}
					});

					this.navLi.forEach((li) => {
						li.classList.remove("active");
						if (li.getAttribute('my-key') == current) {
							li.classList.add("active");
						}
					});
					setTimeout(() => {
						this.startSearch = true;
					}, 200)
				}
			},
			...mapMutations(['setSettings', 'setClient', 'setTarget']),
			// getClient(){
			// 	window.api.get('client/'+this.cliendID+'/')
			// 		.then(client => {
			// 			this.setClient(client.data);
			// 		})
			// 		.catch(clientError => {
			// 			console.log(clientError);
			// 		})
			// },
			sortFunc: function (obj){
				return obj.sort((a, b) => b.grade.localeCompare(a.grade));
			},
			sortSidebar: function (obj){
				return obj.sort((a, b) => a.id - b.id );
			},
			sortNurturing: function (obj){
				return obj.sort((a, b) => a.id - b.id );
			},
			// fixLCP(obj){
			// 	var index = obj.findIndex(element => element.slug == "LeadCascadeProgram'");
			// 	if (index) {
			// 		this.settings.data.sections[index].slug = 'LeadCascadeProgram';
			// 	}
			// },
			getSettings(){
				var bantPos = {
					'budget': 0,
					'authority': 1,
					'need': 2,
					'time': 3,
				}
				window.api.get('campaign/'+this.campaignID+'/settings_campaign/')
					.then(settings => {
						this.sortFunc(settings.data.targets);
						this.sortSidebar(settings.data.sections);
						// var index = settings.data.sections.findIndex(element => element.slug == "LeadCascadeProgram'");
						// if (index > 0) {
						// 	settings.data.sections[index].slug = 'LeadCascadeProgram';
						// }
						// var indexLV = settings.data.sections.findIndex(element => element.slug == "lets_verify");
						// if (indexLV > 0) {
						// 	settings.data.sections[indexLV].name = "Let's Verify";
						// }
						for (var section in settings.data.sections) {
							if(settings.data.sections[section].slug == "LeadCascadeProgram'"){
								settings.data.sections[section].slug = 'LeadCascadeProgram';
							}
							if(settings.data.sections[section].slug == "lets_verify"){
								settings.data.sections[section].name =  "Let's Verify";
							}
							if(settings.data.sections[section].name == "Suppression list(s)"){
								settings.data.sections[section].name =  "Suppression List(s)";
							}
							if(settings.data.sections[section].name == "Suppression list(s)"){
								settings.data.sections[section].name =  "Suppression List(s)";
							}
						}
						if (!settings.data.velocity || settings.data.velocity < 0) {
							settings.data.velocity = 0;
						}
						if (!settings.data.TA || settings.data.TA < 0) {
							settings.data.TA = 0;
						}
						for(var bant in settings.data.bants){
							settings.data.bants[bant].question.pos = bantPos.[settings.data.bants[bant].question.kind];
						}
						this.setSettings(settings.data);
						var engagement_in = +settings.data.engagement_in_process_value + (settings.data.base_velocity * 2);
						this.updateSettings(['engagement_in', engagement_in]);
						this.updateSettings(['campaing_id', this.campaignID]);
						window.api.get('references/nurturing_stages/')
							.then(nurturing => {
								this.updateSettings(['nurturing_stages', this.sortNurturing(nurturing.data)]);
							})
							.catch(nurturingError => {
								console.log(nurturingError);
							})
						window.api.get('references/revenue/')
							.then(revenue => {
								this.updateSettings(['revenue_list', revenue.data]);
							})
							.catch(revenueError => {
								console.log(revenueError);
							})
						window.api.get('references/company_size/')
							.then(company_size => {
								this.updateSettings(['company_size_list', company_size.data]);
							})
							.catch(company_sizeError => {
								console.log(company_sizeError);
							})
						window.api.get('client')
							.then(clients => {
								this.updateSettings(['clients', this.sortClients(clients.data)]);
							})
							.catch(clientsError => {
								console.log(clientsError);
							})
						setTimeout(() => {
							this.load = true;
						}, 500);
					})
					.catch(settingsError => {
						console.log(settingsError);
					})
				// window.api.get('references/nurturing_stages/')
				// 	.then(nurturing => {
				// 		this.updateSettings(['nurturing_stages', nurturing.data]);
				// 	})
				// 	.catch(nurturingError => {
				// 		console.log(nurturingError);
				// 	})
			},
			fixNumbers(){
				if (!this.settings.data.velocity || this.settings.data.velocity < 0) {
					this.settings.data.velocity = 0;
				}
				if (!this.settings.data.TA || this.settings.data.TA < 0) {
					this.settings.data.TA = 0;
				}
			},
			// setScroll(){
			// 	setTimeout(() => {
			// 		this.$nextTick(function() {
			// 			if(window.location.hash) {
			// 				this.setTarget(window.location.hash.substring(1));
			// 				window.router.push('settings');
			// 			}
			// 		})
			// 	}, 250);
			// },
			scrollToSection(){
				if(window.location.hash) {
					var target = window.location.hash.substring(1);
					if(target){
						document.getElementById(target).scrollIntoView({block: "start", behavior: "smooth"});
						setTimeout(()=>{
							window.router.push('settings');
							clearInterval(this.scrollInterval);
						},1000)
					}
				}else{
					clearInterval(this.scrollInterval);
				}
			},
			scroll(){
				setTimeout(() => {
					var dest = this.GetTarget;
					if(dest){
						document.getElementById(dest).scrollIntoView({block: "start", behavior: "smooth"});
					}
					this.setTarget(null);
				}, 250)
			}
		}
	}
</script>