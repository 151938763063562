<template lang="pug">
	div.card-box
		.card.lcp(v-bind:class="{disable: !cardStatus}")
			CardHeader(
				:status="cardStatus",
				title="Lead Cascade Program",
				v-model="cardStatus"
				type="tooltip",
				slug="LeadCascadeProgram",
				:tooltipText="tooltipText",
				file="https://hourglass.infusemedia.com/staticfiles/Lead_Cascade.pdf"
			)
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.lead_cascades.length)}")
				p Do not discard good leads. Keep them engaged until they are primed for conversion.

			div(v-if="GetSettings.lead_cascades.length")
				.fl-title
					h4 Leads qualified at different levels, saved for the client:

				.file-list
					.single(v-for="(item, index) in GetSettings.lead_cascades", v-bind:class="{disabled: item.state == 'pause'}")
						.action-play-pause(
							v-on:click="changeStatus(index)"
							v-bind:class="{played: item.state == 'running'}"
						)
							.play
								svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
									rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
									path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
							.pause
								svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
									rect(width="20" height="20" rx="3").f-f
									rect(x="7" y="5" width="2" height="10" rx="1").f-t
									rect(x="11" y="5" width="2" height="10" rx="1").f-t

						.info
							p.name {{ item.name }}
							p.stat(v-if="item.percent != 0")
								b {{ Number(GetSettings.total_main.leads_generated / 100 * item.percent).toFixed(0) }}
								| Leads

							p.stat(v-else)
								b {{ item.leads_cascade }}
								| Leads

							.remove-row(v-on:click="removeRow(index)", v-if="item.new || Number(GetSettings.total_main.leads_generated / 100 * item.percent).toFixed(0) == 0")

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Do not discard good leads. Keep them engaged until they are primed for conversion.

			button.btn.add-new-btn(type="button", v-on:click="addLCP()") Add a Lead Cascade Option

		.popup.add-lcp(v-if="popupAddLCP")
			.close(v-on:click="showHidePopups()")
			.inside-form
				label Leads Description
				input(type="text", placeholder="Text here...", v-model="leadsDescription", v-bind:class="{error: leadsDescriptionError}", autocomplete="xz").inp

				button.btn.simple(type="button", v-on:click="saveLCP()") APPLY


</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'LCP',
		components:{
			CardHeader,
			DropList
		},
		data() {
			return {
				cardStatus: false,
				tooltipText: '<p>Discarding leads that don’t fit a specific campaign’s every qualification criteria is wasteful. <b>Why?</b></p><p><b>Because those leads might be the perfect fit for a future campaign.</b> Instead of abandoning them, we will turn these bottom-of-the funnel prospects into advocates of your brand and keep them engaged—at a fraction of the cost to you—until they are primed for conversion.</p>',
				popupAddLCP: false,
				leadsDescription: '',
				leadsDescriptionError: false
			}
		},
		created(){
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "LeadCascadeProgram") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			changeStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.lead_cascades.[index].state == 'running'){
						this.GetSettings.lead_cascades.[index].state = 'pause'
					}else{
						this.GetSettings.lead_cascades.[index].state = 'running'
					}
				}
			},
			showHidePopups(){
				this.leadsDescription = '';
				this.popupAddLCP = false;
				this.$emit('input', false);
			},
			addLCP(){
				if (this.cardStatus) {
					this.popupAddLCP = !this.popupAddLCP;
					this.$emit('input', this.popupAddLCP);
				}
			},
			saveLCP(){
				var key =  Object.keys(this.GetSettings.lead_cascades).length;
				var pattern = {
					leads_cascade: 0,
					name: this.leadsDescription,
					percent: 0,
					state:"pause",
					new: true
				}
				if (this.leadsDescription.length < 2) {
					this.leadsDescriptionError = true;
					setTimeout(() => {
						this.leadsDescriptionError = false;
					}, 2000)
				}
				if (!this.leadsDescriptionError) {
					this.$set(this.GetSettings.lead_cascades, key, pattern);
					this.updateSettings(['lead_cascades', this.GetSettings.lead_cascades]);
					this.showHidePopups();
				}
			},
			removeRow(index){
				this.GetSettings.lead_cascades.splice(index, 1);
				this.updateSettings(['lead_cascades', this.GetSettings.lead_cascades]);
			}
		}
	}
</script>