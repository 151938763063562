<template lang="pug">
	.drop-list(v-bind:class="{opened: open}", v-click-outside="handleFocusOut")
		.visible(v-on:click="open = !open")
			p.biger {{ active }}
		.list
			span(v-for="item in list", v-on:click="select(item)", v-bind:class="{text: type == 'tooltip'}") {{ item.name }}
</template>

<script>
	import { mapMutations } from 'vuex'

	export default {
		props: {
			list: [Object, Array, String],
			default: String,
			type: String
		},
		data() {
			return {
				active: this.default,
				open: false
			}
		},
		methods: {
			...mapMutations(['updateSettings']),
			select(data){
				if (this.type != 'tooltip') {
					this.open = false;
					this.active = data.name;
					this.$emit('input', data);
				}
			},
			selectCampaign(data){
				localStorage.setItem('campaign_id', data.id);
				this.updateSettings(['changeCampaign', true]);
			},
			handleFocusOut() {
				this.open = false;
			}
		}
	}
</script>