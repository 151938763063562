<template lang="pug">
	div.card-box
		.card.revenue(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="Revenue", slug="Revenue", v-model="cardStatus")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.revenues.length)}")
				p Provide company revenue range to ensure precise targeting.

			.revenue-table(v-if="GetSettings.revenues.length")
				.t-head
					.cl
						p Revenue

					.cl
						p Leads

					.cl
						.chart-range(ref="chart")
							.single
								p 0

							.single
								p {{ Number(maxNumber / maxDivide * 1).toFixed(0) }}

							.single
								p {{ Number(maxNumber / maxDivide * 2).toFixed(0) }}

							.single
								p {{ Number(maxNumber / maxDivide * 3).toFixed(0) }}

							.single
								p {{ Number(maxNumber / maxDivide * 4).toFixed(0) }}

							.single
								p {{ Number(maxNumber / maxDivide * 5).toFixed(0) }}

				.t-row(v-for="(item, index) in GetSettings.revenues", v-bind:class="{'active': item.state == 'running'}")
					.cl
						.action-play-pause(
							v-if="item.state",
							v-bind:class="{played: item.state == 'running'}"
							v-on:click="changeRevenueStatus(index)"
						)
							.play
								svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
									rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
									path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
							.pause
								svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
									rect(width="20" height="20" rx="3").f-f
									rect(x="7" y="5" width="2" height="10" rx="1").f-t
									rect(x="11" y="5" width="2" height="10" rx="1").f-t

						p(v-if="item.name") {{ item.name }}
						p(v-else) {{ item.user_revenue }}

					.cl
						p(v-if="item.percent") {{ item.leads_revenue }}
						p(v-else) -

					.cl
						.fill-line(v-bind:class="{ 'cl-height': !item.percent }")
							.lines
								.s-line
								.s-line
								.s-line
								.s-line
								.s-line
								.s-line

							.fill(:style='{width: Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(0) / maxNumber * 100 + "%"}', v-if="item.percent != 0")
								p(
									v-bind:class="{'push': chartWidth * Number(Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(0) / maxNumber * 100).toFixed(2) / 100 < 40 }"
								) {{ Number(item.percent).toFixed(2) }}% 

					.cl
						.remove-row(v-on:click="removeRow(index)", v-if="!item.leads_revenue || item.leads_revenue == 0")

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Provide company revenue range to ensure precise targeting.

			button.btn.add-new-btn(type="button", v-on:click="addRevenue()") ADD RANGE

		.popup.new-revenue(v-if="popupAddRevenue")
			.close(v-on:click="showHidePopups()")
			.inside-form
				.in-row
					.cl.full
						label Revenue Title
						DropBoxInp(
							:list="GetSettings.revenue_list",
							:default="newRevenueNameOBJ.revenue_type.name",
							v-model="newRevenueNameOBJ.revenue_type",
							v-bind:class="{error: newRevenueNameError}"
						)

						button.btn.simple(type="button", v-on:click="saveRevenue()") Apply
</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";
	import DropBoxInp from "../components/drop-box-inp.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'Revenue',
		components:{
			CardHeader,
			DropList,
			DropBoxInp
		},
		data() {
			return {
				generatedLeads: 719,
				cardStatus: true,
				revenueList:[
					{
						id: 0,
						revenue: 'Under $5M',
						leads: '',
						percents: '',
						status: 'pause'
					},
					{
						id: 1,
						revenue: 'Small and Medium-Sized Businesses—$5-10M',
						leads: '',
						percents: '',
						status: 'play'
					},
					{
						id: 2,
						revenue: 'Small and Medium Enterprises—$10M- $1B',
						leads: '',
						percents: '34.39',
						status: 'play'
					},
					{
						id: 3,
						revenue: 'Large Enterprises—Over $1B',
						leads: '',
						percents: '65.61',
						status: 'play'
					},
					{
						id: 4,
						revenue: 'Custom Range: $1-2M',
						leads: '',
						percents: '',
						status: 'play'
					},
				],
				chartWidth: '',
				maxDivide: 5,
				maxNumber: 100,
				emptyGoal: true,
				newRevenueName: String,
				popupAddRevenue: false,
				newRevenueNameError: false,
				newRevenueNameOBJ: {
					revenue_type: ''
				}
			}
		},
		created(){
			this.setLeads();
			this.sortFunc(this.GetSettings.revenues);
			this.updateSettings(['revenues', this.GetSettings.revenues]);
			this.setMaxInt();
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "Revenue") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		mounted() {
			// window.addEventListener('resize', this.handleResize);
			this.handleResize();
		},
		watch: {
			'GetSettings.total_main': function () {
				this.generateLeads();
				// this.setMaxInt();
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			generateLeads(){
				if(this.cardStatus){
					for(var item in this.GetSettings.revenues){
						if(this.GetSettings.revenues[item].state == 'running'){
							this.GetSettings.revenues[item].leads_revenue = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.revenues[item].percent / 100)).toFixed(0)
						}else{
							this.GetSettings.revenues[item].percent = this.GetSettings.revenues[item].leads_revenue / this.GetSettings.total_main.leads_generated * 100;
						}
					}
				}else{
					for(var single in this.GetSettings.revenues){
						this.GetSettings.revenues[single].percent = this.GetSettings.revenues[single].leads_revenue / this.GetSettings.total_main.leads_generated * 100;
					}
				}
				this.updateSettings(['revenues', this.GetSettings.revenues]);
				this.sortFunc(this.GetSettings.revenues);
				this.setMaxInt();
			},
			setLeads(){
				for(var single in this.GetSettings.revenues){
					this.GetSettings.revenues[single].leads_revenue = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.revenues[single].percent / 100)).toFixed(0);
				}
				this.updateSettings(['revenues', this.GetSettings.revenues]);
			},
			sortFunc: function (obj){
				return obj.sort((a, b) => b.leads_revenue - a.leads_revenue );
			},
			handleResize() {
				if(this.GetSettings.revenues.length){
					this.chartWidth = this.$refs.chart.clientWidth;
				}
			},
			setMaxInt(){
				var max = this.maxNumber;
				for (var item in this.GetSettings.revenues) {
					var leads = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.revenues[item].percent / 100)).toFixed(0);
					var num = parseInt(leads);
					if (max < num && !isNaN(num)) {
						max = num
					}
				}

				var r = parseInt(max.toString()[0]);
				check();
				var x;
				function check(){
					x = r * 50;
					if(x < max){
						r = r + 1;
						check();
					}
				}
				this.maxNumber = x;
			},
			changeRevenueStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.revenues.[index].state == 'running'){
						this.GetSettings.revenues.[index].state = 'pause'
					}else{
						this.GetSettings.revenues.[index].state = 'running'
					}
				}
			},
			addRevenue(){
				if (this.cardStatus) {
					this.popupAddRevenue = !this.popupAddRevenue;
					this.$emit('input', this.popupAddRevenue);
				}
			},
			showHidePopups(){
				this.newRevenueName = String;
				this.newRevenueNameOBJ.revenue_type = '';
				this.popupAddRevenue = false;
				this.$emit('input', false);
			},
			saveRevenue(){
				this.newRevenueName = this.newRevenueNameOBJ.revenue_type.name;
				if (!this.newRevenueName || this.newRevenueName.length < 2){
					this.newRevenueNameError = true;
					setTimeout(() => {
						this.newRevenueNameError = false;
					}, 1500)
				}else{
					var key =  Object.keys(this.GetSettings.revenues).length;
					var pattern = {
						campaign: '',
						id: '',
						leads_revenue: 0,
						name: '',
						percent: 0,
						state: 'pause'
					}
					pattern.name = this.newRevenueName;
					pattern.revenue = {id: this.newRevenueNameOBJ.revenue_type.id, name: this.newRevenueName};
					this.$set(this.GetSettings.revenues, key, pattern);
					this.updateSettings(['revenues', this.GetSettings.revenues]);
					this.showHidePopups();
					this.handleResize();
				}
			},
			removeRow(index){
				this.GetSettings.revenues.splice(index, 1);
				this.updateSettings(['revenues', this.GetSettings.revenues]);
			}
		}
	}
</script>