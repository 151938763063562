<template lang="pug">
	div.card-box
		.card.suppression-list(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="Suppression list(s)", slug="SuppressionList", v-model="cardStatus")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.suppression_list.length)}")
				p Eliminate redundant or undesirable leads by supplying a suppression list.

			.file-list(v-if="GetSettings.suppression_list.length")
				.single(v-for="(item, index) in GetSettings.suppression_list", v-bind:class="{disabled: item.state == 'pause'}")
					.action-play-pause(
						v-bind:class="{played: item.state == 'running'}"
						v-on:click="changeFileStatus(index)"
					)
						.play
							svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
								rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
								path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
						.pause
							svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
								rect(width="20" height="20" rx="3").f-f
								rect(x="7" y="5" width="2" height="10" rx="1").f-t
								rect(x="11" y="5" width="2" height="10" rx="1").f-t

					.info
						p.name {{ item.title }}
						
						p.stat
							b {{ item.accounts_value.toLocaleString('en-US') }}
							| Accounts

						.remove-row(v-on:click="removeRow(index)")

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Eliminate redundant or undesirable leads by supplying a suppression list.

			button.btn.add-new-btn(type="button", v-on:click="addList()") ADD A NEW LIST

		.popup.add-asset-p(v-if="popupAddList")
			.close(v-on:click="showHidePopups()")
			.inside-form
				.drop-zone(
					@drop="drop",
					@dragover="dragover",
					@dragleave="dragleave"
				)
					input(
						type="file",
						v-on:change="onChange",
						ref="file").file-picker
					div(v-if="listFile.length > 0")
						p {{ listFile[0].name }}
					div(v-else)
						p BROWSE FILE
						span or
							br
							| Drag and Drop file here

				input(type="text", placeholder="Number of Accounts in the List", v-model="newSList.accounts_value", autocomplete="xz").inp

				button.btn.simple(type="button", v-on:click="saveList()") Apply

</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'Suppression',
		components:{
			CardHeader,
			DropList
		},
		data() {
			return {
				cardStatus: false,
				popupAddList: false,
				sList:[
					{
						name: 'CA Site Minder',
						status: 'play',
						accounts: 148
					},
					{
						name: 'Oracle Access Manager',
						status: 'paused',
						accounts: 214
					}
				],
				listFile: [],
				newSList:{
					accounts_value: '',
					title: '',
					state: 'pause'
				}
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		created(){
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "SuppressionList") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		methods: {
			...mapMutations(['updateSettings']),
			showHidePopups(){
				this.listFile = [];
				this.newSList = {
					accounts_value: '',
					title: '',
					state: 'pause'
				}
				this.popupAddList = false;
				this.$emit('input', false);
			},
			onChange() {
				this.listFile = [...this.$refs.file.files];
				this.newSList.accounts_value = this.listFile[0].name.replace(/[^0-9]/g,'');
				this.newSList.title = this.listFile[0].name.split('.').slice(0, -1).join('.');
			},
			drop(event) {
				event.preventDefault();
				this.$refs.file.files = event.dataTransfer.files;
				this.onChange();
			},
			dragover() {
				this.fileDropHover = true;
			},
			dragleave(){
				this.fileDropHover = false;
			},
			addList(){
				if (this.cardStatus) {
					this.popupAddList = !this.popupAddList;
					this.$emit('input', this.popupAddList);
				}
			},
			changeFileStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.suppression_list.[index].state == 'running'){
						this.GetSettings.suppression_list.[index].state = 'pause'
					}else{
						this.GetSettings.suppression_list.[index].state = 'running'
					}
				}
			},
			saveList(){
				var key =  Object.keys(this.GetSettings.suppression_list).length;
				this.$set(this.GetSettings.suppression_list, key, this.newSList);
				this.updateSettings(['suppression_list', this.GetSettings.suppression_list]);
				this.showHidePopups();
			},
			removeRow(index){
				this.GetSettings.suppression_list.splice(index, 1);
				this.updateSettings(['suppression_list', this.GetSettings.suppression_list]);
			}
		}
	}
</script>