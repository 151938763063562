export default{
	init(){
		window.store.commit("Login");
	},

	login(data){
		localStorage.setItem('email', data.email);
		localStorage.setItem('first_name', data.first_name);
		localStorage.setItem('groups', data.groups);
		localStorage.setItem('id', data.id);
		localStorage.setItem('last_name', data.last_name);
		localStorage.setItem('username', data.username);
		this.init();
	},

	logout(){
		localStorage.removeItem('email');
		localStorage.removeItem('first_name');
		localStorage.removeItem('groups');
		localStorage.removeItem('id');
		localStorage.removeItem('last_name');
		localStorage.removeItem('username');
		localStorage.removeItem('login');
		localStorage.removeItem('token');
		this.init();
	}
}