<template lang="pug">
	div.card-box
		.card.geographical(v-bind:class="{disable: !cardStatus}")
			CardHeader(
				:status="cardStatus",
				title="Geographical Locations",
				slug="Geo",
				v-model="cardStatus",
				type="tooltip",
				:tooltipText="tooltipText",
				file="https://hourglass.infusemedia.com/staticfiles/Global_Audience.jpg")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.geolocations.length)}")
				p Define ideal geographies to ensure precise targeting.

			.flex-row(v-if="GetSettings.geolocations.length")
				.left-side
					.geographical-table
						.t-head
							.cl

							.cl
								p Geolocation

							.cl
								p Leads

							.cl
								p(v-bind:class="{empty: emptyGoal}") Goal per Geo

						.t-row(v-for="(item, index) in GetSettings.geolocations", v-bind:class="{'active': item.state == 'running'}")
							.cl
								.action-play-pause(
									v-if="item.state",
									v-bind:class="{played: item.state == 'running'}"
									v-on:click="changeGeoStatus(index)"
								)
									.play
										svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
											rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
											path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
									.pause
										svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
											rect(width="20" height="20" rx="3").f-f
											rect(x="7" y="5" width="2" height="10" rx="1").f-t
											rect(x="11" y="5" width="2" height="10" rx="1").f-t

							.cl
								p {{ item.name }}

							.cl
								p(v-if="item.percent && item.leads_geolocation > 0") {{ item.leads_geolocation }}
								p(v-else) -

							.cl
								//- p(v-if="item.goal_per_geo") {{item.goal_per_geo}}% ({{ item.geolocation_goal }})
								//- p(v-else) -
								//- p(v-if="item.goal_per_geo") {{item.goal_per_geo}}%
								//- p(v-else) -
								.inp-row
									.for-inp
										p.invisible {{item.goal_per_geo}}%
										.field-absolute
											input(
												type="text",
												v-model="item.goal_per_geo",
												:ref="'goal_'+index",
												v-on:focus="editLeadGoal(index)",
												v-on:blur="handleFocusOut",
												v-on:input="checkGoal(index)"
												v-bind:class="{error: leadGoalError === index}",
												@keypress="onlyNumber"
											).goal-inp
											span(v-if="item.goal_per_geo > 0 && item.goal_per_geo <= 100") %
										
										.error-tooltip.up(v-bind:class="{show: leadGoalError === index}")
											span The lead goal can not be smaller than the leads generated within this section or larger than the campaign lead goal.

							.cl
								.remove-row(v-on:click="removeRow(index)", v-if="item.leads_geolocation == 0")
					
					button.btn.add-new-btn(type="button", v-on:click="addGeo()") ADD A NEW GEOLOCATION

				.right-side
					.map
						Map(:geo="GetSettings.geolocations", :map="GetSettings.part_of_the_map")
					
			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Define ideal geographies to ensure precise targeting.

			button.btn.add-new-btn(v-if="!GetSettings.geolocations.length", type="button", v-on:click="addGeo()") ADD A NEW GEOLOCATION


		.popup.add-industries-p(v-if="popupAddGeo")
			.close(v-on:click="showHidePopups()")
			.inside-form
				label Geographical Locations
				input(type="text", placeholder="Text here...", v-model="newGeoName", v-bind:class="{error: newGeoNameError}", autocomplete="xz").inp

				button.btn.simple(type="button", v-on:click="saveGeo()") Apply
</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";
	import Map from "../components/map.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'GeographicalLocations',
		components:{
			CardHeader,
			DropList,
			Map
		},
		data() {
			return {
				tooltipText: "<p>The highlighted areas on the map show the generated leads' locations, not all locations of interest.</p>",
				generatedLeads: 719,
				cardStatus: false,
				geolocationList: [
					{
						id: 0,
						geolocation: 'The United Kingdom',
						leads: '',
						percents: '20',
						gpg: '20% (512)',
						status: 'play'
					},
					{
						id: 1,
						geolocation: 'Russia',
						leads: '',
						percents: '10',
						gpg: '',
						status: 'pause'
					},
					{
						id: 2,
						geolocation: 'France',
						leads: '',
						percents: '12',
						gpg: '',
						status: 'play'
					},
					{
						id: 3,
						geolocation: 'Spain',
						leads: '',
						percents: '11',
						gpg: '',
						status: 'pause'
					},
					{
						id: 4,
						geolocation: 'The Netherlands',
						leads: '',
						percents: '5',
						gpg: '',
						status: 'pause'
					},
					{
						id: 5,
						geolocation: 'Germany',
						leads: '',
						percents: '76',
						gpg: '',
						status: 'pause'
					},
					{
						id: 6,
						geolocation: 'Poland',
						leads: '',
						percents: '99',
						gpg: '',
						status: 'pause'
					},
					{
						id: 7,
						geolocation: 'Czech Republic',
						leads: '',
						percents: '71',
						gpg: '',
						status: 'pause'
					}
				],
				emptyGoal: true,
				newGeoName: '',
				popupAddGeo: false,
				newGeoNameError: false,
				leadGoalError: false,
				prevNumber: false,
				editIndex: false
			}
		},
		created(){
			this.setLeads();
			this.checkList();
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "Geo") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		watch: {
			'GetSettings.total_main': function () {
				this.generateLeads();
				// this.setMaxInt();
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			generateLeads(){
				if(this.cardStatus){
					for(var item in this.GetSettings.geolocations){
						if(this.GetSettings.geolocations[item].state == 'running'){
							this.GetSettings.geolocations[item].leads_geolocation = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.geolocations[item].percent / 100)).toFixed(0)
						}else{
							this.GetSettings.geolocations[item].percent = Number(this.GetSettings.geolocations[item].leads_geolocation / this.GetSettings.total_main.leads_generated * 100).toFixed(2);
						}
					}
				}else{
					for(var single in this.GetSettings.geolocations){
						this.GetSettings.geolocations[single].percent = Number(this.GetSettings.geolocations[single].leads_geolocation / this.GetSettings.total_main.leads_generated * 100).toFixed(2);
					}
				}
				this.updateSettings(['geolocations', this.GetSettings.geolocations]);
				this.sortFunc(this.GetSettings.geolocations);
			},
			setLeads(){
				for(var single in this.GetSettings.geolocations){
					var generated = Number((this.GetSettings.geolocations[single].percent / 100) * this.GetSettings.total_main.leads_generated).toFixed(0);

					if (!this.GetSettings.geolocations[single].goal_per_geo || this.GetSettings.geolocations[single].goal_per_geo == 0) {
						this.GetSettings.geolocations[single].goal_per_geo = '-';
					}

					this.GetSettings.geolocations[single].leads_geolocation = generated;
				}
				this.updateSettings(['geolocations', this.GetSettings.geolocations]);
			},
			sortFunc: function (obj){
				return obj.sort((a, b) => b.percent - a.percent );
			},
			changeGeoStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.geolocations.[index].state == 'running'){
						this.GetSettings.geolocations.[index].state = 'pause'
					}else{
						this.GetSettings.geolocations.[index].state = 'running'
					}
				}
			},
			checkList(){
				for (var item in this.geolocationList) {
					var gpg = this.geolocationList[item].gpg;
					if (gpg.length > 1 || gpg > 0) {
						this.emptyGoal = false;
						return;
					}
				}
			},
			addGeo(){
				if (this.cardStatus) {
					this.popupAddGeo = !this.popupAddGeo;
					this.$emit('input', this.popupAddGeo);
				}
			},
			showHidePopups(){
				this.newGeoName = '';
				this.popupAddGeo = false;
				this.$emit('input', false);
			},
			saveGeo(){
				if (this.newGeoName.length < 2){
					this.newGeoNameError = true;
					setTimeout(() => {
						this.newGeoNameError = false;
					}, 1500)
				}else{
					var key =  Object.keys(this.GetSettings.geolocations).length;
					var pattern = {
						campaign: '',
						code: '',
						geolocation: {
							name: this.newGeoName
						},
						goal_per_geo: '-',
						id: '',
						leads_geolocation: 0,
						name: '',
						percent: 0,
						state: 'pause'
					}
					pattern.name = this.newGeoName;
					this.$set(this.GetSettings.geolocations, key, pattern);
					this.updateSettings(['geolocations', this.GetSettings.geolocations]);
					this.showHidePopups();
				}
			},
			switchTab(tab){
				if (this.cardStatus) {
					this.activeTab = tab;
					this.calcTotal();
				}
			},
			onlyNumber ($event) {
				let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
					$event.preventDefault();
				}
			},
			editLeadGoal(index){
				this.editIndex = index;
				this.prevNumber = this.GetSettings.geolocations[this.editIndex].goal_per_geo;
			},
			checkGoal(){
				if (+this.GetSettings.geolocations[this.editIndex].goal_per_geo > 100 || +this.GetSettings.geolocations[this.editIndex].goal_per_geo < 1) {
					this.leadGoalError = this.editIndex
				}else{
					this.leadGoalError = false;
				}
			},
			handleFocusOut(){
				if (+this.GetSettings.geolocations[this.editIndex].goal_per_geo > 100 || +this.GetSettings.geolocations[this.editIndex].goal_per_geo < 1) {
					this.GetSettings.geolocations[this.editIndex].goal_per_geo = '-';
					// this.GetSettings.geolocations[this.editIndex].goal_per_geo = this.prevNumber;
				}
				this.updateSettings(['geolocations', this.GetSettings.geolocations]);
				this.leadGoalError = false;
				this.prevNumber = false;
				this.editIndex = false;
			},
			removeRow(index){
				this.GetSettings.geolocations.splice(index, 1);
				this.updateSettings(['geolocations', this.GetSettings.geolocations]);
			}
		}
	}
</script>