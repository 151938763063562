<template lang="pug">
	footer
		.logo-infuse
			img(src="@/assets/infuse-logo.svg", alt="")

		.main-footer
			.address
				p 2385 NW Executive Center Drive, Suite 100
					br
					| Boca Raton, FL 33431

			.contacts
				a(href="tel:6174594500").phone (617) 459-4500
				a(href="mailto:info@infusemedia.com").mail info@infusemedia.com

			.awards
				img(src="@/assets/inc-awards.svg", alt="")

			.copy-privacy
				.links
					a(href="https://infusemedia.com/privacy-policy-for-california-residents/", target="_blank") Privacy Notice for California Residents
					span |
					a(href="https://infusemedia.com/gdpr/", target="_blank") GDPR
					span |
					a(href="https://infusemedia.com/lgpd/", target="_blank") LGPD
					span |
					a(href="https://infusemedia.com/terms-and-conditions/", target="_blank") Terms & Conditions
				p © 2023 INFUSEmedia All Rights Reserved
</template>

<script>
	export default {
		name: 'Footer'
	}
</script>