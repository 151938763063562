// for right sidebar
import Vue from 'vue';

export default{
	actions: {
	},
	mutations: {
		setRS(state, data){
			state.rs = data;
		},
		updateRS(state, data){
			// state.settings[data[0]] = data[1]
			Vue.set(state.rs, data[0], data[1])
		}
	},
	state: {
		rs: []
	},
	getters:{
		RightSidebar(state){
			return state.rs
		}
	}
}