<template lang="pug">
	.drop-list(v-bind:class="{opened: open}", v-click-outside="handleFocusOut")
		.visible(v-on:click="open = !open")
			p.biger {{ active }}
		.list
			.with-search
				//- .search
				//- 	input(type="text", v-model="listSearch", autocomplete="xz").inp
				//- 	.ico
				vuescroll(:ops="ops")
					.inside
						span(v-for="item in filterBy(list, listSearch, 'name')", v-on:click="select(item.name)", v-bind:class="{text: type == 'tooltip'}") {{ item.name }}

				.show-more(v-on:click="showMore()") View More
</template>

<script>
	import vuescroll from 'vuescroll'
	import Vue2Filters from 'vue2-filters'

	export default {
		props: {
			list: [Object, Array],
			default: String,
			type: String
		},
		components: {
			vuescroll
		},
		mixins: [Vue2Filters.mixin],
		data() {
			return {
				active: this.default,
				open: false,
				listSearch: '',
				ops:{
					bar:{
						background: '#FC9000',
						onlyShowBarOnScroll: false,
						keepShow: true,
						size: '2px'
					},
					rail:{
						background: '#E6E6E6',
						opacity: 1,
						size: '2px'
					}
				}
			}
		},
		methods: {
			select(data){
				if (this.type != 'tooltip') {
					this.open = false;
					this.active = data.name;
					this.$emit('input', data);
				}
			},
			showMore(){
				this.$emit('input', true);
			},
			handleFocusOut() {
				this.open = false;
			}
		}
	}
</script>