<template lang="pug">
	div.card-box
		.card.industries(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="Industries", slug="Industries", v-model="cardStatus")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.industries.length)}")
				p Provide ideal industries to ensure precise targeting.

			.industries-table(v-if="GetSettings.industries.length")
				.t-head
					.cl
						p Industry

					.cl
						p Leads

					.cl
						.chart-range(ref="chart")
							.single
								p 0

							.single
								p {{ Number(maxNumber / maxDivide * 1).toFixed(0) }}

							.single
								p {{ Number(maxNumber / maxDivide * 2).toFixed(0) }}

							.single
								p {{ Number(maxNumber / maxDivide * 3).toFixed(0) }}

				.t-row(v-for="(item, index) in GetSettings.industries", v-bind:class="{'active': item.state == 'running'}")
					.cl
						.action-play-pause(
							v-if="item.state",
							v-bind:class="{played: item.state == 'running'}"
							v-on:click="changeIndustriesStatus(index)"
						)
							.play
								svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
									rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
									path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
							.pause
								svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
									rect(width="20" height="20" rx="3").f-f
									rect(x="7" y="5" width="2" height="10" rx="1").f-t
									rect(x="11" y="5" width="2" height="10" rx="1").f-t

						p(v-if="item.name") {{ item.name }}
						p(v-else) {{ item.user_industry }}

					.cl
						p(v-if="item.leads_industry && item.leads_industry != 0 && !isNaN(item.leads_industry)") {{ Number(item.leads_industry).toFixed(0) }}
						p(v-else) -

					.cl
						.fill-line
							.lines
								.s-line
								.s-line
								.s-line
								.s-line

							.fill(:style='{width: Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(0) / maxNumber * 100 + "%"}')
								p(
									v-bind:class="{'push': chartWidth * Number(Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(0) / maxNumber * 100).toFixed(2) / 100 < 40 }"
							) {{ Number(item.percent).toFixed(2) }}%

					.cl
						.remove-row(v-on:click="removeRow(index)", v-if="!item.leads_industry || item.leads_industry == 0")

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Provide ideal industries to ensure precise targeting.
				
			button.btn.add-new-btn(type="button", v-on:click="addIndustries()") ADD A NEW INDUSTRY

		.popup.add-industries-p(v-if="popupAddIndustries")
			.close(v-on:click="showHidePopups()")
			.inside-form
				label Industries Title
				input(type="text", placeholder="Text here...", v-model="newIndustriesName", v-bind:class="{error: newIndustriesNameError}", autocomplete="xz").inp

				button.btn.simple(type="button", v-on:click="saveIndustries()") Apply

</template>

<script>
	import vuescroll from 'vuescroll'
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'Industries',
		components:{
			CardHeader,
			DropList,
			vuescroll
		},
		data() {
			return {
				generatedLeads: 719,
				cardStatus: true,
				chartWidth: '',
				maxDivide: 3,
				maxNumber: 100,
				emptyGoal: true,
				ops:{
					bar:{
						background: '#FC9000',
						onlyShowBarOnScroll: false,
						keepShow: true,
						size: '2px'
					},
					rail:{
						background: '#E6E6E6',
						opacity: 1,
						size: '2px'
					}
				},
				newIndustriesName: '',
				popupAddIndustries: false,
				newIndustriesNameError: false
			}
		},
		created(){
			this.setLeads();
			this.sortFunc(this.GetSettings.industries);
			this.updateSettings(['industries', this.GetSettings.industries]);
			this.setMaxInt();
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "Industries") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		watch: {
			'GetSettings.total_main': function () {
				this.generateLeads();
				// this.setMaxInt();
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		mounted() {
			// window.addEventListener('resize', this.handleResize);
			this.handleResize();
		},
		methods: {
			...mapMutations(['updateSettings']),
			generateLeads(){
				if(this.cardStatus){
					for(var item in this.GetSettings.industries){
						if(this.GetSettings.industries[item].state == 'running'){
							this.GetSettings.industries[item].leads_industry = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.industries[item].percent / 100)).toFixed(0);
						}else{
							var percent = this.GetSettings.industries[item].leads_industry / this.GetSettings.total_main.leads_generated * 100;
							if (isNaN(percent)) {
								percent = 0;
							}
							this.GetSettings.industries[item].percent = percent;
						}
					}
				}else{
					for(var single in this.GetSettings.industries){
						this.GetSettings.industries[single].percent = this.GetSettings.industries[single].leads_industry / this.GetSettings.total_main.leads_generated * 100;
					}
				}
				this.updateSettings(['industries', this.GetSettings.industries]);
				this.sortFunc(this.GetSettings.industries);
				this.setMaxInt();
			},
			setLeads(){
				for(var single in this.GetSettings.industries){
					this.GetSettings.industries[single].leads_industry = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.industries[single].percent / 100)).toFixed(0);
				}
				this.updateSettings(['industries', this.GetSettings.industries]);
			},
			handleResize() {
				if(this.GetSettings.industries.length){
					this.chartWidth = this.$refs.chart.clientWidth;
				}
			},
			sortFunc: function (obj){
				return obj.sort((a, b) => b.leads_industry - a.leads_industry );
			},
			setMaxInt(){
				var max = this.maxNumber;
				for (var item in this.GetSettings.industries) {
					var leads = this.GetSettings.industries[item].leads_industry;
					var num = parseInt(leads);
					if (max < num && !isNaN(num)) {
						max = num
					}
				}

				var r = parseInt(max.toString()[0]);
				check();
				var x;
				function check(){
					x = r * 100;
					if(x < max){
						r = r + 1;
						check();
					}
				}
				this.maxNumber = x;
			},
			changeIndustriesStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.industries.[index].state == 'running'){
						this.GetSettings.industries.[index].state = 'pause'
					}else{
						this.GetSettings.industries.[index].state = 'running'
					}
				}
			},
			addIndustries(){
				if (this.cardStatus) {
					this.popupAddIndustries = !this.popupAddIndustries;
					this.$emit('input', this.popupAddIndustries);
				}
			},
			showHidePopups(){
				this.newIndustriesName = '';
				this.popupAddIndustries = false;
				this.$emit('input', false);
			},
			saveIndustries(){
				if (this.newIndustriesName.length < 2){
					this.newIndustriesNameError = true;
					setTimeout(() => {
						this.newIndustriesNameError = false;
					}, 1500)
				}else{
					var key =  Object.keys(this.GetSettings.industries).length;
					var pattern = {
						campaign: '',
						id: '',
						leads_industry: 0,
						name: '',
						percent: 0,
						state: "pause"
					}
					pattern.name = this.newIndustriesName;
					this.$set(this.GetSettings.industries, key, pattern);
					this.updateSettings(['industries', this.GetSettings.industries]);
					this.showHidePopups();
					this.handleResize();
				}
			},
			removeRow(index){
				this.GetSettings.industries.splice(index, 1);
				this.updateSettings(['industries', this.GetSettings.industries]);
			}
		}
	}
</script>