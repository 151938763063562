<template lang="pug">
	.flex-cont(v-bind:class="{flex: transform, 'opacity-show': auth}", ref="get-width")
		.main-layer(v-bind:class="{transform: transform}", ref="transform")
			Header(v-bind:class="{show: GetDashboard && GetDashboard.showHF}")

			LeftSidebar(
				v-if="showLeft"
				v-model="activeCampaign"
			)

			SettingsSidebar(
				v-if="showLeftSettings"
			)

			AddCampaign(
				v-bind:class="{active: addCampaign}"
				v-if="showLeft",
				v-click-outside="handleFocusOut"
			)

			AddDealDesk(
				v-bind:class="{active: addDealDesk}"
				v-if="showLeft"
			)

			IntegratePopup(
				v-if="showIntegrate"
			)

			LeadGoal(
				v-if="editGoal"
			)

			router-view

			RightSidebar(
				:activeCampaign="activeCampaign"
				v-if="showRight"
			)

			Footer(v-bind:class="{show: GetDashboard && GetDashboard.showHF}")

</template>

<style lang="scss">
	@import "./mainStyles/main.scss";
</style>

<script>
	import Header from '@/components/Header'
	import Footer from '@/components/Footer'
	import LeftSidebar from '@/components/Left-Sidebar'
	import RightSidebar from '@/components/Right-Sidebar'
	import AddCampaign from '@/components/Add-Campaign'
	import AddDealDesk from '@/components/Add-DealDesk'
	import IntegratePopup from '@/components/Integrate-Popup'
	import LeadGoal from '@/components/Lead-Goal'
	import SettingsSidebar from '@/components/Settings-Sidebar'

	// import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'App',
		components: {
			LeftSidebar,
			RightSidebar,
			AddCampaign,
			AddDealDesk,
			IntegratePopup,
			LeadGoal,
			SettingsSidebar,
			Header,
			Footer
		},
		data(){
			return{
				activeCampaign: null,
				showLeft: false,
				showLeftSettings: false,
				showRight: false,
				addCampaign: false,
				addCampaignBTN: false,
				addDealDesk: false,
				addDealDeskBTN: false,
				showIntegrate: false,
				editGoal: false,
				transform: false,
				auth: true,
				showHeaderFooter: false
			}
		},
		computed: {
			GetDashboard(){
				return this.$store.getters.GetDashboard
			},
			AddCampaign() {
				return this.$store.getters.AddCampaign
			},
			AddDealDesk() {
				return this.$store.getters.AddDealDesk
			},
			SC(){
				return this.$store.getters.SC.load;
			}
		},
		created: function(){
			this.checkRouter();
			if (this.$store.getters.SC.load) {
				this.auth = true;
			}
			window.addEventListener("resize", this.handleResize);
		},
		watch: {
			AddCampaign(){
				this.NewCampaign();
			},
			AddDealDesk(){
				this.NewDealDesk();
			},
			SC(){
				this.auth = true;
			},
			$route() {
				this.checkRouter();
			}
		},
		methods:{
			NewCampaign(){
				var st = this.$store.state.AddCampaign;
				var status = st.status === 'true';
				if(status){
					this.addCampaign = true;
					this.addCampaignBTN = true;
					setTimeout(() => {
						this.addCampaignBTN = false;
					}, 250)
				}else{
					this.addCampaign = false;
				}
			},
			NewDealDesk(){
				var st = this.$store.state.AddDealDesk;
				var status = st.status === 'true';
				if(status){
					this.addDealDesk = true;
					this.addDealDeskBTN = true;
					setTimeout(() => {
						this.addDealDeskBTN = false;
					}, 250)
				}else{
					this.addDealDesk = false;
				}
			},
			checkRouter(){
				setTimeout(() => {
					this.checkResize();
				}, 20);
				// if (this.$route.path == '/' || this.$route.path == '/accelerator/' || this.$route.path == '/accelerator') {
				if (this.$route.name == 'Home') {
					this.showRight = true;
					this.showLeft = true;
					this.showLeftSettings = false;
					this.transform = true;
				}else if (this.$route.path == '/add-new-campaign') {
					this.showLeft = true;
					this.showRight = false;
					this.showLeftSettings = false;
				}else if(this.$route.name == 'Settings'){
					this.showRight = false;
					this.showLeft = false;
					this.showLeftSettings = true;
					this.transform = true;
				}else{
					this.showRight = false;
					this.showLeft = false;
					this.auth = true;
					this.showLeftSettings = false;
				}
			},
			checkResize(){
				var originalWidth = this.$refs.['get-width'].clientWidth;
				if (this.$route.name != 'Mobile') {
					// if (originalWidth < 1200) {
					if (originalWidth < 1024) {
						this.transform = false;
						this.showRight = false;
						this.showLeft = false;
						this.auth = true;
						this.showLeftSettings = false;
						this.$router.push(window.baseURL+'mobile');
						// document.location.href='/mobile';
					}
				}

				if (this.$route.name == 'Mobile') {
					// if (originalWidth >= 1200) {
					if (originalWidth >= 1024) {
						window.AddDD.deleteData();
						// this.$router.push('/');
						// document.location.href='/';
						localStorage.removeItem('campaign_from_settings');
						document.location.href = window.baseURL;
					}
				}
			},
			handleResize() {
				this.checkResize();
			},
			handleFocusOut() {
				if (!this.addCampaignBTN) {
					window.AddC.deleteData();
					this.addCampaign = false;
				}
			},
			handleFocusOutDD() {
				if (!this.addDealDeskBTN) {
					window.AddDD.deleteData();
					this.addDealDesk = false;
				}
			}
		}
	}
</script>
