<template lang="pug">
	div.card-box
		.card.company-size(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="Company Size", slug="CompanySize", v-model="cardStatus")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.companies_size.length)}")
				p Provide ideal company sizes to ensure precise targeting.

			.flex-row(v-if="GetSettings.companies_size.length")
				.left-side
					//- h6 The company size range
					.list
						.single(v-for="(item, index) in GetSettings.companies_size", v-bind:class="{'active': item.state == 'running'}")
							.action-play-pause(
								v-if="item.state",
								v-bind:class="{played: item.state == 'running'}"
								v-on:click="changeCompanyRangeStatus(index)"
							)
								.play
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
										path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
								.pause
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(width="20" height="20" rx="3").f-f
										rect(x="7" y="5" width="2" height="10" rx="1").f-t
										rect(x="11" y="5" width="2" height="10" rx="1").f-t

							//- p {{ item.name }}({{ (Number(GetSettings.total_main.leads_generated * (item.percent / 100))).toFixed(0) }})
							p(v-if="item.name") {{ item.name }} ({{ Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(0) }})
							p(v-else) {{ item.user_company_size }} ({{ Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(0) }})
							.remove-row(v-on:click="removeRow(index)", v-if="item.percent == 0")

				.right-side
					h6 Company Size Captured

					.chart-block
						.legend
							//- .inline(v-for="item in sortFuncPercent()")
							.inline(v-for="item in GetSettings.companies_size")
								.fill
								p {{ item.name }}
								.line
								p.fix {{ Number(item.percent).toFixed(0) }}%
					
						.chart
							pie-chart(:data="chartData", :options="chartOptions", ref="doughnutUpdate")

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Provide ideal company sizes to ensure precise targeting.
				
			button.btn.add-new-btn(type="button", v-on:click="addCompanyRange()") ADD RANGE

		.popup.new-company-size(v-if="popupAddCompanyRange")
			.close(v-on:click="showHidePopups()")
			.inside-form
				.in-row
					.cl.full
						label Company Size
						DropBoxInp(
							:list="GetSettings.company_size_list",
							:default="newCompanyRangeNameOBJ.company_size.name",
							v-model="newCompanyRangeNameOBJ.company_size",
							v-bind:class="{error: newCompanyRangeNameError}"
						)

						button.btn.simple(type="button", v-on:click="saveCompanyRange()") Apply

</template>

<script>
	import PieChart from "../components/PieChart.js";
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";
	import DropBoxInp from "../components/drop-box-inp.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'CompanySize',
		components:{
			PieChart,
			CardHeader,
			DropList,
			DropBoxInp
		},
		data() {
			return {
				cardStatus: true,
				boxesState:{
					ct: false,
					leads: false
				},
				chartOptions: {
					cutoutPercentage: 72,
					// tooltips:{
					// 	enabled: false
					// },
					legend:{
						display: false
					},
				},
				chartData: {
					labels: [],
					datasets: [
						{
							backgroundColor: [],
							data: [],
							borderWidth: 0
						}
					]
				},
				newCompanyRangeName: String,
				popupAddCompanyRange: false,
				newCompanyRangeNameError: false,
				newCompanyRangeNameOBJ: {
					company_size: ''
				},
				percentSort: ''
			}
		},
		created(){
			this.setLeads();
			this.sortFunc(this.GetSettings.companies_size);
			// this.percentSort = this.sortFuncPercent(this.GetSettings.companies_size);
			this.setDoughnut();
			this.$nextTick(function () {
				if(this.GetSettings.companies_size.length){
					this.updateDoughnut();
				}
			});
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "CompanySize") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		watch:{
			cardStatus: function(){
				this.updateDoughnut();
			},
			'GetSettings.total_main': function () {
				this.generateLeads();
				// this.setMaxInt();
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			generateLeads(){
				this.chartData.labels = [];
				this.chartData.datasets[0].data = [];
				if(this.cardStatus){
					for(var item in this.GetSettings.companies_size){
						if(this.GetSettings.companies_size[item].state == 'running'){
							this.GetSettings.companies_size[item].leads_company_size = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.companies_size[item].percent / 100)).toFixed(0)
						}else{
							var percentResR = this.GetSettings.companies_size[item].leads_company_size / this.GetSettings.total_main.leads_generated * 100;
							if (isNaN(percentResR)) {
								percentResR = 0;
							}
							this.GetSettings.companies_size[item].percent = percentResR;
						}
					}
				}else{
					for(var single in this.GetSettings.companies_size){
						var percentRes = this.GetSettings.companies_size[single].leads_company_size / this.GetSettings.total_main.leads_generated * 100;
						if (isNaN(percentRes)) {
							percentRes = 0;
						}
						this.GetSettings.companies_size[single].percent = percentRes;
					}
				}
				this.updateSettings(['companies_size', this.GetSettings.companies_size]);
				this.sortFunc(this.GetSettings.companies_size);
				// this.percentSort = this.sortFuncPercent(this.GetSettings.companies_size);
				this.setDoughnut();
				this.updateDoughnut();
			},
			setLeads(){
				for(var single in this.GetSettings.companies_size){
					this.GetSettings.companies_size[single].leads_company_size = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.companies_size[single].percent / 100)).toFixed(0);
				}
				this.updateSettings(['companies_size', this.GetSettings.companies_size]);
			},
			sortFunc: function (obj){
				// leads_company_size
				// return obj.sort((a, b) => parseInt(b.percent) - parseInt(a.percent) );
				return obj.sort((a, b) => parseInt(a.name.split(' ')[0].replace(',', '')) - parseInt(b.name.split(' ')[0].replace(',', '')) );
			},
			sortFuncPercent: function (obj){
				return obj.sort((a, b) => parseInt(b.percent) - parseInt(a.percent) );
			},
			updateDoughnut() {
				if (this.cardStatus) {
					this.chartData.datasets[0].backgroundColor = ["#014165", "#FFC000", "#16AAC4", "#FC9200", "#FF5722", "#69D09F", "#00C2FF", "#2A76BC"];
				}else{
					this.chartData.datasets[0].backgroundColor = ["#053754", "#E9B20B", "#0694AD", "#F07B07", "#E14413", "#3FAB77", "#0EA5D5", "#125DA2"];
				}
				this.$refs.doughnutUpdate.update();
			},
			changeCompanyRangeStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.companies_size.[index].state == 'running'){
						this.GetSettings.companies_size.[index].state = 'pause'
					}else{
						this.GetSettings.companies_size.[index].state = 'running'
					}
				}
			},
			addCompanyRange(){
				if (this.cardStatus) {
					this.popupAddCompanyRange = !this.popupAddCompanyRange;
					this.$emit('input', this.popupAddCompanyRange);
				}
			},
			showHidePopups(){
				this.newCompanyRangeName = String;
				this.newCompanyRangeNameOBJ.company_size = '';
				this.popupAddCompanyRange = false;
				this.$emit('input', false);
			},
			saveCompanyRange(){
				this.newCompanyRangeName = this.newCompanyRangeNameOBJ.company_size.name;
				if (!this.newCompanyRangeName || this.newCompanyRangeName.length < 2){
					this.newCompanyRangeNameError = true;
					setTimeout(() => {
						this.newCompanyRangeNameError = false;
					}, 1500)
				}else{
					var key =  Object.keys(this.GetSettings.companies_size).length;
					var pattern = {
						leads_company_size: 0,
						name: '',
						percent: 0,
						state:"pause"
					}
					pattern.name = this.newCompanyRangeName;
					pattern.company_size = {id: this.newCompanyRangeNameOBJ.company_size.id, name: this.newCompanyRangeName};
					this.$set(this.GetSettings.companies_size, key, pattern);
					this.updateSettings(['companies_size', this.GetSettings.companies_size]);
					this.showHidePopups();
				}
			},
			setDoughnut(){
				for (var single in this.GetSettings.companies_size) {
					if (this.GetSettings.companies_size[single].percent > 0) {
						this.chartData.labels.push(this.GetSettings.companies_size[single].name);
						this.chartData.datasets[0].data.push(Number(this.GetSettings.companies_size[single].percent).toFixed(0));
					}
				}
			},
			removeRow(index){
				this.GetSettings.companies_size.splice(index, 1);
				this.updateSettings(['companies_size', this.GetSettings.companies_size]);
			}
		}
	}
</script>