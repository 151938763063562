export default{
	init(){
		window.store.commit("setToken");
	},
	settoken(data){
		localStorage.setItem('refresh', data.refresh);
		localStorage.setItem('access', data.access);
	},
	deletetoken(){
		localStorage.removeItem('refresh');
		localStorage.removeItem('access');
		this.init();
	}
}