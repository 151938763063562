<template lang="pug">
	div.card-box
		.card.fair-trade(v-bind:class="{disable: !cardStatus}")
			CardHeader(
				:status="cardStatus",
				title="Fair-Trade Lead Qualification",
				v-model="cardStatus",
				type="tooltip",
				slug="Fair-TradeLeadQualification",,
				file="https://hourglass.infusemedia.com/staticfiles/Fair-Trade_Lead_Qualification.pdf",
				:tooltipText="tooltipText")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.fair_trades.length)}")
				p Increase positive brand engagement.

			div(v-if="GetSettings.fair_trades.length")
				.align
					.congratulation
						p
							b Great! 
							| Your prospects are fairly compensated for their time.

				.file-list
					.single(v-for="(item, index) in GetSettings.fair_trades", v-bind:class="{disabled: item.state == 'pause'}")
						.action-play-pause(
							v-on:click="changeStatus(index)"
							v-bind:class="{played: item.state == 'running'}"
						)
							.play
								svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
									rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
									path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
							.pause
								svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
									rect(width="20" height="20" rx="3").f-f
									rect(x="7" y="5" width="2" height="10" rx="1").f-t
									rect(x="11" y="5" width="2" height="10" rx="1").f-t

						.info
							p.name {{ item.name }}
							p.stat
								b {{ item.value }}

							.remove-row(v-on:click="removeRow(index)")

				.engage
					p A Treat that Engages!
				br

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Increase positive brand engagement.

			button.btn.add-new-btn(type="button", v-on:click="addTreat()") ADD A NEW treat

		.popup.add-treat(v-if="popupAddTreat")
			.close(v-on:click="showHidePopups()")
			.inside-form
				label Treat Description
				input(type="text", placeholder="Text here...", v-model="treatDescription", autocomplete="xz").inp
				
				label Title/Value
				input(type="text", placeholder="Text here...", v-model="treatValue", v-bind:class="{error: treatValueError}", autocomplete="xz").inp

				button.btn.simple(type="button", v-on:click="saveTreat()") APPLY

</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'FairTrade',
		components:{
			CardHeader,
			DropList
		},
		data() {
			return {
				cardStatus: false,
				tooltipText: '<p>Custom account intelligence is key to gaining optimal insight into your ideal customer. We incentivize prospects to share this intelligence by offering a personalized gift in exchange for answering relevant questions. This is your opportunity to create the first positive touch with your brand that keeps on rewarding long after they drink that cup of coffee or read that book.</p>',
				ftList:[
					{
						name: 'Amazon Gift Card',
						status: 'play',
						leads: '$15'
					},
					{
						name: 'Starbucks Gift Card',
						status: 'paused',
						leads: '$10'
					},
					{
						name: 'Free eBook',
						status: 'paused',
						leads: 'Free'
					}
				],
				popupAddTreat: false,
				treatDescription: '',
				treatValue: '',
				treatValueError: false
			}
		},
		created(){
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "Fair-TradeLeadQualification") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			changeStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.fair_trades.[index].state == 'running'){
						this.GetSettings.fair_trades.[index].state = 'pause'
					}else{
						this.GetSettings.fair_trades.[index].state = 'running'
					}
				}
			},
			showHidePopups(){
				this.treatDescription = '';
				this.treatValue = '';
				this.popupAddTreat = false;
				this.$emit('input', false);
			},
			addTreat(){
				if (this.cardStatus) {
					this.popupAddTreat = !this.popupAddTreat;
					this.$emit('input', this.popupAddTreat);
				}
			},
			saveTreat(){
				this.treatValueError = false;
				var key =  Object.keys(this.GetSettings.fair_trades).length;
				var pattern = {
					name: this.treatDescription,
					state:"pause",
					value: this.treatValue,
					new: true
				}
				if (this.treatValue.length < 1) {
					this.treatValueError = true;
					setTimeout(() => {
						this.treatValueError = false;
					}, 2000)
				}
				if (!this.treatValueError) {
					this.$set(this.GetSettings.fair_trades, key, pattern);
					this.updateSettings(['fair_trades', this.GetSettings.fair_trades]);
					this.showHidePopups();
				}
			},
			removeRow(index){
				this.GetSettings.fair_trades.splice(index, 1);
				this.updateSettings(['fair_trades', this.GetSettings.fair_trades]);
			}
		}
	}
</script>