<template lang="pug">
	header
		.logo-demand
			img(src="@/assets/hg-demand.svg", alt="")

		.right-side
			a(href="/profiler/", target="_blank").single-item
				img(src="@/assets/hg-idp.svg", alt="")
			a(href="/builder/", target="_blank").single-item
				img(src="@/assets/hg-idb.svg", alt="")
			.single-item.active
				img(src="@/assets/hg-ida.svg", alt="")

</template>

<script>
	export default {
		name: 'Header'
	}
</script>