<template lang="pug">
	.left-sidebar
		.logo(v-on:click="showHF()", v-click-outside="handleFocusLogo")
			img(src="@/assets/logo-sidebar.svg", alt="")
		.active-campaign-name
			h4 My Current Campaigns


		<!-- img(src="@/assets/Hour.svg", alt="") -->

		.all-lists
			.custom-list.clients(v-bind:class="{ opened: clientsList, loaded: clientsLoad, 'have-selected' : activeClient }")
				.visible(v-on:click="dropListClients()")
					span List of Clients
					p(v-if="activeClient") {{ activeClient.name }}

				.drop-list(v-if="clientsList")
					.list-and-search(v-if="clientsLoad")
						.search
							input(type="text", placeholder="Search...", v-model="clientSearch").search-inp

						.scroll-area
							.scroll-list
								scrolly.scroll.foo
									scrolly-viewport
										.single-item(v-for="client in filterBy(allClients, clientSearch, 'name')", v-on:click="selectClient(client.id, client.name)") {{ client.name }}
									.scroll-bar
									scrolly-bar(axis="y")

					.loader(v-if="!clientsLoad")
						.spiner
						p Loading...

			.add-new.clients(v-if="clientsLoad", v-bind:class="{active: addClient}")
				p(v-on:click="addNewClient()") Add New Client
				.new-client
					input.client-inp(v-model="newClient.name", v-bind:class="{error: newClientError}")
					button.btn.simple(type="button", v-on:click="saveClient()") Save

			.custom-list.campaigns(v-bind:class="{ opened: campaignsList, loaded: campaignsLoad, 'have-selected' : activeCampaign }", v-if="clientsLoad && activeClient != null")
				.visible(v-on:click="dropListCampaigns()")
					span List of Campaigns
					p(v-if="activeCampaign") {{ activeCampaign.name }}

				.drop-list(v-if="campaignsList")
					.list-and-search(v-if="campaignsLoad")
						.search
							input(type="text", placeholder="Search...", v-model="campaignSearch").search-inp

						.scroll-area
							.scroll-list
								scrolly.scroll.foo
									scrolly-viewport
										.single-item(v-for="campaign in  filterBy(allCampaigns, campaignSearch, 'name')", v-on:click="selectCampaign(campaign.id, campaign.name, campaign.kind, campaign.pacing, campaign.integration, campaign.client)") {{ campaign.name }}
									.scroll-bar
									scrolly-bar(axis="y")

					.loader(v-if="!campaignsLoad")
						.spiner
						p Loading...

			//- router-link(to="/add-new-campaign").add-new.campaign(v-if="campaignsLoad")
			.add-new.campaign(v-if="campaignsLoad", v-on:click="addNewCampaign()", v-bind:class="{active: addCampaign}")
				p Add New Campaign

			.add-new.deal-desk(v-on:click="addNewDealDesk()", v-bind:class="{active: addDealDesk}")
				p DealDesk Request

		.count-cmaping
			p Active
				br
				| Campaigns

			.count
				span /
				p {{ activeCampaignCount.count }}
</template>

<script>
	import { Scrolly, ScrollyViewport, ScrollyBar } from 'vue-scrolly'
	import Vue2Filters from 'vue2-filters'

	import { mapMutations } from 'vuex'
	export default {
		name: 'LeftSidebar',
		components:{
			Scrolly,
			ScrollyViewport,
			ScrollyBar
		},
		mixins: [Vue2Filters.mixin],
		data(){
			return {
				addCampaign: false,
				addDealDesk: false,
				clientsList: true,
				clientsLoad: false,
				clientSearch: '',
				activeClient: null,

				campaignsList: false,
				campaignsLoad: false,
				campaignSearch: '',
				activeCampaign: null,
				newClientError: false,

				addClient: false,

				newClient: {
					name: '',
					client_type: 'contract'
				},

				allClients: [],
				allCampaigns: [],
				activeCampaignCount: '-'
			}
		},
		computed: {
			AddCampaign() {
				return this.$store.getters.AddCampaign
			},
			AddDealDesk() {
				return this.$store.getters.AddDealDesk
			},
			SC(){
				return this.$store.getters.SC.id;
			}
		},
		watch: {
			AddCampaign(){
				var st = this.$store.state.AddCampaign.status;
				var status = st === 'true';
				this.addCampaign = status;
			},
			AddDealDesk(){
				var st = this.$store.state.AddDealDesk.status;
				var status = st === 'true';
				this.addDealDesk = status;
			},
			SC(){
				// this.selectCampaign(this.$store.state.SelectedCampaign)
				this.selectCampaign(this.$store.state.SelectedCampaign.id, this.$store.state.SelectedCampaign.name, this.$store.state.SelectedCampaign.kind, this.$store.state.SelectedCampaign.pacing, this.$store.state.SelectedCampaign.integration, this.$store.state.SelectedCampaign.client)
			}
		},
		created: function(){
			var campaignFromSettings = JSON.parse(localStorage.getItem('campaign_from_settings'));
			if(campaignFromSettings){
				this.selectClient(campaignFromSettings.client.id, campaignFromSettings.client.name);
				this.selectCampaign(campaignFromSettings.campaign.id, campaignFromSettings.campaign.name, campaignFromSettings.campaign.kind, campaignFromSettings.campaign.pacing, campaignFromSettings.campaign.integration, campaignFromSettings.campaign.client);
			}
			this.campaignCount()
			this.getClientsList()
			this.$root.$refs.LS = this;
		},
		methods:{
			// sortFunc: function (obj){
			// 	return obj.sort((a, b) => b.id - a.id );
			// },
			...mapMutations(['setSC', 'dataLoad', 'setData', 'updateData']),
			sortFunc: function (obj){
				return obj.sort(function(a, b) {
					var nameA = a.name.toUpperCase();
					var nameB = b.name.toUpperCase();
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					return 0;
				})
			},
			campaignCount(){
				window.api.get('campaign/active_count')
					.then(count => {
						this.activeCampaignCount = count.data;
					})
					.catch(countError => {
						console.log(countError);
					})
			},
			dropListClients(){
				this.clientsList = !this.clientsList;
				this.addClient = false;
				if (this.clientsList) {
					this.campaignsList = false;
				}
				this.addCampaign = false;
				this.DealDesk = false;
				window.AddC.deleteData();
				window.AddDD.deleteData();
			},
			dropListCampaigns(){
				window.AddDD.deleteData();
				this.campaignsList = !this.campaignsList;
				this.addClient = false;
				if (this.campaignsList) {
					this.clientsList = false;
				}
			},
			getClientsList(){
				window.api.get('client')
					.then(clients => {
						this.allClients = this.sortFunc(clients.data);
						this.clientsLoad = true
						this.dataLoad(true);
					})
					.catch(clientsError => {
						console.log(clientsError);
					})
			},
			selectClient(id, name){
				window.AddDD.deleteData();
				this.clientsList = false;
				if (id) {
					this.activeClient = {
						id: id,
						name: name
					}
				}

				this.campaignsList = true;

				window.api.get('campaign?client='+this.activeClient.id)
					.then(campaigns => {
						this.allCampaigns = this.sortFunc(campaigns.data);
						this.campaignsLoad = true;
					})
					.catch(clientsError => {
						console.log(clientsError);
					})


				// let th = this;
				// setTimeout(function(){
				// 	th.campaignsLoad = true;
				// }, 3000);
			},
			addNewClient(){
				this.addClient = !this.addClient;
				this.campaignsList = false;
				this.clientsList = false;
				window.AddDD.deleteData();
			},
			searchClient(){
				for(var name in this.allClients){
					if (this.allClients[name].name.toLowerCase() == this.newClient.name.toLowerCase()) {
						return true;
					}
				}
			},
			saveClient(){
				// var res = this.searchClient();
				// if (res) {
				// 	this.newClientError = true;
				// }else{
					window.api.post('client', this.newClient)
						.then(client => {
							this.addClient = !this.addClient;
							this.clientsList = true;
							this.newClient.name = '';
							this.newClient.client_type = 'contract';
							this.getClientsList();
							this.selectClient(client.data.id, client.data.name);
						})
						.catch(clientError => {
							this.newClientError = true;
							setTimeout(() => {this.newClientError = false}, 2000)
							console.log(clientError);
						})
				// }
				setTimeout(() => {
					this.newClientError = false;
				}, 2000)
			},
			selectCampaign(id, name, kind, pacing, integration, client){
				this.campaignsList = false;
				this.activeCampaign = {
					id: id,
					name: name,
					clientName: this.activeClient.name,
					kind: kind,
					pacing: pacing,
					integration: integration,
					client: client
				}
				this.DealDesk = false;
				// window.SelectC.setData(this.activeCampaign);
				this.setSC(this.activeCampaign);
				setTimeout(() => {
					this.addCampaign = false;
					window.AddC.deleteData();
				}, 2000);
				window.AddDD.deleteData();
			},
			addNewCampaign(){
				this.campaignsList = false;
				this.addCampaign = !this.addCampaign;
				if(this.addCampaign){
					var data = {
						clientID: this.activeClient.id,
						clientName: this.activeClient.name,
						status: this.addCampaign,
						allCampaign: this.allCampaigns
					}
					window.AddC.setData(data);
					window.AddDD.deleteData();
				}else{
					window.AddC.deleteData();
				}
				// window.AddC.init();
			},
			addNewDealDesk(){
				this.addDealDesk = !this.addDealDesk;
				if(this.addDealDesk){
					var data = {
						status: this.addDealDesk,
					}
					window.AddDD.setData(data);
					window.AddC.deleteData();
				}else{
					window.AddDD.deleteData();
				}
				// window.AddC.init();
			},
			showHF(){
				this.setData({'showHF': true});
				// this.updateData(['showHF', true]);
			},
			handleFocusLogo(){
				this.setData({'showHF': false});
			}
		}
	}
</script>