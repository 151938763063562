<template lang="pug">
	div.card-box.mb
		.card.creatives(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="Creatives", slug="Creatives", v-model="cardStatus", file="https://hourglass.infusemedia.com/staticfiles/Custom_Content-evergreen.pdf")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.creatives.length)}")
				p Empower your campaign with compelling creatives.

			//- div(v-if="GetSettings.landings.length")
			div
				.lp-bn
					.lp-slider
						.sl(v-if="GetSettings.landings.length")
							VueSlickCarousel(:arrows="true", :dots="redot")
								.single(v-for="(item, index) in GetSettings.landings")
									.settings
										svg(width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg")
											path(d="M15.9463 8.90605C15.9791 8.60864 16 8.30706 16 8.00026C16 7.69346 15.9791 7.39188 15.9457 7.09395L13.6615 6.28156C13.5478 5.90797 13.3986 5.55108 13.218 5.21194L14.2574 3.02469C13.8791 2.55032 13.4489 2.11979 12.9749 1.74114L10.7877 2.78259C10.4468 2.59959 10.0885 2.45102 9.71812 2.33908L8.90628 0.0547856C8.60836 0.0213925 8.30679 0 8 0C7.69321 0 7.39164 0.0208707 7.09372 0.0547856L6.28188 2.33908C5.9083 2.45231 5.55142 2.60101 5.21229 2.78259L3.02459 1.74114C2.54979 2.11942 2.11987 2.54988 1.74108 3.02469L2.7825 5.21089C2.60093 5.55004 2.45223 5.90745 2.33901 6.28052L0.0547838 7.09447C0.0213918 7.39188 0 7.69451 0 8.00078C0 8.30706 0.02087 8.60864 0.0547838 8.90657L2.33901 9.71949C2.45223 10.0925 2.60093 10.45 2.7825 10.7891L1.7416 12.9764C2.11987 13.4512 2.55032 13.8806 3.02511 14.2589L5.21072 13.2179C5.54986 13.3995 5.90726 13.5487 6.28031 13.6625L7.0932 15.9463C7.3906 15.9791 7.69217 16 7.99948 16C8.30627 16 8.60732 15.9791 8.90524 15.9457L9.71812 13.6614C10.0912 13.5477 10.4486 13.3985 10.7877 13.2179L12.9749 14.2573C13.4497 13.879 13.8791 13.4486 14.2574 12.9748L13.2175 10.7881C13.3991 10.4505 13.5483 10.092 13.662 9.71844L15.9457 8.90605H15.9463ZM8 10.5413C7.66625 10.5413 7.33576 10.4757 7.02739 10.348C6.71902 10.2203 6.43882 10.0332 6.20277 9.79724C5.72606 9.32071 5.45817 8.67432 5.45803 8.00026C5.45789 7.32621 5.72552 6.6797 6.20204 6.20298C6.67855 5.72625 7.32492 5.45835 7.99896 5.45821C8.67299 5.45807 9.31947 5.72571 9.79618 6.20224C10.2729 6.67877 10.5408 7.32516 10.5409 7.99922C10.5411 8.67327 10.2734 9.31978 9.79692 9.7965C9.32041 10.2732 8.67403 10.5411 8 10.5413Z")

										.action-list
											a(:href="item.landing_page", download, target="_blank").download Open
											//- p.edit Edit
											p(v-on:click="removeLP(index)").remove Remove

									img(:src="item.landing_page", alt="")
									.name
										//- p Landing Page {{index + 1}}
										p Landing Page {{ index + 1 }}
						
						.no-lp-bn(v-else)
							.info
								p No Landing Pages here yet

					.bn-list
						vuescroll(:ops="ops" v-if="GetSettings.banners.length")
							.list
								.single-item(v-for="(item, index) in GetSettings.banners")
									.settings
										svg(width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg")
											path(d="M15.9463 8.90605C15.9791 8.60864 16 8.30706 16 8.00026C16 7.69346 15.9791 7.39188 15.9457 7.09395L13.6615 6.28156C13.5478 5.90797 13.3986 5.55108 13.218 5.21194L14.2574 3.02469C13.8791 2.55032 13.4489 2.11979 12.9749 1.74114L10.7877 2.78259C10.4468 2.59959 10.0885 2.45102 9.71812 2.33908L8.90628 0.0547856C8.60836 0.0213925 8.30679 0 8 0C7.69321 0 7.39164 0.0208707 7.09372 0.0547856L6.28188 2.33908C5.9083 2.45231 5.55142 2.60101 5.21229 2.78259L3.02459 1.74114C2.54979 2.11942 2.11987 2.54988 1.74108 3.02469L2.7825 5.21089C2.60093 5.55004 2.45223 5.90745 2.33901 6.28052L0.0547838 7.09447C0.0213918 7.39188 0 7.69451 0 8.00078C0 8.30706 0.02087 8.60864 0.0547838 8.90657L2.33901 9.71949C2.45223 10.0925 2.60093 10.45 2.7825 10.7891L1.7416 12.9764C2.11987 13.4512 2.55032 13.8806 3.02511 14.2589L5.21072 13.2179C5.54986 13.3995 5.90726 13.5487 6.28031 13.6625L7.0932 15.9463C7.3906 15.9791 7.69217 16 7.99948 16C8.30627 16 8.60732 15.9791 8.90524 15.9457L9.71812 13.6614C10.0912 13.5477 10.4486 13.3985 10.7877 13.2179L12.9749 14.2573C13.4497 13.879 13.8791 13.4486 14.2574 12.9748L13.2175 10.7881C13.3991 10.4505 13.5483 10.092 13.662 9.71844L15.9457 8.90605H15.9463ZM8 10.5413C7.66625 10.5413 7.33576 10.4757 7.02739 10.348C6.71902 10.2203 6.43882 10.0332 6.20277 9.79724C5.72606 9.32071 5.45817 8.67432 5.45803 8.00026C5.45789 7.32621 5.72552 6.6797 6.20204 6.20298C6.67855 5.72625 7.32492 5.45835 7.99896 5.45821C8.67299 5.45807 9.31947 5.72571 9.79618 6.20224C10.2729 6.67877 10.5408 7.32516 10.5409 7.99922C10.5411 8.67327 10.2734 9.31978 9.79692 9.7965C9.32041 10.2732 8.67403 10.5411 8 10.5413Z")

										.action-list
											a(:href="item.banner", download, target="_blank").download Open
											p(v-on:click="removeBN(index)").remove Remove

									.image-name
										img(:src="item.banner", alt="")

										.name
											p Banner {{ index + 1 }}

						
						.no-lp-bn(v-else)
							.info
								p No banners here yet

				.action
					.contact(v-bind:class="{show: managerContact}", v-click-outside="handleFocusOut")
						.message-form
							.user-status.online
								p Online

							textarea(placeholder="Type your message here", v-model="messageForm.message", v-bind:class="{error: errorMessage}").message-area

							button.btn.send-message(type="button", v-on:click="sendMessage()") Send a message

						.contact-btn(v-on:click="showManagerForm()")
							.ico
								svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
									path(d="M5 15V14.5C4.86739 14.5 4.74021 14.5527 4.64645 14.6464L5 15ZM1 19H0.5C0.5 19.2022 0.621821 19.3846 0.808658 19.4619C0.995495 19.5393 1.21055 19.4966 1.35355 19.3536L1 19ZM3 1V0.5V1ZM17 1V0.5V1ZM5.5 4.59142C5.22386 4.59142 5 4.81528 5 5.09142C5 5.36756 5.22386 5.59142 5.5 5.59142V4.59142ZM14.5 5.59142C14.7761 5.59142 15 5.36756 15 5.09142C15 4.81528 14.7761 4.59142 14.5 4.59142V5.59142ZM5.5 7.59142C5.22386 7.59142 5 7.81528 5 8.09142C5 8.36756 5.22386 8.59142 5.5 8.59142V7.59142ZM14.5 8.59142C14.7761 8.59142 15 8.36756 15 8.09142C15 7.81528 14.7761 7.59142 14.5 7.59142V8.59142ZM5.5 10.5914C5.22386 10.5914 5 10.8153 5 11.0914C5 11.3676 5.22386 11.5914 5.5 11.5914V10.5914ZM14.5 11.5914C14.7761 11.5914 15 11.3676 15 11.0914C15 10.8153 14.7761 10.5914 14.5 10.5914V11.5914ZM18.5 13C18.5 13.3978 18.342 13.7794 18.0607 14.0607L18.7678 14.7678C19.2366 14.2989 19.5 13.663 19.5 13H18.5ZM18.0607 14.0607C17.7794 14.342 17.3978 14.5 17 14.5V15.5C17.663 15.5 18.2989 15.2366 18.7678 14.7678L18.0607 14.0607ZM17 14.5H5V15.5H17V14.5ZM4.64645 14.6464L0.646447 18.6464L1.35355 19.3536L5.35355 15.3536L4.64645 14.6464ZM1.5 19V3H0.5V19H1.5ZM1.5 3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934L1.23223 1.23223C0.763392 1.70107 0.5 2.33696 0.5 3H1.5ZM1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5V0.5C2.33696 0.5 1.70107 0.763392 1.23223 1.23223L1.93934 1.93934ZM3 1.5H17V0.5H3V1.5ZM17 1.5C17.3978 1.5 17.7794 1.65804 18.0607 1.93934L18.7678 1.23223C18.2989 0.763392 17.663 0.5 17 0.5V1.5ZM18.0607 1.93934C18.342 2.22064 18.5 2.60218 18.5 3H19.5C19.5 2.33696 19.2366 1.70107 18.7678 1.23223L18.0607 1.93934ZM18.5 3V13H19.5V3H18.5ZM5.5 5.59142H14.5V4.59142H5.5V5.59142ZM5.5 8.59142H14.5V7.59142H5.5V8.59142ZM5.5 11.5914H14.5V10.5914H5.5V11.5914Z")

							span contact client success manager

					.add-bl
						button.btn.add-new-btn(type="button", v-on:click="addNewBN()") ADD A NEW banner

				.creatives-table(v-if="emails")
					.t-row(v-for="(item, index) in GetSettings.creatives", v-if="item.subject_line && item.email_text")
						.cl
							.ico
								svg(width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg")
									path(d="M16.3125 0H1.68751C0.75696 0 0 0.728924 0 1.62501V11.375C0 12.2711 0.75696 13 1.68751 13H16.3125C17.243 13 18 12.2711 18 11.375V1.62501C18 0.728924 17.243 0 16.3125 0ZM16.3125 1.08333C16.3889 1.08333 16.4616 1.0986 16.5281 1.12526L9 7.40824L1.47188 1.12526C1.53834 1.09863 1.61105 1.08333 1.68747 1.08333H16.3125ZM16.3125 11.9166H1.68751C1.37715 11.9166 1.125 11.6739 1.125 11.375V2.26981L8.6314 8.53441C8.73743 8.62273 8.86872 8.66665 9 8.66665C9.13128 8.66665 9.26256 8.62277 9.3686 8.53441L16.875 2.26981V11.375C16.875 11.6739 16.6228 11.9166 16.3125 11.9166Z")
							p Subject Line: 
								marquee(behavior="scroll" direction="left") {{ item.subject_line }}

						.cl
							p {{ item.email_text }}

						.cl
							.actions-row
								.view(v-on:click="view(index)")
									svg(width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg")
										path(d="M8.99999 1.10002C11.3004 1.10002 13.2475 2.15738 14.4758 3.04454C15.9311 4.09559 16.68 5.11225 16.8742 5.50002C16.6798 5.88775 15.9311 6.90441 14.4758 7.95549C13.2475 8.84262 11.3004 9.89998 8.99999 9.89998C6.69953 9.89998 4.75254 8.84262 3.52417 7.95549C2.06862 6.90441 1.31999 5.88775 1.12552 5.50002C1.31999 5.11225 2.06862 4.09559 3.52389 3.04454C4.75254 2.15738 6.69953 1.10002 8.99999 1.10002ZM8.99999 0C4.02955 0 0 4.4 0 5.50002C0 6.60003 4.02955 11 8.99999 11C13.9705 11 18 6.6 18 5.50002C18 4.40003 13.9705 0 8.99999 0Z")
										ellipse(cx="9.00107" cy="5.49959" rx="2.4" ry="2.42")

								.change(v-on:click="editSL(index)")
									svg(width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg")
										path(d="M15.9463 8.90605C15.9791 8.60864 16 8.30706 16 8.00026C16 7.69346 15.9791 7.39188 15.9457 7.09395L13.6615 6.28156C13.5478 5.90797 13.3986 5.55108 13.218 5.21194L14.2574 3.02469C13.8791 2.55032 13.4489 2.11979 12.9749 1.74114L10.7877 2.78259C10.4468 2.59959 10.0885 2.45102 9.71812 2.33908L8.90628 0.0547856C8.60836 0.0213925 8.30679 0 8 0C7.69321 0 7.39164 0.0208707 7.09372 0.0547856L6.28188 2.33908C5.9083 2.45231 5.55142 2.60101 5.21229 2.78259L3.02459 1.74114C2.54979 2.11942 2.11987 2.54988 1.74108 3.02469L2.7825 5.21089C2.60093 5.55004 2.45223 5.90745 2.33901 6.28052L0.0547838 7.09447C0.0213918 7.39188 0 7.69451 0 8.00078C0 8.30706 0.02087 8.60864 0.0547838 8.90657L2.33901 9.71949C2.45223 10.0925 2.60093 10.45 2.7825 10.7891L1.7416 12.9764C2.11987 13.4512 2.55032 13.8806 3.02511 14.2589L5.21072 13.2179C5.54986 13.3995 5.90726 13.5487 6.28031 13.6625L7.0932 15.9463C7.3906 15.9791 7.69217 16 7.99948 16C8.30627 16 8.60732 15.9791 8.90524 15.9457L9.71812 13.6614C10.0912 13.5477 10.4486 13.3985 10.7877 13.2179L12.9749 14.2573C13.4497 13.879 13.8791 13.4486 14.2574 12.9748L13.2175 10.7881C13.3991 10.4505 13.5483 10.092 13.662 9.71844L15.9463 8.90605ZM8 10.5413C7.66625 10.5413 7.33576 10.4757 7.02739 10.348C6.71902 10.2203 6.43882 10.0332 6.20277 9.79724C5.72606 9.32071 5.45817 8.67432 5.45803 8.00026C5.45789 7.32621 5.72552 6.6797 6.20204 6.20298C6.67855 5.72625 7.32492 5.45835 7.99896 5.45821C8.67299 5.45807 9.31947 5.72571 9.79618 6.20224C10.2729 6.67877 10.5408 7.32516 10.5409 7.99922C10.5411 8.67327 10.2734 9.31978 9.79692 9.7965C9.32041 10.2732 8.67403 10.5411 8 10.5413Z")

			//- .empty-section(v-else)
			//- 	.ico
			//- 		img(src="@/assets/empty-section.svg", alt="")
			//- 	p Empower your campaign with compelling creatives.

			button.btn.add-new-btn(type="button", v-on:click="addNewSL()") Add A NEW EMAIL

			//- button.btn.add-new-btn(type="button", v-on:click="testSaveFile()") Save Files

		.popup.creatives(v-if="popupSLedit")
			.close(v-on:click="hidePopups()")

			.inside-form
				label Subject Line
				input(
					type="text",
					placeholder="Text here...",
					v-bind:class="{error: errorSL}",
					v-model="fieldSL",
					autocomplete="xz"
				).inp

				label Email Text
				textarea(
					placeholder="Text here...",
					v-bind:class="{error: errorEmail}",
					v-model="fieldEmail",
				).area-field

				.for-btns(v-if="editIndex != null")
					button.btn.simple(type="button", v-on:click="updateSL()") APPLY
					button.btn.cancel(type="button", v-on:click="deleteSL()") DELETE

				button.btn.simple(type="button", v-on:click="addSL()", v-if="newSL") APPLY

		.popup.creatives(v-if="popupView")
			.close(v-on:click="hidePopups()")

			.inside-form
				label Subject Line
				.fake-inp
					p {{ GetSettings.creatives[viewIndex].subject_line }}

				label Email Text
				.fake-area
					vuescroll(:ops="ops")
						p {{ GetSettings.creatives[viewIndex].email_text }}

				.switch-slide
					.prev(v-on:click="prevSlide()")

					.dots
						.dot(
							v-for="item in emailsIndex",
							v-bind:class="{active: item == viewIndex}",
							v-on:click="viewIndex = item"
						)

					.next(v-on:click="nextSlide()")

		.popup.creatives(v-if="popupBN")
			.close(v-on:click="hidePopups()")
			.inside-form
				label Banner
				.drop-zone(
					@drop="drop",
				)
					input(
						type="file",
						v-on:change="onChange",
						ref="file").file-picker
					div(v-if="assetFile.length > 0")
						p {{ assetFile[0].name }}
					div(v-else)
						p BROWSE FILE
						span or
							br
							| Drag and Drop file here (.png, .jpg, .jpeg)
				span.error(v-if="fileError && assetFile.length > 0") Please choose next file extension: .png, .jpg, .jpeg
				button.btn.simple(type="button", v-on:click="addBN()", v-bind:class="{disable: fileError}") APPLY


</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";

	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import vuescroll from 'vuescroll'

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'Creatives',
		components:{
			CardHeader,
			VueSlickCarousel,
			vuescroll
		},
		data() {
			return {
				cardStatus: true,
				height: 0,
				ops:{
					bar:{
						background: '#FC9000',
						onlyShowBarOnScroll: false,
						keepShow: true,
						size: '2px'
					},
					rail:{
						background: '#F5F5F5',
						opacity: 1,
						right: 0,
						size: '2px'
					}
				},
				assetFile: [],
				emailsIndex: [],
				emails: null,
				lps: null,
				bns: null,
				errorSL: false,
				errorEmail: false,
				fieldSL: null,
				fieldEmail: null,
				popupSLedit: false,
				popupLP: false,
				popupBN: false,
				popupView: false,
				editIndex: null,
				newSL: false,
				tempFileURL: null,
				viewIndex: null,
				managerContact: false,
				errorMessage: false,
				messageForm:{
					message: '',
					campaign: null,
					manager: null
				},
				someNumber: 0,
				originalFile: null,
				allFiles: '',
				redot: true,
				fileError: true
			}
		},
		created(){
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "Creatives") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		mounted(){
			this.getAllCounts();
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			onChange() {
				this.assetFile = [...this.$refs.file.files];
				this.tempFileURL = URL.createObjectURL(this.assetFile[0]);
				this.originalFile = this.$refs.file.files[0];
				var fileName = this.assetFile[0].name.split('.');
				if (fileName[fileName.length - 1] == 'png' || fileName[fileName.length - 1] == 'jpg' || fileName[fileName.length - 1] == 'jpeg') {
					this.fileError = false;
				}else{
					this.fileError = true;
				}
			},
			drop(event) {
				event.preventDefault();
				this.$refs.file.files = event.dataTransfer.files;
				this.onChange();
			},
			nextNumber(){
				this.someNumber += 1;
				return this.someNumber;
			},
			getAllCounts(){
				this.emails = 0;
				this.emailsIndex = [];
				this.lps = 0;
				this.bns = 0;
				for(var item in this.GetSettings.creatives){
					if (this.GetSettings.creatives[item].email_text) {
						this.emails += 1;
						this.emailsIndex.push(item)
					}
					// if (this.GetSettings.creatives[item].landing_page) {
					// 	this.lps += 1;
					// 	this.GetSettings.creatives[item].lpNumber = this.lps;
					// }else{
					// 	this.GetSettings.creatives[item].lpNumber = null;
					// }
					// if (this.GetSettings.creatives[item].banners) {
					// 	this.bns += 1;
					// 	this.GetSettings.creatives[item].bNumber = this.bns;
					// }else{
					// 	this.GetSettings.creatives[item].bNumber = null;
					// }
				}
				this.updateSettings(['creatives', this.GetSettings.creatives]);
			},
			removeLP(index){
				this.GetSettings.landings.splice(index, 1);
				this.updateSettings(['landings', this.GetSettings.landings]);
				this.redot = false;
				setTimeout(() => {
					this.redot = true;
				}, 50);
				// this.getAllCounts();
				
			},
			removeBN(index){
				this.GetSettings.banners.splice(index, 1);
				this.updateSettings(['banners', this.GetSettings.banners]);
				// this.getAllCounts();
			},
			hidePopups(){
				this.newSL = false;
				this.errorSL = false;
				this.errorEmail = false;
				this.fieldSL = null,
				this.fieldEmail = null;
				this.editIndex = null;
				this.popupSLedit = false;
				this.popupLP = false;
				this.popupBN = false;
				this.popupView = false;
				this.viewIndex = null;
				this.assetFile = false;
				this.tempFileURL = false;
				this.originalFile = null;
				this.fileError = true;
				this.$emit('input', false);
			},
			editSL(index){
				if (this.cardStatus) {
					this.fieldSL = this.GetSettings.creatives[index].subject_line;
					this.fieldEmail = this.GetSettings.creatives[index].email_text;
					this.editIndex = index;
					this.popupSLedit = true;
					this.$emit('input', this.popupSLedit);
				}
			},
			updateSL(){
				var error = false;
				if (!this.fieldSL || this.fieldSL.length < 2) {
					this.errorSL = true;
					error = true;
				}
				if (!this.fieldEmail || this.fieldEmail.length < 2) {
					this.errorEmail = true;
					error = true;
				}

				if(!error){
					this.GetSettings.creatives[this.editIndex].subject_line = this.fieldSL;
					this.GetSettings.creatives[this.editIndex].email_text = this.fieldEmail;
					this.updateSettings(['creatives', this.GetSettings.creatives]);
					this.hidePopups();
				}
				
				setTimeout(() => {
					this.errorSL = false;
					this.errorEmail = false;
					error = false;
				}, 2000)
			},
			deleteSL(){
				this.GetSettings.creatives[this.editIndex].subject_line = null;
				this.GetSettings.creatives[this.editIndex].email_text = null;
				this.updateSettings(['creatives', this.GetSettings.creatives]);
				this.hidePopups();
				this.getAllCounts();
			},
			addNewSL(){
				this.newSL = true;
				this.popupSLedit = true;
				this.$emit('input', this.popupSLedit);
			},
			addSL(){
				var error = false;
				if (!this.fieldSL || this.fieldSL.length < 2) {
					this.errorSL = true;
					error = true;
				}
				if (!this.fieldEmail || this.fieldEmail.length < 2) {
					this.errorEmail = true;
					error = true;
				}
				if(!error){
					var key =  Object.keys(this.GetSettings.creatives).length;
					var pattern = {
						subject_line: this.fieldSL,
						email_text: this.fieldEmail
					}
					this.$set(this.GetSettings.creatives, key, pattern);
					this.updateSettings(['creatives', this.GetSettings.creatives]);
					this.hidePopups();
					this.getAllCounts();
				}
				setTimeout(() => {
					this.errorSL = false;
					this.errorEmail = false;
					error = false;
				}, 2000)
			},
			view(index){
				this.viewIndex = index;
				this.fieldSL = this.GetSettings.creatives[index].subject_line;
				this.fieldEmail = this.GetSettings.creatives[index].email_text;
				this.popupView = true;
				this.$emit('input', this.popupView);
			},
			addNewBN(){
				this.popupBN = true;
				this.$emit('input', this.popupBN);
			},
			addBN(){
				var key =  Object.keys(this.GetSettings.banners).length;
				var pattern = {
					banner: this.tempFileURL,
					originalFile: this.originalFile
				}
				this.$set(this.GetSettings.banners, key, pattern);
				this.updateSettings(['banners', this.GetSettings.banners]);
				this.hidePopups();
				// this.getAllCounts();
			},
			prevSlide(){
				var ind = this.emailsIndex.findIndex(elem => elem == this.viewIndex);
				if (ind == 0) {
					this.viewIndex = this.emailsIndex[this.emailsIndex.length - 1];
				}else{
					this.viewIndex = this.emailsIndex[ind - 1];
				}
			},
			nextSlide(){
				var ind = this.emailsIndex.findIndex(elem => elem == this.viewIndex);
				if ((ind + 1) == this.emailsIndex.length) {
					this.viewIndex = this.emailsIndex[0];
				}else{
					this.viewIndex = this.emailsIndex[ind + 1];
				}
			},
			showManagerForm(){
				this.managerContact = !this.managerContact;
			},
			handleFocusOut() {
				this.managerContact = false;
			},
			sendMessage(){
				this.errorMessage = false;
				this.messageForm.campaign = this.GetSettings.campaing_id;
				this.messageForm.manager = this.GetSettings.managed_by;
				if (this.messageForm.message.length < 2) {
					this.errorMessage = true;
					setTimeout(() => {
						this.errorMessage = false;
					}, 2000)
				}
				if (!this.errorMessage) {
					window.api.post("campaign_settings/message/", this.messageForm)
						.then(() => {
							setTimeout(() => {
								this.managerContact = false
								this.messageForm = { message: '', campaign: null, manager: this.GetSettings.managed_by }
							}, 500)
						})
						.catch(error => {
							console.log(error)
						})
				}
			},
			// testSaveFile(){
			// 	let formData = new FormData();
			// 	formData.append('campaign', 450)
			// 	for( var i = 0; i < this.GetSettings.banners.length; i++ ){
			// 		if (this.GetSettings.banners[i].originalFile) {
			// 			let file = this.GetSettings.banners[i].originalFile
			// 			formData.append('banner_450', file);
			// 		}
			// 	}
			// 	window.api.post('cr_files_upload/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
			// 		.then(() => {
			// 			console.log('good')
			// 		})
			// 		.catch(settingsError => {
			// 			console.log('error')
			// 		})
			// }
		}
	}
</script>