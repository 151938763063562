// for selected campaign
import Vue from 'vue';

export default{
	actions: {
	},
	mutations: {
		setTarget(state, data){
			state.targetScroll = data;
		},
		updateTarget(state, data){
			// state.settings[data[0]] = data[1]
			Vue.set(state.targetScroll, data[0], data[1])
		},
	},
	state: {
		targetScroll: null
	},
	getters:{
		GetTarget(state){
			return state.targetScroll
		}
	}
}