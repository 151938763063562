// for selected campaign
import Vue from 'vue';

export default{
	actions: {
	},
	mutations: {
		setSettings(state, data){
			state.settings = data;
		},
		updateSettings(state, data){
			// state.settings[data[0]] = data[1]
			Vue.set(state.settings, data[0], data[1])
		},
		setClient(state, data){
			state.client = data;
		},
	},
	state: {
		settings: null,
		client: null
	},
	getters:{
		GetSettings(state){
			return state.settings
		},
		GetClient(state){
			return state
		}
	}
}