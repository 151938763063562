<template lang="pug">
	.settings-header
		.top
			.left
				button.btn.add-new-btn(type="button", v-on:click="saveCampaign()") Save Changes

			h4 {{ GetSettings.client.name }}

			.right
				button.btn.play-pause.paused(type="button", v-if="GetSettings.state == 'running'", v-on:click="runPause('pause')") Pause
				button.btn.play-pause.play(type="button", v-else, v-on:click="runPause('running')") RESUME
				router-link(to="./engine", target="_blank").btn.engine engine

		.popup.save-campaign(v-if="popupSave")
			.close(v-on:click="hide()")
			p Do you want to save changes?

			.btns-row
				button.btn.simple(type="button", v-on:click="saveWithName()", v-bind:class="{disable: disableBTN}") Save as a new Campaign
				button.btn.border(type="button", v-on:click="saveAll()", v-bind:class="{disable: disableBTN}") Save
				button.btn.cancel(type="button", v-on:click="hide()") Cancel

		.popup.save-campaign(v-if="popupCmName")
			.close(v-on:click="hide()")
			.inside-form
				label Client
				ClientsList(:list="GetSettings.clients", :default="saveForClient.name", v-model="saveForClient").select-client

				label Campaign Name
				input(type="text", placeholder="Text here...", v-model="newCmName", autocomplete="xz", v-bind:class="{error: nameError}").inp

				.btns-row.other
					button.btn.simple(type="button", v-on:click="saveAll('stay')") Submit and 
						br
						| stay on this page
					button.btn.simple(type="button", v-on:click="saveAll('open-new')") Submit and open 
						br
						| the saved campaign

		.error-box(v-if="errorSave")
			.close(v-on:click="hide()")
			.fv
				label Post:
				.result-box
					pre {{weSend}}

			.fv
				label Errors:
				.result-box
					pre {{allErrors}}

</template>

<script>

	import ClientsList from "./clients-list.vue";
	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'Header',
		components: {
			ClientsList
		},
		data() {
			return {
				save: false,
				popupSave: false,
				popupCmName: false,
				errorSave: false,
				newCmName: '',
				allErrors: '',
				weSend: '',
				saveForClient:{
					id: 34,
					name: 'Prospective'
				},
				allCampaigns: '',
				disableBTN: true,
				nameError: false
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		created(){

		},
		filters: {
			pretty: function(value) {
				return JSON.stringify(JSON.parse(value), null, 2);
			}
		},
		methods: {
			...mapMutations(['updateSettings']),
			getCampaignList(){
				window.api.get('campaign/campaign_list')
					.then(campaigns => {
						this.allCampaigns = campaigns.data;
						this.disableBTN = false;
					})
					.catch(clientsError => {
						console.log(clientsError);
					})
			},
			hide(){
				this.popupSave = false;
				this.popupCmName = false;
				this.errorSave = false;
				this.save = false;
				this.newCmName = '';
				this.saveForClient = {
					id: 34,
					name: 'Prospective'
				}
				this.$emit('input', false);
			},
			saveCampaign(){
				var t = this.GetSettings.abm_goal_percent;
				if(this.GetSettings.goal_requested){
					var newGoal = this.GetSettings.goal_requested.slice(0, -1);
					if(!isNaN(newGoal) && newGoal <= 100 && newGoal > 0){
						t = newGoal;
					}else{
						t = 100;
					}
				}
				console.log('ABM Goal: ' + t);
				this.getCampaignList();
				this.popupSave = true;
				this.$emit('input', !this.save);
			},
			saveWithName(){
				this.popupSave = false;
				this.popupCmName = true;
			},
			runPause(state){
				this.updateSettings(['state', state]);
			},
			saveAll(next){
				// this.GetSettings.
				if (this.popupCmName && this.newCmName.length < 1) {
					this.nameError = true;
					setTimeout(() => {
						this.nameError = false;
					}, 3000)
					return;
				}

				var creatives = [];
				for (var creat in this.GetSettings.creatives) {
					creatives.push({
						subject_line: this.GetSettings.creatives[creat].subject_line,
						email_text: this.GetSettings.creatives[creat].email_text
					})
				}

				var banners = [];
				for (var banner in this.GetSettings.banners) {
					if (this.GetSettings.banners[banner].id) {
						banners.push(this.GetSettings.banners[banner].id)
					}
				}

				var landings = [];
				for (var landing in this.GetSettings.landings) {
					if (this.GetSettings.landings[landing].id) {
						landings.push(this.GetSettings.landings[landing].id)
					}
				}

				var targets = [];
				for (var target in this.GetSettings.targets) {
					if(this.GetSettings.targets[target].campaign_pos_type){
						targets.push({
							campaign_pos_type: this.GetSettings.targets[target].campaign_pos_type,
							leads_goal: this.GetSettings.targets[target].leads_goal,
							pos_type_name: this.GetSettings.targets[target].pos_type_name,
							grade: this.GetSettings.targets[target].grade
						})
					}else{
						targets.push({
							leads_goal: this.GetSettings.targets[target].leads_goal,
							pos_type_name: this.GetSettings.targets[target].pos_type_name,
							grade: this.GetSettings.targets[target].grade
						})
					}
				}

				var assets = [];
				for (var asset in this.GetSettings.assets) {
					// var allTitles = [];
					// for (var i = this.GetSettings.assets[asset].titles.length - 1; i >= 0; i--) {
					// 	allTitles.push(this.GetSettings.assets[asset].titles[i].id)
					// }
					var assetObj = {
						name: this.GetSettings.assets[asset].name,
						state: this.GetSettings.assets[asset].state
					}
					assets.push(assetObj)
				}

				var intents = [];
				for(var intent in this.GetSettings.intents){
					// var allCompanies = [];
					// for (var c = this.GetSettings.intents[intent].company.length - 1; c >= 0; c--) {
					// 	allCompanies.push(this.GetSettings.intents[intent].company[c].id)
					// }
					intents.push({
						state: this.GetSettings.intents[intent].state,
						name: this.GetSettings.intents[intent].name,
						campaign: this.GetSettings.intents[intent].campaign,
						// company: allCompanies,
						kind: this.GetSettings.intents[intent].kind
					})
				}

				var titles = [];
				for (var title in this.GetSettings.titles) {
					if (!this.GetSettings.titles[title].job_title.id) {
						titles.push({
							state:  this.GetSettings.titles[title].state,
							user_job_title: this.GetSettings.titles[title].job_title.name,
							goal: isNaN(this.GetSettings.titles[title].goal) ? 0 : parseInt(this.GetSettings.titles[title].goal)
						})
					}else{
						titles.push({
							state:  this.GetSettings.titles[title].state,
							job_title: this.GetSettings.titles[title].job_title.id,
							goal: isNaN(this.GetSettings.titles[title].goal) ? 0 : parseInt(this.GetSettings.titles[title].goal)
						})
					}
				}

				var companies = [];
				for (var company in this.GetSettings.companies_size) {
					if (!this.GetSettings.companies_size[company].company_size.id) {
						companies.push({
							state: this.GetSettings.companies_size[company].state,
							user_company_size: this.GetSettings.companies_size[company].company_size.name
						})
					}else{
						companies.push({
							state: this.GetSettings.companies_size[company].state,
							company_size: this.GetSettings.companies_size[company].company_size.id
						})
					}
				}

				var revenues = [];
				for (var revenue in this.GetSettings.revenues) {
					if (!this.GetSettings.revenues[revenue].revenue.id) {
						revenues.push({
							state: this.GetSettings.revenues[revenue].state,
							user_revenue: this.GetSettings.revenues[revenue].revenue.name
						})
					}else{
						revenues.push({
							state: this.GetSettings.revenues[revenue].state,
							revenue: this.GetSettings.revenues[revenue].revenue.id
						})
					}
				}

				var geolocations = [];
				for (var geolocation in this.GetSettings.geolocations) {
					var geo = {
						state: this.GetSettings.geolocations[geolocation].state,
						name: this.GetSettings.geolocations[geolocation].geolocation.name,
					}
					if (!this.GetSettings.geolocations[geolocation].geolocation.id) {
						geo.user_geolocation = this.GetSettings.geolocations[geolocation].geolocation.name;
					}else{
						geo.geolocation = this.GetSettings.geolocations[geolocation].geolocation.id;
					}
					if (!isNaN(this.GetSettings.geolocations[geolocation].goal_per_geo)) {
						geo.goal_per_geo = this.GetSettings.geolocations[geolocation].goal_per_geo;
					}
					geolocations.push(geo)
				}

				var bantPos = {
					'budget': 0,
					'authority': 1,
					'need': 2,
					'time': 3,
				}

				var bants = [];
				for (var bant in this.GetSettings.bants) {

					if (this.GetSettings.bants[bant].question.id) {
						bants.push({
							question: this.GetSettings.bants[bant].question.id,
							answer: this.GetSettings.bants[bant].answer.id,
							question_txt: this.GetSettings.bants[bant].question.question,
							answer_txt: this.GetSettings.bants[bant].answer.answer,
							kind: this.GetSettings.bants[bant].question.kind,
							pos: bantPos.[this.GetSettings.bants[bant].question.kind],
						})
					}
				}

				var cqs = [];
				for (var cq in this.GetSettings.custom_questions) {
					if (this.GetSettings.custom_questions[cq].question.id) {
						cqs.push({
							state: this.GetSettings.custom_questions[cq].state,
							question: this.GetSettings.custom_questions[cq].question.id,
							answer: this.GetSettings.custom_questions[cq].answer.id,
							question_txt: this.GetSettings.custom_questions[cq].question.question,
							answer_txt: this.GetSettings.custom_questions[cq].answer.answer
						})
					}
				}

				var abms = [];
				for (var abm in this.GetSettings.abms) {
					var abmsOBJ = {
						state: this.GetSettings.abms[abm].state,
						title: this.GetSettings.abms[abm].title,
					}
					if (this.GetSettings.abms[abm].accounts_value > 0) {
						abmsOBJ.accounts_value = this.GetSettings.abms[abm].accounts_value
					}
					abms.push(abmsOBJ)
				}

				var ibs = [];
				for (var ib in this.GetSettings.install_base) {
					ibs.push({
						state: this.GetSettings.install_base[ib].state,
						name: this.GetSettings.install_base[ib].name,
					})
				}

				var fts = [];
				for (var ft in this.GetSettings.fair_trades) {
					fts.push({
						state: this.GetSettings.fair_trades[ft].state,
						name: this.GetSettings.fair_trades[ft].name,
						value: this.GetSettings.fair_trades[ft].value
					})
				}

				var lcs = [];
				for (var lc in this.GetSettings.lead_cascades) {
					lcs.push({
						state: this.GetSettings.lead_cascades[lc].state,
						name: this.GetSettings.lead_cascades[lc].name
					})
				}

				if (this.newCmName.length < 1) {
					this.newCmName = this.GetSettings.client.name + '_' + window.moment(new Date()).format("MM/DD/YYYY") + '_' + this.$store.state.Auth.first_name + '_' + this.$store.state.Auth.last_name;
				}

				var integration_type;
				if (!this.GetSettings.integration_type) {
					integration_type = 6;
				}else{
					integration_type = this.GetSettings.integration_type.id;
				}
				var part_of_the_map = 3;
				// var part_of_the_map;
				// if (!this.GetSettings.part_of_the_map) {
				// 	part_of_the_map = 3;
				// }else{
				// 	part_of_the_map = this.GetSettings.part_of_the_map.id;
				// }

				var pacing_type;
				if (!this.GetSettings.pacing_type) {
					pacing_type = 1;
				}else{
					pacing_type = this.GetSettings.pacing_type.id;
				}

				if(this.GetSettings.newGoal || !isNaN(this.newGoal) && this.newGoal < 100 && this.newGoal > 0){
					this.GetSettings.abm_goal_percent = this.GetSettings.newGoal;
				}

				var agp;
				if(this.GetSettings.goal_requested){
					var newGoal = this.GetSettings.goal_requested.slice(0, -1);
					if(!isNaN(newGoal) && newGoal <= 100 && newGoal > 0){
						agp = newGoal;
					}else{
						agp = 100;
					}
				}else{
					agp = this.GetSettings.goal_requested;
				}

				var ifgp;
				if(this.GetSettings.goal_intent_requested){
					var newGoalIntent = this.GetSettings.goal_intent_requested.slice(0, -1);
					if(!isNaN(newGoalIntent) && newGoalIntent <= 100 && newGoalIntent > 0){
						ifgp = newGoalIntent;
					}else{
						ifgp = 100;
					}
				}else{
					ifgp = this.GetSettings.goal_intent_requested;
				}

				var industries = [];
				for (var indust in this.GetSettings.industries) {
					if (!this.GetSettings.industries[indust].id) {
						industries.push({
							user_industry:  this.GetSettings.industries[indust].name,
							state: this.GetSettings.industries[indust].state
						})
					}else{
						industries.push({
							name:  this.GetSettings.industries[indust].name,
							industry: this.GetSettings.industries[indust].industry,
							state: this.GetSettings.industries[indust].state
						})
					}
				}

				var sups = [];
				for (var sps in this.GetSettings.suppression_list) {
					sups.push({
						title: this.GetSettings.suppression_list[sps].title,
						accounts_value: this.GetSettings.suppression_list[sps].accounts_value,
						state: this.GetSettings.suppression_list[sps].state
					})
				}

				var cm = {
					client: this.saveForClient.id,
					email: this.$store.state.Auth.email,
					name: this.newCmName,
					integration_type: integration_type, //
					kind: 'copy',
					part_of_the_map: part_of_the_map, //
					nurturing_parameters: this.GetSettings.nurturing_parameters,
					abm_look_a_like_state: this.GetSettings.abm_look_a_like_state,
					pacing_type: pacing_type, //
					abm_goal_percent: agp,
					intent_feed_goal_percent: ifgp,
					start_date: this.GetSettings.start_date,
					end_date: this.GetSettings.end_date,
					creatives: creatives,
					source_campaign: this.GetSettings.campaing_id,
					source_banners: banners,
					source_landings: landings,
					targets: targets,
					assets: assets,
					intents: intents,
					titles: titles,
					companies: companies,
					revenues: revenues,
					geolocations: geolocations,
					bants: bants,
					cqs: cqs,
					abms: abms,
					ibs: ibs,
					fair_trades: fts,
					lead_cascades: lcs,
					industries: industries,
					sups: sups
				}

				window.api.post('campaign', cm)
					.then(settings => {
						this.newCmName = '';
						this.saveSectors(settings.data.id);
						this.saveNurturing(settings.data.id);
						this.saveBant(settings.data.id);
						this.saveCQ(settings.data.id);
						this.uploadBanners(settings.data.id);
						this.saveIT(next, settings.data.id);
						// this.hide();
						// this.disableBTN = true;
						// if(next == 'open-new'){
						// 	localStorage.setItem('campaign_id', settings.data.id);
						// 	this.$router.go(this.$router.currentRoute)
						// }
					})
					.catch(settingsError => {
						this.weSend = cm;
						this.allErrors = settingsError.response.data;
						this.errorSave = true;
					})
			},
			saveSectors(id){
				var sectors = [];
				for(var sector in this.GetSettings.sections){
					sectors.push({
						slug: this.GetSettings.sections[sector].slug,
						enabled: this.GetSettings.sections[sector].enabled
					})
				}
				window.api.post('campaign/'+id+'/update_settings/', sectors)
					.then(() => {

					})
					.catch(sectorsError => {
						console.log(sectorsError.response.data);
					})
			},
			updateBant(id, newBants){
				window.api.post('campaign/'+id+'/update_bant/', newBants)
					.then(() => {
						
					})
					.catch(bantError => {
						console.log(bantError.response.data);
					})
			},
			postBant(id, form){
				window.api.post('campaign/'+id+'/create_bant/', form.question)
					.then(bantQuestion => {
						var index = this.GetSettings.bants.map(e => e.question.question).indexOf(bantQuestion.data.question);
						var ansIndex = bantQuestion.data.answers.map(e => e.answer).indexOf(form.answer.answer);
						var obj = {
							answer: bantQuestion.data.answers[ansIndex],
							question: bantQuestion.data
						}

						this.updateBant(id, [{'answer': obj.answer.id, 'question': obj.question.id}])

						this.$set(this.GetSettings.bants, index, obj);
						this.updateSettings(['bants', this.GetSettings.bants]);
					})
					.catch(bantError => {
						console.log(bantError);
					})
			},
			saveBant(id){
				var bantPos = {
					'budget': 0,
					'authority': 1,
					'need': 2,
					'time': 3,
				}
				for (var q = 0; q <= this.GetSettings.bants.length - 1; q++) {
					if (!this.GetSettings.bants[q].question.id) {
						var bantObj = this.GetSettings.bants[q];
						bantObj.question.pos = bantPos.[this.GetSettings.bants[q].question.kind];
						this.postBant(id, bantObj)
					}
				}
			},
			//
			updateCQ(id, newCQ){
				window.api.post('campaign/'+id+'/update_cq/', newCQ)
					.then(() => {
						
					})
					.catch(cqError => {
						console.log(cqError.response.data);
					})
			},
			postCQ(id, form){
				window.api.post('campaign/'+id+'/create_cq/', form.question)
					.then(cqQuestion => {
						var index = this.GetSettings.custom_questions.map(e => e.question.question).indexOf(cqQuestion.data.question);
						var ansIndex = cqQuestion.data.answers.map(e => e.answer).indexOf(form.answer.answer);
						var obj = {
							answer: cqQuestion.data.answers[ansIndex],
							question: cqQuestion.data,
							state: this.GetSettings.custom_questions[index].state
						}

						this.updateCQ(id, [{'answer': obj.answer.id, 'question': obj.question.id, 'state': obj.state}])

						this.$set(this.GetSettings.custom_questions, index, obj);
						this.updateSettings(['custom_questions', this.GetSettings.custom_questions]);
					})
					.catch(cqError => {
						console.log(cqError);
					})
			},
			saveCQ(id){
				for (var q = 0; q <= this.GetSettings.custom_questions.length - 1; q++) {
					if (!this.GetSettings.custom_questions[q].question.id) {
						this.postCQ(id, this.GetSettings.custom_questions[q])
					}
				}
			},
			saveNurturing(id){
				var nurturings = [];
				for(var nt in this.GetSettings.nurturings){
					var obj = {
						assets: this.GetSettings.nurturings[nt].assets.id,
						state: this.GetSettings.nurturings[nt].state,
						nurturing_stages: this.GetSettings.nurturings[nt].nurturing_stages.id
					}
					nurturings.push(obj)
				}
				window.api.post('campaign/'+id+'/create_nurturing/', nurturings)
					.then(() => {

					})
					.catch(nurturingsError => {
						console.log(nurturingsError.response.data);
					})
			},
			//
			saveIT(next, id){
				var itcurated = [];
				for(var curated in this.GetSettings.itcurateds){
					itcurated.push({
						slug: this.GetSettings.itcurateds[curated].curated.slug,
						status: this.GetSettings.itcurateds[curated].status
					})
				}
				var it = itcurated;
				window.api.post('campaign/' + id + '/update_curated/', it)
					.then(() => {
						this.hide();
						this.disableBTN = true;
						if(next == 'open-new'){
							localStorage.setItem('campaign_id', id);
							this.$router.go(this.$router.currentRoute)
						}
					})
					.catch(settingsError => {
						this.weSend = it;
						this.allErrors = settingsError.response.data;
						this.errorSave = true;
					})
			},
			//
			uploadBanners(id){
				var have = false;
				let formData = new FormData();
				formData.append('campaign', id)
				for( var i = 0; i < this.GetSettings.banners.length; i++ ){
					if (this.GetSettings.banners[i].originalFile) {
						let file = this.GetSettings.banners[i].originalFile
						formData.append('banner_' + i, file);
						have = true;
					}
				}
				if (have) {
					window.api.post('cr_files_upload/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
						.then(() => {
							console.log('good')
						})
						.catch(settingsError => {
							console.log(settingsError)
						})
				}
			}
		}
	}
</script>