<template lang="pug">
	div.card-box
		.card.base-list(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="Install Base", slug="InstallBase", v-model="cardStatus")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.install_base.length)}")
				p Identify your ideal customers based on technographics (installed software).

			.file-list(v-if="GetSettings.install_base.length")
				.single(v-for="(item, index) in GetSettings.install_base", v-bind:class="{disabled: item.state == 'pause'}")
					.action-play-pause(
						v-on:click="changeBaseStatus(index)"
						v-bind:class="{played: item.state == 'running'}"
					)
						.play
							svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
								rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
								path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
						.pause
							svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
								rect(width="20" height="20" rx="3").f-f
								rect(x="7" y="5" width="2" height="10" rx="1").f-t
								rect(x="11" y="5" width="2" height="10" rx="1").f-t

					.info
						p.name {{ item.name }}
						p.stat
							b {{ Number(item.leads_installbase).toFixed(0) }}
							| Leads

						.remove-row(v-on:click="removeRow(index)", v-if="item.new || Number(item.leads_installbase).toFixed(0) == 0")
							
			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Identify your ideal customers based on technographics (installed software).


			button.btn.add-new-btn(type="button", v-on:click="addBase()") ADD A NEW OPTION

		.popup.add-lcp(v-if="popupAddBase")
			.close(v-on:click="showHidePopups()")
			.inside-form
				label Base Description
				input(type="text", placeholder="Text here...", v-model="baseDescription", v-bind:class="{error: baseDescriptionError}", autocomplete="xz").inp

				button.btn.simple(type="button", v-on:click="saveBase()") APPLY


</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'InstallBase',
		components:{
			CardHeader,
			DropList
		},
		data() {
			return {
				cardStatus: true,
				baseList:[
					{
						name: 'CA Site Minder',
						status: 'play',
						leads: 148
					},
					{
						name: 'Oracle Access Manager',
						status: 'paused',
						leads: 214
					}
				],
				popupAddBase: false,
				baseDescription: '',
				baseDescriptionError: false
			}
		},
		created(){
			this.setLeads();
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "InstallBase") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		watch: {
			'GetSettings.total_main': function () {
				this.generateLeads();
				// this.setMaxInt();
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			generateLeads(){
				if(this.cardStatus){
					for(var item in this.GetSettings.install_base){
						if(this.GetSettings.install_base[item].state == 'running'){
							this.GetSettings.install_base[item].leads_installbase = isNaN(Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.install_base[item].percent / 100)).toFixed(0)) ? 0 : parseInt(Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.install_base[item].percent / 100)).toFixed(0))
						}else{
							this.GetSettings.install_base[item].percent = this.GetSettings.install_base[item].leads_installbase / this.GetSettings.total_main.leads_generated * 100;
						}
					}
				}else{
					for(var single in this.GetSettings.install_base){
						this.GetSettings.install_base[single].percent = this.GetSettings.install_base[single].leads_installbase / this.GetSettings.total_main.leads_generated * 100;
					}
				}
				this.updateSettings(['install_base', this.GetSettings.install_base]);
			},
			setLeads(){
				for(var single in this.GetSettings.install_base){
					this.GetSettings.install_base[single].leads_installbase = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.install_base[single].percent / 100)).toFixed(0);
				}
				this.updateSettings(['install_base', this.GetSettings.install_base]);
			},
			changeBaseStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.install_base.[index].state == 'running'){
						this.GetSettings.install_base.[index].state = 'pause'
					}else{
						this.GetSettings.install_base.[index].state = 'running'
					}
				}
			},
			showHidePopups(){
				this.baseDescription = '';
				this.popupAddBase = false;
				this.$emit('input', false);
			},
			addBase(){
				if (this.cardStatus) {
					this.popupAddBase = !this.popupAddBase;
					this.$emit('input', this.popupAddBase);
				}
			},
			saveBase(){
				this.baseDescriptionError = false;
				var key =  Object.keys(this.GetSettings.install_base).length;
				var pattern = {
					leads_installbase: 0,
					name: this.baseDescription,
					state: "pause",
					new: true
				}
				if (this.baseDescription.length < 2) {
					this.baseDescriptionError = true;
					setTimeout(() => {
						this.baseDescriptionError = false;
					}, 2000)
				}
				if (!this.baseDescriptionError) {
					this.$set(this.GetSettings.install_base, key, pattern);
					this.updateSettings(['install_base', this.GetSettings.install_base]);
					this.showHidePopups();
				}
			},
			removeRow(index){
				this.GetSettings.install_base.splice(index, 1);
				this.updateSettings(['install_base', this.GetSettings.install_base]);
			}
		}
	}
</script>