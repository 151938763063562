<template lang="pug">
	.ui
		.grid
			.col
				h5 normal

			.col
				h5 not active

		.grid
			.col
				button.btn.play-pause(type="button") Start
			.col
				button.btn.play-pause.disable(type="button") Start

		.grid
			.col
				button.btn.play-pause.paused(type="button") Pause

		.grid
			.col
				button.btn.engine(type="button") Engine

		.grid
			.col
				button.btn.edit-goal(type="button") edit goals

		.grid
			.col
				button.btn.arrow(type="button")

		.grid
			.col
				.user-manager
					.photo
						img(src="../../assets/user.png", alt="")

					.desc
						.by
							p Managed by

						.name
							p Mia Dragojlovic

		.grid
			.col
				span.link-verified Verified

		.grid
			.col
				span.lead-goal Lead Goal

		.grid
			.col
				span.tactics-activated Tactics Activated

		.grid
			.col
				.leads-percent
					.single.top
						.candle
						.info
							p.value Top
							.b-line
							p.percent 100%

					.single.middle
						.candle
						.info
							p.value Middle
							.b-line
							p.percent 65%

					.single.bottom
						.candle
						.info
							p.value Bottom
							.b-line
							p.percent 78%

		.grid
			.col
				.leads-stat
					.til
						p Remaining Leads / Lead Goal

					.from-to
						p 551 
						p.blue.slash / 
						p.blue 700

</template>

<script>
	export default {
		name: 'Ui'
	}
</script>