<template lang="pug">
	div.footer
		.infuse-logo
			a(href="https://infusemedia.com", target="_blank").logo
				img(src="@/assets/infuse-logo.svg", alt="")

		a(href="mailto:accelerator@infusemedia.com").mailto accelerator@infusemedia.com

		p.copy © 2023 INFUSEmedia All Rights Reserved

</template>

<script>
	export default {
		name: 'Footer'
	}
</script>