<template lang="pug">
	.add-campaign
		.top-section
			.client-name
				h3
					span Client: 
					| Zinancial

			UserBlock

		.main-form
			scrolly.scroll.foo
				scrolly-viewport
					.row-inp
						.single-3
							label Customer Information*
							input(type="text").inp

						.single-3
							label Contact Name*
							input(type="text").inp

						.single-3
							label Email*
							input(type="text").inp

					.row-inp
						.single
							label Campaign Name*
							input(type="text").inp

					.row-inp
						.single-2
							label Campaign Start Date*
							input(type="text").inp

						.single-2
							label Campaign End Date*
							input(type="text").inp

					.row-inp
						.single-2
							label Campaign Type*
							input(type="text").inp
							
						.single-2
							label Purchase Order*
							input(type="text").inp

					.row-inp
						.single-2
							label Campaign Guarantees*
							input(type="text").inp
							
						.single-2
							label Campaign Details*
							input(type="text").inp

					.row-inp
						.single
							label Notes:
							textarea.area-field

					.actions
						button.btn.submit(type="button") SUBMIT
						button.btn.cancel(type="button") Cancel
				.scroll-bar
				scrolly-bar(axis="y")
</template>

<script>
	import UserBlock from '@/components/User-Action'
	import { Scrolly, ScrollyViewport, ScrollyBar } from 'vue-scrolly'
	export default {
		name: 'Add-Campaign',
		components: {
			UserBlock,
			Scrolly,
			ScrollyViewport,
			ScrollyBar
		},
		data(){
			return {
				activeCampaign: this.$parent.activeCampaign
			}
		},
		created: function(){
			// if (!this.activeCampaign) {
			// 	this.$router.push('/');
			// }
		},
		methods:{
			onCampaignSelected (data) {
				this.activeCampaign = data.activeCampaign
			}
		}
	}
</script>