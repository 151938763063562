export default{
	setToken(token){
		token.Token.refresh = localStorage.getItem("refresh");
		token.Token.access = localStorage.getItem("access");
	},
	Login(state){
		state.Auth.email = localStorage.getItem("email");
		state.Auth.first_name = localStorage.getItem("first_name");
		state.Auth.groups = localStorage.getItem("groups");
		state.Auth.id = localStorage.getItem("id");
		state.Auth.last_name = localStorage.getItem("last_name");
		state.Auth.username = localStorage.getItem("username");
		state.Auth.login = state.Auth.fname !== null && state.Auth.last_name !== null && state.Auth.id !== null;
	},
	setCampaign(data){
		data.AddCampaign.clientID = localStorage.getItem('clientID');
		data.AddCampaign.clientName = localStorage.getItem('clientName');
		data.AddCampaign.status = localStorage.getItem('status');
	},
	setDealDesk(data){
		data.AddDealDesk.status = localStorage.getItem('status');
	},
	// selectCampaign(data){
	// 	data.SelectedCampaign.id = localStorage.getItem('selectCampaignID');
	// 	data.SelectedCampaign.name = localStorage.getItem('selectCampaignName');
	// 	data.SelectedCampaign.kind = localStorage.getItem('selectCampaignKind');
	// 	data.SelectedCampaign.campaignClient = localStorage.getItem('campaignClient');
	// }
}