<template lang="pug">
	.dropbox(v-bind:class="{opened: open}", v-click-outside="handleFocusOut")
		.visible(v-on:click="open = !open")
			p.biger {{ active }}
		.box-list
			vuescroll(:ops="ops")
				.inside
					span(v-for="item in list", v-on:click="select(item)") {{ item.name }}
</template>

<script>
	import vuescroll from 'vuescroll'
	export default {
		props: {
			list: [Object, Array],
			default: String
		},
		components: {
			vuescroll
		},
		data() {
			return {
				active: this.default,
				open: false,
				ops:{
					bar:{
						background: '#FC9000',
						onlyShowBarOnScroll: false,
						keepShow: true,
						size: '2px'
					},
					rail:{
						background: '#E6E6E6',
						opacity: 1,
						size: '2px'
					}
				}
			}
		},
		watch: {
			default:{
				handler: function() {
					this.active = this.default
				},
				deep: true
			}
		},
		methods: {
			select(data){
				this.open = false;
				this.active = data.name;
				this.$emit('input', data);
			},
			handleFocusOut() {
				this.open = false;
			}
		}
	}
</script>