<template lang="pug">
	.drop-list(v-bind:class="{opened: open}", v-click-outside="handleFocusOut")
		.visible(v-on:click="open = !open")
			p.biger {{ active }}
		.list
			span(v-for="item in list", v-on:click="selectCampaign(item)") {{ item.name }}
</template>

<script>
	import { mapMutations } from 'vuex'

	export default {
		props: {
			list: [Object, Array],
			default: String,
			type: String
		},
		data() {
			return {
				active: this.default,
				open: false
			}
		},
		methods: {
			...mapMutations(['updateSettings']),
			selectCampaign(data){
				localStorage.setItem('campaign_id', data.id);
				this.$router.go(this.$router.currentRoute)
				// this.updateSettings(['changeCampaign', true]);
			},
			handleFocusOut() {
				this.open = false;
			}
		}
	}
</script>