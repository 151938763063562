export default{
	init(){
		window.store.commit("setDealDesk");
	},
	setData(data){
		localStorage.setItem('status', data.status);
		this.init();
	},
	deleteData(){
		localStorage.removeItem('status');
		this.init();
	}
}