<template lang="pug">
	.dropbox(v-bind:class="{opened: open}", v-click-outside="handleFocusOut")
		.visible(v-on:click="open = !open")
			p {{ active }}
		.box-list
			span(v-for="item in list", v-on:click="select(item)") {{ item.name }}
</template>

<script>
	export default {
		props: {
			list: [Object, Array],
			default: String
		},
		data() {
			return {
				active: this.default,
				open: false
			}
		},
		watch: {
			default:{
				handler: function() {
					this.active = this.default
				},
				deep: true
			}
		},
		methods: {
			select(data){
				this.open = false;
				this.active = data.name;
				this.$emit('input', data);
			},
			handleFocusOut() {
				this.open = false;
			}
		}
	}
</script>