<template lang="pug">
	.engine-page
		.main
			.close(v-on:click="closeTab()")
				.ico
			iframe(src="https://hourglass.infusemedia.com/engine_frame")

</template>

<script>
	import UserBlock from '@/components/User-Action'

	export default {
		name: 'Assets',
		components:{
			UserBlock,
		},
		data() {
			return {
			}
		},
		created(){
		},
		mounted(){
		},
		methods: {
			closeTab(){
				window.close();
			}
		}
	}
</script>