<template lang="pug">
	div.card-box
		.card.tactics-activated-card
			CardHeader(title="Tactics Activated", type="only-title")

			.in-row
				.left-side
					.list
						.item(v-for="item in GetSettings.tactics", v-bind:class="{ active: item.active}")
							.ico
								svg(width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg")
									path(d="M0.011204 1.19187L3.57767 5.99775L0 11.0154L1.62844 12.1765L6.05077 5.97423L1.61727 0L0.011204 1.19187Z")
									path(d="M8.07767 5.99775L4.5112 1.19187L6.11727 0L10.5508 5.97423L6.12844 12.1765L4.5 11.0154L8.07767 5.99775Z")
									path(d="M12.5777 5.99775L9.0112 1.19187L10.6173 0L15.0508 5.97423L10.6284 12.1765L9 11.0154L12.5777 5.99775Z")
							p {{ item.name }}
						
						router-link(to="./engine", target="_blank").btn.engine.orange Engine

				.right-side
					h3 Content marketing 
						span Engine

					.engine
						.abm
							h5 ABM
							.line
							p Account 
								br
								| Based 
								br
								| Marketing

						.ai
							h5 AI
							.line
							p Artificial
								br
								| Intelligence

						img(src="@/assets/engine-img.png", alt="")

</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";

	import { mapGetters } from 'vuex'

	export default {
		name: 'TacticsActivated',
		components:{
			CardHeader,
			DropList
		},
		computed: mapGetters(['GetSettings'])
	}
</script>