<template lang="pug">
	div.card-box
		.card.intent-feed(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="Intent Data Signal Streams", slug="IntentFeed", v-model="cardStatus", file="https://infusemedia.com/emea/what-we-do/intent-driven-marketing/")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.intents.length)}")
				//- p Empower your campaign with three independent intent data signals.
				a(href="http://intent.infusemedia.com", target="_blank").rocket-link Empower your campaign with three independent intent data signals.

			div(v-if="GetSettings.intents.length || newTopic && cardStatus")
				.intent-top
					.goal(v-on:click="showPopupGoal()")
						span Goal
						p {{ GetSettings.intent_feed_goal_percent}}%
							b.orange ({{ Number(GetSettings.intent_feed_goal_percent / 100 * GetSettings.total_main.leads_goal).toFixed(0) }})

					.done
						span Done
						p(v-if="!isNaN(GetSettings.intent_feed_done_percent)") {{ Number(GetSettings.intent_feed_done_percent).toFixed(0) }}%
							b.green ({{ Number(Number(GetSettings.intent_feed_goal_percent / 100 * GetSettings.total_main.leads_goal).toFixed(0) * (GetSettings.intent_feed_done_percent / 100)).toFixed(0) }})

						p(v-else) 0%
							b.green (0)

					.goal-requested(v-if="GetSettings.goal_intent_requested")
						span Goal Requested
						p {{ GetSettings.goal_intent_requested }}

					a(href="http://intent.infusemedia.com", target="_blank").congratulations(v-else)
						i
							b Congratulations! 
							| Your targeting is optimised with the constant Intent Feed!

				.table-switch
					.tab.infuse(v-bind:class='{active: activeTab == "INFUSEmedia"}', v-on:click="switchTab('INFUSEmedia')")
						img(src="@/assets/s-infuse-logo.svg", alt="", width="102px")
						p Intent Engine

					.tab.bombora(v-bind:class='{active: activeTab == "Bombora"}', v-on:click="switchTab('Bombora')")
						img(src="@/assets/s-bombora-logo.svg", alt="", width="115px")

					.tab.aberdeen(v-bind:class='{active: activeTab == "Aberdeen"}', v-on:click="switchTab('Aberdeen')")
						img(src="@/assets/s-aberdeen-logo.svg", alt="", width="110px")

				.intent-table
					.t-head
						.cl
							p Intent Topics

						.cl
							p Companies with this Intent

						.cl
							p Generated Leads

					.t-row(v-for="(item, index) in GetSettings.intents", v-bind:class="{'active': item.state == 'running'}", v-if="item.kind == activeTab")
						.cl
							.action-play-pause(
								v-if="item.state",
								v-bind:class="{played: item.state == 'running'}"
								v-on:click="changeStatus(index)"
							)
								.play
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
										path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
								.pause
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(width="20" height="20" rx="3").f-f
										rect(x="7" y="5" width="2" height="10" rx="1").f-t
										rect(x="11" y="5" width="2" height="10" rx="1").f-t

							p {{ item.name }}

						.cl
							.in-row
								p.sm {{ item.companies_count }}

								DropListSearch(
									:list="item.company",
									:default="item.company[0].name",
									v-model="showPopupLoad"
									type="tooltip",
									v-if="item.company.length > 0"
								)

						.cl
							.in-row
								p.bg {{ Number(item.leads_generated).toFixed(0) }}

								.sm-progress
									.progress
										.fill(
											:style='{width: item.percent + "%"}'
										)

									p(v-if="Number(item.leads_generated).toFixed(0) == 0") 0%
									p(v-else) {{ Number(item.percent).toFixed(0) }}%

						.cl
							.remove-row(v-on:click="removeRow(index)", v-if="!item.percent")

					.t-row.add-topic-row(v-if="newTopic && cardStatus")
						.cl(v-click-outside="handleFocusOutList")
							input(type="text", placeholder="Text here...", v-model="newTopicVal", v-on:input="searchTopic()", v-on:click="showList = true", v-bind:class="{error: newTopicError}", autocomplete="xz").inp
							.search-result(v-if="showList")
								.inside(@scroll="onScroll")
									span(v-for="item in filterBy(topicsList, searchVal, 'topic')", v-on:click="selectTopic(item.topic)") {{ item.topic }}

						.cl
							input(type="button", value="Add", v-on:click="addNewTopic()").s-btn

					.t-row.sum
						.cl
							p TOTAL

						.cl
							p {{ totalLeads }}

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				a(href="http://intent.infusemedia.com", target="_blank").rocket-link Empower your campaign with three independent intent data signals.
				
			button.btn.add-new-btn(type="button", v-on:click="addTopic()") ADD more topics

		.popup.loader(v-if="popupLoad")
			.close(v-on:click="showHidePopups()")
			.load-block
				p Loading, please wait
				.spiner

				button.btn.simple(type="button", v-on:click="showHidePopups()") CANCEL

		.popup.change-goal(v-if="popupAddGoal")
			.close(v-on:click="showHidePopups()")
			.inside-form
				label Request your goal
				input(type="text", placeholder="Text here...", v-model="newGoal", v-bind:class="{error: newGoalError}", autocomplete="xz").inp

				button.btn.simple(type="button", v-on:click="saveGoal()") Apply

		.popup.popup-requested(v-if="popupRequested")
			.close(v-on:click="showHidePopups()")
			.inside-form
				p Your request is submitted. 
					br
					| Our Client Success Manager 
					br
					| will update the campaign shortly.

				button.btn.simple(type="button", v-on:click="showHidePopups()") OK

</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropListSearch from "../components/drop-list-search.vue";

	import { mapMutations, mapGetters } from 'vuex'
	import Vue2Filters from 'vue2-filters'

	export default {
		name: 'Intent',
		components:{
			CardHeader,
			DropListSearch
		},
		data() {
			return {
				cardStatus: false,
				defaultTitle: 'List',
				newTopicsList: [],
				totalLeads: 0,
				totalAll: 0,
				newTopic: false,
				newTopicVal: '',
				newTopicError: false,
				activeTab: 'INFUSEmedia',
				showPopupLoad: false,
				popupLoad: false,
				goalNumber: 0,
				prevNumber: 0,
				leadGoalError: false,
				showList: false,
				topicsList: [],
				nextTopick: false,
				searchVal: '',
				searchUrl: '',
				lastPage: false,
				allowScroll: true,
				load: false,
				popupAddGoal: false,
				newGoalError: false,
				newGoal: '',
				popupRequested: false
			}
		},
		mixins: [Vue2Filters.mixin],
		computed: { ...mapGetters(['GetSettings']) },
		created(){
			this.goalNumber = Number(this.GetSettings.intent_feed_goal_percent / 100 * this.GetSettings.total_main.leads_goal).toFixed(0);
			this.prevNumber = Number(this.GetSettings.intent_feed_goal_percent / 100 * this.GetSettings.total_main.leads_goal).toFixed(0);
			this.setLeads();
			this.sortFunc(this.GetSettings.intents);
			this.updateSettings(['intents', this.GetSettings.intents]);
			this.calcTotal();
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "IntentFeed") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		watch: {
			'GetSettings.total_main': function () {
				this.generateLeads();
				// this.setMaxInt();
			},
			showPopupLoad: function(){
				this.showPopup();
			}
		},
		methods: {
			...mapMutations(['updateSettings']),
			generateLeads(){
				if(this.cardStatus){
					for(var item in this.GetSettings.intents){
						if(this.GetSettings.intents[item].state == 'running'){
							this.GetSettings.intents[item].leads_generated = Number((this.GetSettings.intents[item].percent / 100) * (this.GetSettings.intent_feed_goal_percent / 100) * this.GetSettings.total_main.leads_generated).toFixed(0);
						}else{
							if (this.GetSettings.intents[item].leads_generated > 0) {
								var percent = this.GetSettings.intents[item].leads_generated / this.totalAll * 100;
								if(isNaN(percent)){
									percent = 0;
								}
								this.GetSettings.intents[item].percent = percent;
							}
						}
					}
				}else{
					for(var single in this.GetSettings.intents){
						if (this.GetSettings.intents[single].leads_generated > 0) {
							var percentD = this.GetSettings.intents[single].leads_generated / this.totalAll * 100;
							if(isNaN(percentD)){
								percentD = 0;
							}
							this.GetSettings.intents[single].percent = percentD;
						}
					}
				}
				
				//
				this.sortFunc(this.GetSettings.intents);
				this.calcTotal();
			},
			getTopics(){
				var next = '';
				var param = '';
				var and = '?'
				if (this.nextTopick) {
					next = '?page='+this.nextTopick;
					and = '&'
				}
				if (this.searchUrl) {
					param = and + this.searchUrl;
				}
				window.api.get('references/topics' + next + param)
					.then(topics => {
						if(this.topicsList.length){
							this.topicsList = this.topicsList.concat(topics.data.results)
						}else{
							this.topicsList = topics.data.results
						}
						this.allowScroll = true;
						this.load = false;
						if (topics.data.next) {
							if(!this.nextTopick){
								this.nextTopick = 2;
							}else{
								this.nextTopick = +1;
							}
						}else{
							this.nextTopick = null;
						}
					})
					.catch(topicsError => {
						console.log(topicsError);
					})
			},
			setLeads(){
				for(var single in this.GetSettings.intents){
					this.GetSettings.intents[single].leads_generated = Number((this.GetSettings.intents[single].percent / 100) * (this.GetSettings.intent_feed_goal_percent / 100) * this.GetSettings.total_main.leads_generated).toFixed(0);
				}
				this.updateSettings(['intents', this.GetSettings.intents]);
				this.calcTotal();
			},
			sortFunc: function (obj){
				return obj.sort((a, b) => b.percent - a.percent );
			},
			changeStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.intents.[index].state == 'running'){
						this.GetSettings.intents.[index].state = 'pause'
					}else{
						this.GetSettings.intents.[index].state = 'running'
					}
				}
			},
			calcTotal(){
				this.totalLeads = 0;
				this.totalAll = 0;
				for (var item in this.GetSettings.intents) {
					this.totalAll += parseInt(this.GetSettings.intents[item].leads_generated);
					if (this.GetSettings.intents[item].kind == this.activeTab) {
						this.totalLeads += parseInt(this.GetSettings.intents[item].leads_generated);
					}
				}

				this.GetSettings.intent_feed_done_percent = this.totalAll / Number(this.GetSettings.intent_feed_goal_percent / 100 * this.GetSettings.total_main.leads_goal).toFixed(0) * 100;
			},
			addTopic(){
				if (this.cardStatus) {
					this.newTopic = !this.newTopic;
				}
			},
			addNewTopic(){
				if (this.newTopicVal.length < 2 || this.newTopciVal > 200) {
					this.newTopicError = true;
					setTimeout(() => {
						this.newTopicError = false;
					}, 2000);
				}else{
					var key = Object.keys(this.GetSettings.intents).length;
					var topic = {
						campaign: localStorage.getItem('campaign_id'),
						companies_count: 0,
						company: [],
						goal_intent_feed: 0,
						kind: this.activeTab,
						leads_generated: 0,
						name: this.newTopicVal,
						percent: 0,
						state: 'pause'
					}
					this.$set(this.GetSettings.intents, key, topic);
					this.updateSettings(['intents', this.GetSettings.intents]);
					this.newTopicVal = '';
					this.newTopic = false;
				}
			},
			switchTab(tab){
				if (this.cardStatus) {
					this.activeTab = tab;
					this.calcTotal();
				}
			},
			showHidePopups(){
				this.showPopupLoad = false;
				this.popupLoad = false;
				this.popupAddGoal = false;
				this.newGoalError = false;
				this.newGoal = '';
				this.popupRequested = false;
				this.$emit('input', false);
			},
			showPopup(){
				if (this.showPopupLoad) {
					this.popupLoad = !this.popupLoad;
					this.$emit('input', this.popupLoad);
				}
			},
			onlyNumber ($event) {
				let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
					$event.preventDefault();
				}
			},
			checkGoal(){
				if (this.prevNumber < this.totalAll || this.prevNumber > this.GetSettings.total_main.leads_goal) {
					this.leadGoalError = true;
				}else{
					this.leadGoalError = false;
				}
			},
			// handleFocusOut() {
			// 	if (this.prevNumber < this.totalAll || this.prevNumber > this.GetSettings.total_main.leads_goal) {
			// 		this.prevNumber = this.goalNumber
			// 	}else{
			// 		this.goalNumber = this.prevNumber
			// 		this.GetSettings.intent_feed_goal_percent = Number(this.prevNumber / this.GetSettings.total_main.leads_goal * 100).toFixed(0);
			// 		this.GetSettings.intent_feed_done_percent = Number(this.doneNumber / this.prevNumber * 100).toFixed(0)
			// 		this.updateSettings(['intent_feed_goal_percent', this.GetSettings.intent_feed_goal_percent]);
			// 		this.updateSettings(['intent_feed_done_percent', this.GetSettings.intent_feed_done_percent]);
			// 	}
			// 	this.leadGoalError = false;
			// },
			handleFocusOutList() {
				this.showList = false;
				this.searchVal = '';
			},
			selectTopic(topic){
				this.newTopicVal = topic;
				this.showList = false;
			},
			searchTopic(){
				this.searchVal = this.newTopicVal;
				var filterRes = this.filterBy(this.topicsList, this.searchVal, 'topic');
				if (filterRes.length < 20) {
					this.nextTopick = null;
				}
				if (this.searchVal.length > 3) {
					this.searchUrl = 'topic='+this.searchVal;
					this.topicsList = [];
				}else{
					this.searchUrl = '';
				}
				if (this.searchVal.length < 1) {
					this.topicsList = [];
				}
				setTimeout(() => {
					this.getTopics();
				}, 250);
			},
			onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
				if (scrollTop + clientHeight + 40 >= scrollHeight && this.allowScroll) {
					this.allowScroll = false;
					if (!this.lastPage) {
						this.load = true;
					}
					setTimeout(() => {
						this.getTopics();
					}, 500);
				}
			},
			removeRow(index){
				this.GetSettings.intents.splice(index, 1);
				this.updateSettings(['intents', this.GetSettings.intents]);
			},
			showPopupGoal(){
				if (this.cardStatus) {
					this.popupAddGoal = !this.popupAddGoal;
					this.$emit('input', this.popupAddGoal);
				}
			},
			saveGoal(){
				this.newGoalError = false;
				if (this.newGoal.length < 1) {
					this.newGoalError = true;
					setTimeout(() => {
						this.newGoalError = false;
					}, 2000)
				}else{
					this.newGoal = this.newGoal + ' ';
					this.newGoal = this.newGoal.substr(0,this.newGoal.indexOf(' '));

					if (this.newGoal.slice(-1) != '%') {
						this.newGoal = this.newGoal + '%';
					}
					this.updateSettings(['goal_intent_requested', this.newGoal]);
					this.popupAddGoal = false;
					this.popupRequested = true;
				}
			}
		}
	}
</script>