<template lang="pug">
	.lead-goal-popup
		.close(v-on:click="hide()")
		h4 Lead Goal

		.goals-list
			.single
				p Single Touch:
				h5.green 750 
					span Leads

			.single
				p Double Touch:
				h5.red 230 
					span Leads

			.single
				p Pacing:
				h5.yellow {{ $store.state.SelectedCampaign.pacing }}

		button.btn.edit-goal(type="button") edit goals
		

</template>

<script>
	export default {
		name: 'LeadGoal',
		data(){
			return{

			}
		},
		methods: {
			hide(){
				this.$emit('input', false);
			}
		}
	}
</script>