<template lang="pug">
	.add-dealdesk(v-bind:class="{'more-options': advancedOptions}")
		.main-form
			.ty(v-bind:class="{'show-ty': success}")
				.go-back(v-on:click="goBack()") Restore
				h3 THANK YOU!
				h5 You DealDesk Request 
					br
					| has been submitted!
			scrolly.scroll.foo(
				passiveScroll=true
			)
				scrolly-viewport
					.row-inp
						.single-3
							label Client
							input(
								type="text",
								v-model="campaignForm.client",
							).inp

						.single-3
							label Campaign Name
							input(
								type="text",
								v-model="campaignForm.campaign_name",
							).inp

						.single-3
							label Required Lead Volume
							input(
								type="text",
								v-model="campaignForm.required_lead_volume",
							).inp

					.row-inp
						.single-3
							label Lead Type*
							DropBoxInp(
								:list="leadTypeList",
								:clear="clearData",
								v-model="leadType",
								v-bind:class="{error: errorForm.lead_type}",
								search=false
							).disable-enter

						.single-3
							label Campaign Duration
							input(
								type="text",
								v-model="campaignForm.campaign_duration",
							).inp

						.single-3
							label Lead Cap
							input(
								type="text",
								v-model="campaignForm.lead_cap",
							).inp

					.row-inp
						.single-3
							label Seniority (Job level)*
							DropBoxInp(
								:list="seniorityList",
								:clear="clearData",
								v-model="seniority",
								v-bind:class="{error: errorForm.seniority}",
								search=false
							)

						.single-3
							label Job Titles
							DropBoxInp(
								:list="titlesList",
								:clear="clearData",
								v-model="titles",
								search=true
							)

						.single-3
							label Install Base
							input(
								type="text",
								v-model="campaignForm.install_base",
							).inp

					.row-inp
						.single-3
							label Job Area / Job Functions*
							input(
								type="text",
								v-model="campaignForm.job_area",
								v-bind:class="{error: errorForm.job_area}"
							).inp

						.single-3
							label Company Revenue
							DropBoxInp(
								:list="revenueList",
								:clear="clearData",
								v-model="revenue",
								search=false
							)

						.single-3
							label Company Size (Number of Employees)
							DropBoxInp(
								:list="companySizeList",
								:clear="clearData",
								v-model="companySize",
								search=false
							)

					.row-inp
						.single-3
							label Industries*
							DropBoxInp(
								:list="industriesList",
								:clear="clearData",
								v-model="industries",
								v-bind:class="{error: errorForm.industries}",
								search=false
							)

						.single-3
							label Custom Questions
							input(
								type="text",
								v-model="campaignForm.custom_questions"
							).inp

						.single-3
							label It this a renewal?
							DropBox(
								:list="yesNo",
								default="No",
								v-model="campaignForm.is_renewal"
							)

					.row-inp
						.single-3
							label Geolocation*
							treeselect(
								v-model="value",
								:multiple="true",
								placeholder="",
								:options="geolocationList",
								:normalizer="normalizer",
								:searchable="false",
								value-consists-of="ALL"
							).tree-box
							//- DropBoxInp(
							//- 	:list="geolocationList",
							//- 	:clear="clearData",
							//- 	v-model="geolocation",
							//- 	v-bind:class="{error: errorForm.geolocation}"
							//- )
							//- DropBoxThree(
							//- 	:list="geolocationList",
							//- 	:clear="clearData",
							//- 	v-model="geolocation",
							//- 	v-bind:class="{error: errorForm.geolocation}",
							//- 	search=false,
							//- 	title="Enter or select"
							//- )

						.single-3
							label Budget / CPL
							input(
								type="text",
								v-model="campaignForm.budget"
							).inp

						.single-3
							label Suppression List(s)
							DropBox(
								:list="yesNo",
								default="No",
								v-model="campaignForm.suppresion_list"
							)

					.row-inp
						.single-3
							label ABM (Account Based Marketing)*
							DropBox(
								:list="yesNo",
								default="No",
								v-model="campaignForm.abm"
							)

						.single-full
							label Notes:
							input(
								type="text",
								v-model="campaignForm.notes"
							).inp

					.row-inp
						.single-3
							.actions.wrap
								button.btn.blue(type="button", v-on:click="moreOptions()") Advanced Options
								button.btn.submit(type="button", v-on:click="saveCampaign('standart')", v-bind:class="{disable: disableBTN}") SUBMIT
								button.btn.border(type="button", v-on:click="saveCampaign('draft')", v-bind:class="{disable: disableBTN}") SAVE AS A DRAFT
								button.btn.cancel(type="button", v-on:click="goBack()") Cancel

						.single-full
							.file-ui
								.upload-file(v-bind:class="{'block-click': listFile.length === 5}")
									input(
										type="file",
										v-on:change="onChange",
										multiple,
										ref="file").file-picker

									span Upload Attachments

									span.files-limit (max 5 files)

								//- .file-list(v-if="listFile.length > 0")
								.file-row-list
									.single-file(v-for="(file, index) in listFile")
										span(:title="file.name") {{ file.name }}
										.remove(v-on:click="removeFile(index)")


							//- .drop-zone(
							//- 	@drop="drop",
							//- 	@dragover="dragover",
							//- 	@dragleave="dragleave"
							//- )
							//- 	input(
							//- 		type="file",
							//- 		v-on:change="onChange",
							//- 		ref="file").file-picker
							//- 	div(v-if="listFile.length > 0")
							//- 		p {{ listFile[0].name }}
							//- 	div(v-else)
							//- 		p BROWSE FILE
							//- 		span or
							//- 			br
							//- 			| Drag and Drop file here

				.scroll-bar
				scrolly-bar(axis="y")
</template>

<script>
	import UserBlock from '@/components/User-Action'
	import { Scrolly, ScrollyViewport, ScrollyBar } from 'vue-scrolly'
	// import { mapMutations } from 'vuex'
	import DropBoxInpSearch from "./components/drop-box-inp-search.vue";
	import DropBoxInp from "./components/drop-box-inp.vue";
	import DropBox from "./components/drop-box.vue";
	// import DropBoxThree from "./components/drop-box-three.vue";

	import Treeselect from '@riophae/vue-treeselect'
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'

	export default {
		name: 'Add-DealDesk',
		components: {
			UserBlock,
			Scrolly,
			ScrollyViewport,
			ScrollyBar,
			DropBoxInpSearch,
			DropBoxInp,
			DropBox,
			// DropBoxThree,
			Treeselect
		},
		data(){
			return {
				normalizer(node) {
					return {
						id: node.id,
						label: node.name
					}
				},
				success: false,
				errorForm:{
					lead_type: false,
					seniority: false,
					job_area: false,
					industries: false,
					geolocation: false
				},
				error: true,
				formOrder: '',
				campaignForm:{
					client: '',
					campaign_name: '',
					budget: '',
					required_lead_volume: '',
					campaign_duration: '',
					job_area: '',
					lead_cap: '',
					install_base: '',
					custom_questions: '',
					notes: '',
					abm: false,
					suppresion_list: false,
					is_renewal: false,
					job_titles: [],
					industries: [],
					geolocation: [],
					company_revenue: [],
					company_size: [],
					lead_type: [],
					seniority: [],
					status: null
				},
				disableBTN: false,
				allCampaigns: null,
				advancedOptions: false,
				listFile: [],
				yesNo: [
					{
						"id":0,
						"name":"Yes"
					},
					{
						"id":1,
						"name":"No"
					}
				],
				userLeadType: ['custom enter'],
				leadTypeList: null,
				leadType: '',
				seniorityList: null,
				seniority: '',
				titlesList: null,
				titles: '',
				industriesList: null,
				industries: '',
				geolocationList: null,
				geolocation: null,
				revenueList: null,
				revenue: '',
				companySizeList: null,
				companySize: '',
				clearData: false,
				value: null
			}
		},
		computed: {
			AddDealDesk() {
				return this.$store.getters.AddDealDesk
			}
		},
		watch: {
			AddDealDesk(){
				this.advancedOptions = false;
			}
		},
		created: function(){
			this.getLeadsType();
			this.getSeniority();
			this.getJobsTitles();
			this.getIndustries();
			this.getGeolocation();
			this.getRevenue();
			this.getCompanySize();
		},
		methods:{
			goBack(){
				this.campaignForm = {
					client: '',
					campaign_name: '',
					budget: '',
					required_lead_volume: '',
					campaign_duration: '',
					job_area: '',
					lead_cap: '',
					install_base: '',
					custom_questions: '',
					notes: '',
					abm: true,
					suppresion_list: true,
					is_renewal: true,
					job_titles: [],
					industries: [],
					geolocation: [],
					company_revenue: [],
					company_size: [],
					lead_type: [],
					seniority: [],
					status: null
				};
				this.listFile = [];
				this.leadType = '';
				this.seniority = '';
				this.titles = '';
				this.industries = '';
				this.geolocation = '';
				this.revenue = '';
				this.companySize = '';
				this.clearData = true;
				this.value = null;
				window.AddDD.deleteData();
				setTimeout(() => {
					this.advancedOptions = false;
					this.success = false;
				})
				setTimeout(() => {
					this.clearData = false;
				}, 500)
			},
			sortGeo: function (obj){
				return obj.sort(function(a, b) {
					var nameA = a.name.toUpperCase();
					var nameB = b.name.toUpperCase();
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					return 0;
				})
			},
			moreOptions(){
				this.advancedOptions = !this.advancedOptions;
			},
			onChange() {
				let fileList = [...this.$refs.file.files];
				for (var i = 0; i <= fileList.length - 1; i++) {
					if(this.listFile.length < 5){
						this.listFile.push(fileList[i])
					}else{
						return false;
					}
				}
				this.$refs.file.value=null
			},
			drop(event) {
				event.preventDefault();
				this.$refs.file.files = event.dataTransfer.files;
				this.onChange();
			},
			dragover() {
				this.fileDropHover = true;
			},
			dragleave(){
				this.fileDropHover = false;
			},
			removeFile(index){
				let fileList = [...this.listFile];
				this.listFile = [];
				for (var i = 0; i <= fileList.length - 1; i++) {
					if(i != index){
						this.listFile.push(fileList[i])
					}
				}
			},
			getLeadsType(){
				window.api.get('references/lead_types/')
					.then(leads => {
						this.leadTypeList = leads.data;
					})
					.catch(leadsError => {
						console.log(leadsError);
					})
			},
			getSeniority(){
				window.api.get('references/seniority/')
					.then(seniority => {
						this.seniorityList = seniority.data;
					})
					.catch(seniorityError => {
						console.log(seniorityError);
					})
			},
			getJobsTitles(){
				window.api.get('references/titles/')
					.then(titles => {
						this.titlesList = titles.data;
					})
					.catch(titlesError => {
						console.log(titlesError);
					})
			},
			getIndustries(){
				window.api.get('references/industry/')
					.then(industry => {
						this.industriesList = industry.data;
					})
					.catch(industriesError => {
						console.log(industriesError);
					})
			},
			// getGeolocation(){
			// 	window.api.get('references/geolocations/')
			// 		.then(geolocation => {
			// 			this.geolocationList = geolocation.data;
			// 		})
			// 		.catch(geolocationError => {
			// 			console.log(geolocationError);
			// 		})
			// },
			getGeolocation(){
				window.api.get('references/geolocations_tree/')
					.then(geolocation => {
						this.geolocationList = geolocation.data;
						this.rebuildList();
					})
					.catch(geolocationError => {
						console.log(geolocationError);
					})
			},
			rebuildList(){
				var geoList = this.sortGeo(this.geolocationList);
				// var geoList = this.geolocationList;

				var childrens = geoList.filter(({ id, parent }) => (id !== null && id !== parent && parent !== null));
				var levelOne = geoList.filter(({ id, parent }) => (parent === null || parent === id));

				var levelTwo = [];
				for(var item in levelOne){
					var parentID = levelOne[item].id;
					var ch = childrens.filter(child => child.parent === parentID);
					if(ch.length){
						levelTwo.push(...childrens.filter(child => child.parent === parentID))
						// if(levelTwo.length){
						//	 Object.assign(levelTwo, ch);
						// }else{
						//	 levelTwo = ch;
						// }
					}
				}

				var levelThree = [];
				for(let item in levelTwo){
					let childParentID = levelTwo[item].id;
					if(childParentID){
						if(geoList.filter(child => child.parent === levelTwo[item].id).length){
							levelThree.push(...geoList.filter(child => child.parent === levelTwo[item].id))
						}
					}
				}

				for(var item3 in levelThree){
					var children3 = levelThree[item3];
					var index = levelTwo.findIndex(parent => parent.id === children3.parent);
					if(index >= 0){
						if(!levelTwo[index].children){
							levelTwo[index].children = [];
						}
						levelTwo[index].children.push(children3)
					}
				}

				for(var item2 in levelTwo){
					var children2 = levelTwo[item2];
					var index2 = levelOne.findIndex(parent => parent.id === children2.parent);
					if(index2 >= 0){
						if(!levelOne[index2].children){
							levelOne[index2].children = [];
						}
						levelOne[index2].children.push(children2)
					}
				}

				this.geolocationList = levelOne;

				// var sortedList = [];

				// child.filter(function(geo) {
				// 	let parent = geo.parent;
				// 	if(parent){
				// 		let newObj = parents.find(({ id }) => id === parent);
				// 		if(newObj){
				// 			if(!newObj.children){
				// 				newObj.children = [];
				// 			}
				// 			newObj.children.push(geo);
				// 			sortedList.push(newObj)
				// 		}
				// 	}
				// });

				// console.log(sortedList)



				// child.filter(function(geo) {
				// 	let parent = geo.parent;
				// 	if(parent){
				// 		var index = sortedList.findIndex(({ children }) => children[0].id === parent);
				// 		if(index >= 0){
				// 			var secondIndex = sortedList[index].children.findIndex(({ id }) => id === parent);
				// 			if(!sortedList[index].children[secondIndex].children){
				// 				sortedList[index].children[secondIndex].children = [];
				// 			}
				// 			sortedList[index].children[secondIndex].children.push(geo)
				// 		}
				// 	}
				// });

				// this.geolocationList = sortedList;
			},
			getRevenue(){
				window.api.get('references/revenue/')
					.then(revenue => {
						this.revenueList = revenue.data;
					})
					.catch(revenueError => {
						console.log(revenueError);
					})
			},
			getCompanySize(){
				window.api.get('references/company_size/')
					.then(size => {
						this.companySizeList = size.data;
					})
					.catch(sizeError => {
						console.log(sizeError);
					})
			},
			saveCampaign(type){
				this.geolocation = this.value;
				if(type == 'draft'){
					this.campaignForm.status = 'draft'
				}else{
					this.campaignForm.status = null
				}
				var userJT = null;
				for(var title in this.titles){
					if (this.titles[title].id) {
						this.campaignForm.job_titles.push(this.titles[title].id);
					}else{
						userJT = this.titles[title].name;
					}
				}
				this.campaignForm.user_job_titles = userJT;

				var userIndustry = null;
				for(var industry in this.industries){
					if (this.industries[industry].id) {
						this.campaignForm.industries.push(this.industries[industry].id);
					}else{
						userIndustry = this.industries[industry].name;
					}
				}
				this.campaignForm.user_industries = userIndustry;

				// var userGeo = null;
				// for(var geoloc in this.geolocation){
				// 	if (this.geolocation[geoloc].id) {
				// 		this.campaignForm.geolocation.push(this.geolocation[geoloc].id);
				// 	}else{
				// 		userGeo = this.geolocation[geoloc].name;
				// 	}
				// }
				// this.campaignForm.user_geolocation = userGeo;

				var userGeo = null;
				for(var geoloc in this.geolocation){
					if (this.geolocation[geoloc]) {
						console.log(this.campaignForm.geolocation)
						this.campaignForm.geolocation.push(this.geolocation[geoloc]);
					}else{
						userGeo = this.geolocation[geoloc].name;
					}
				}
				this.campaignForm.user_geolocation = userGeo;

				var userRevenue = null;
				for(var rev in this.revenue){
					if (this.revenue[rev].id) {
						this.campaignForm.company_revenue.push(this.revenue[rev].id);
					}else{
						userRevenue = this.revenue[rev].name;
					}
				}
				this.campaignForm.user_company_revenue = userRevenue;

				var userCZ = null;
				for(var cz in this.companySize){
					if (this.companySize[cz].id) {
						this.campaignForm.company_size.push(this.companySize[cz].id);
					}else{
						userCZ = this.companySize[cz].name;
					}
				}

				if (this.leadType != null && this.leadType.length) {
					for(var lt in this.leadType){
						if (this.leadType[lt].id === null) {
							this.campaignForm.user_lead_type = this.leadType[lt].name;
						}else{
							this.campaignForm.lead_type.push(this.leadType[lt].id);
						}
					}
					this.errorForm.lead_type = false;
				}else{
					this.errorForm.lead_type = true;
				}

				if (this.seniority != null && this.seniority.length) {
					for(var sn in this.seniority){
						if (this.seniority[sn].id === null) {
							this.campaignForm.user_seniority = this.seniority[sn].name;
						}else{
							this.campaignForm.seniority.push(this.seniority[sn].id);
						}
					}
					this.errorForm.seniority = false;
				}else{
					this.errorForm.seniority = true;
				}

				if(this.campaignForm.job_area.length < 2){
					this.errorForm.job_area = true;
				}else{
					this.errorForm.job_area = false;
				}

				if (this.industries != null && this.industries.length) {
					for(var ind in this.industries){
						if (this.industries[ind].id === null) {
							this.campaignForm.user_industries = this.industries[ind].name;
						}else{
							this.campaignForm.industries.push(this.industries[ind].id);
						}
					}
					this.errorForm.industries = false;
				}else{
					this.errorForm.industries = true;
				}

				if (this.geolocation != null && this.geolocation.length) {
					for(var geo in this.geolocation){
						if (this.geolocation[geo] === null) {
							this.campaignForm.user_geolocation = this.geolocation[geo].name;
						}else{
							this.campaignForm.geolocation.push(this.geolocation[geo]);
						}
					}
					this.errorForm.geolocation = false;
				}else{
					this.errorForm.geolocation = true;
				}

				this.campaignForm.user_company_size = userCZ;

				if (!this.errorForm.lead_type && !this.errorForm.seniority && !this.errorForm.job_area && !this.errorForm.industries && !this.errorForm.geolocation) {
					window.api.post('deal_desk/', this.campaignForm)
					.then(dealDesk => {
						if(this.listFile.length > 0){
							this.uploadFiles(dealDesk.data.id)
						}else{
							this.success = true;
						}
					})
					.catch(newCampaignError => {
						this.errorForm = newCampaignError.response.data;
					})
				}
			},
			uploadFiles(dealDeskID){
				var have = false;
				let formData = new FormData();
				formData.append('deal_desk', dealDeskID)
				for(var i = 0; i <= this.listFile.length - 1; i++){
					let file = this.listFile[i]
					formData.append('file'+i, file);
					have = true;
				}
				if (have) {
					window.api.post('deal_desk/files/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
						.then(() => {
							this.success = true;
						})
						.catch(fileError => {
							console.log(fileError)
						})
				}
			}
		}
	}
</script>