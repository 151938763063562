<template lang="pug">
	.dropbox(v-bind:class="{opened: open}", v-click-outside="handleFocusOutList")
		.visible(v-on:click="open = true")
			input(type="text", v-model="userInput", v-on:keyup="change()", :placeholder="activeShow", autocomplete="xz", v-on:keyup.enter="addToList()", ref="input").inp
		.box-list
			vuescroll(:ops="ops")
				.inside
					.ch-list(v-if="userList.name.length > 0", title="Click to remove", v-on:click="removeCustom()")
						span.checked {{ userList.name }}

					input(type="text", v-if="search", v-model="itemSearch", placeholder="Search...").inp
					
					.ch-list(v-for="item in filterBy(list, itemSearch, 'name')", v-on:click="addNewTitle(item)", v-else)
						span(v-bind:class="{checked: ((selectedItems.filter(single => single.id == item.id).length) > 0)}") {{ item.name }}



							//- div(v-if="item.children[0].children")
							//- 	div(v-for="childtwo in item.children[0].children")
							//- 		span.ml-2 {{ childTwo[0] }}
					
					//- .ch-list(v-for="item in list", v-else, v-on:click="addNewTitle(item)")
					//- 	span(v-bind:class="{checked: ((selectedItems.filter(single => single.id == item.id).length) > 0)}") {{ item.name }}
</template>

<script>
	import vuescroll from 'vuescroll'
	import Vue2Filters from 'vue2-filters'
	export default {
		props: {
			list: [Object, Array],
			default: String,
			search: Boolean,
			clear: Boolean
		},
		mixins: [Vue2Filters.mixin],
		components: {
			vuescroll
		},
		data() {
			return {
				active: '',
				activeInput: {
					id: null,
					name: ''
				},
				userList: {
					id: null,
					name: ''
				},
				activeShow: '',
				open: false,
				ops:{
					bar:{
						background: '#FC9000',
						onlyShowBarOnScroll: false,
						keepShow: true,
						size: '2px'
					},
					rail:{
						background: '#E6E6E6',
						opacity: 1,
						size: '2px'
					}
				},
				itemSearch: '',
				selectedItems: [],
				currentList: [Object, Array],
				userInput: ''
			}
		},
		// created: function(){
		// 	this.currentList = [...this.list];
		// },
		watch: {
			clear(){
				if (this.clear) {
					this.active = '';
					this.activeInput = {
						id: null,
						name: ''
					};
					this.activeShow = '';
					this.itemSearch = '';
					this.selectedItems = [];
					this.currentList = [Object, Array]
				}
			}
		},
		methods: {
			change(){
				this.activeInput = {
					id: null,
					name: this.userInput
				};
				this.mergeData();
			},
			handleFocusOutList() {
				if(this.open){
					this.userInput = '';
					this.open = false;
				}
			},
			addNewTitle(item){
				if (this.selectedItems.filter(single => single.id == item.id).length > 0) {
					this.selectedItems.splice(this.selectedItems.findIndex(single => single.id === item.id),1);
				}else{
					this.selectedItems.push(item);
				}
				this.userInput = '';
				this.mergeData();
			},
			removeTitle(index){
				this.selectedItems.splice(index,1);
				// this.mergeData();
			},
			addToList(){
				this.$refs.input.blur();
				this.handleFocusOutList();
				this.mergeData();
			},
			removeCustom(){
				this.activeInput = {
					id: null,
					name: ''
				};
				this.userList = {
					id: null,
					name: ''
				};
				this.userInput = '';
				this.activeShow = '';
				this.mergeData();
			},
			mergeData(){
				this.active = [];
				this.activeShow = '';
				this.active = [...this.selectedItems];
				this.userList = this.activeInput;
				if(this.activeInput.name.length > 0){
					if(this.selectedItems.length > 0){
						this.activeShow += this.activeInput.name + ', ';
					}else{
						this.activeShow += this.activeInput.name;
					}
					this.active.push(this.activeInput);
				}

				for (var i = 0; i <= this.selectedItems.length - 1; i++) {
					var end = ', ';
					if (i == this.selectedItems.length - 1) {
						end = '';
					}
					this.activeShow += this.selectedItems[i].name + end;
				}
				this.$emit('input', this.active);
			},
			// onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
			// 	if (scrollTop + clientHeight + 40 >= scrollHeight && this.allowScroll) {
			// 		console.log('asd')
			// 	}
			// },
		}
	}
</script>