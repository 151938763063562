<template lang="pug">
	.settings-sidebar
		.logo(v-on:click="changeScroll('Campaign')", my-key="Campaign")
			img(src="@/assets/logo-settings.svg", alt="")

		.mini-hours(v-bind:class="{hide: hideClock}")
			.clock-and-light(v-if="load")
				.effect(ref="light")
				.clock-bg
					svg(width="175" height="191" viewBox="0 0 175 191" fill="none" xmlns="http://www.w3.org/2000/svg")
						rect(x="160" y="144" width="15" height="15" rx="3" fill="#334B49")
						rect(y="144" width="15" height="15" rx="3" fill="#334B49")
						rect(x="16" y="128" width="15" height="15" rx="3" fill="#15524C")
						rect(x="128" y="112" width="15" height="15" rx="3" fill="#15524C")
						rect(x="32" y="112" width="15" height="15" rx="3" fill="#15524C")
						rect(x="112" y="96" width="15" height="15" rx="3" fill="#15524C")
						rect(x="144" y="128" width="15" height="15" rx="3" fill="#15524C")
						rect(x="48" y="96" width="15" height="15" rx="3" fill="#15524C")
						rect(x="96" y="80" width="15" height="15" rx="3" fill="#15524C")
						rect(x="64" y="80" width="15" height="15" rx="3" fill="#15524C")
						rect(x="112" y="64" width="15" height="15" rx="3" fill="#025370")
						rect(x="48" y="64" width="15" height="15" rx="3" fill="#025370")
						rect(x="128" y="48" width="15" height="15" rx="3" fill="#025370")
						rect(x="32" y="48" width="15" height="15" rx="3" fill="#025370")
						rect(x="144" y="32" width="15" height="15" rx="3" fill="#025370")
						rect(x="16" y="32" width="15" height="15" rx="3" fill="#025370")
						rect(x="0" y="16" width="15" height="15" rx="3" fill="#024265")
						rect(x="160" y="16" width="15" height="15" rx="3" fill="#024265")

				.for-clock(v-bind:class="{'no-blick': GetSettings.kind == 'copy' || GetSettings.kind == 'contract' || GetSettings.state == 'pause'}")
					svg(width="175" height="191" viewBox="0 0 175 191" fill="none" xmlns="http://www.w3.org/2000/svg")
						g(v-on:click="openDashboard()")
							g.line-one(ref="line-one")
								rect(x="32" width="15" height="15" rx="3")
								rect(x="48" width="15" height="15" rx="3")
								rect(x="64" width="15" height="15" rx="3")
								rect(x="80" width="15" height="15" rx="3")
								rect(x="96" width="15" height="15" rx="3")
								rect(x="112" width="15" height="15" rx="3")
								rect(x="128" width="15" height="15" rx="3")

							g.line-two(ref="line-two")
								rect(x="16" y="16" width="15" height="15" rx="3")
								rect(x="32" y="16" width="15" height="15" rx="3")
								rect(x="48" y="16" width="15" height="15" rx="3")
								rect(x="64" y="16" width="15" height="15" rx="3")
								rect(x="80" y="16" width="15" height="15" rx="3")
								rect(x="96" y="16" width="15" height="15" rx="3")
								rect(x="112" y="16" width="15" height="15" rx="3")
								rect(x="128" y="16" width="15" height="15" rx="3")
								rect(x="144" y="16" width="15" height="15" rx="3")

							g.line-three(ref="line-three")
								rect(x="32" y="32" width="15" height="15" rx="3")
								rect(x="48" y="32" width="15" height="15" rx="3")
								rect(x="64" y="32" width="15" height="15" rx="3")
								rect(x="80" y="32" width="15" height="15" rx="3")
								rect(x="96" y="32" width="15" height="15" rx="3")
								rect(x="112" y="32" width="15" height="15" rx="3")
								rect(x="128" y="32" width="15" height="15" rx="3")

							g.line-four(ref="line-four")
								rect(x="48" y="48" width="15" height="15" rx="3")
								rect(x="64" y="48" width="15" height="15" rx="3")
								rect(x="80" y="48" width="15" height="15" rx="3")
								rect(x="96" y="48" width="15" height="15" rx="3")
								rect(x="112" y="48" width="15" height="15" rx="3")

							g.line-five(ref="line-five")
								rect(x="64" y="64" width="15" height="15" rx="3")
								rect(x="80" y="64" width="15" height="15" rx="3")
								rect(x="96" y="64" width="15" height="15" rx="3")

							rect(x="0" width="15" height="15" rx="3" fill="#10A0CD")
							rect(x="16" width="15" height="15" rx="3" fill="#10A0CD")
							rect(x="144" width="15" height="15" rx="3" fill="#10A0CD")
							rect(x="160" width="15" height="15" rx="3" fill="#10A0CD")

							g.line-fill-one.line-t(ref="line-fill-one")
								rect(x="17" y="145" width="13" height="13" rx="3").fill
								rect(x="33" y="145" width="13" height="13" rx="3").fill
								rect(x="49" y="145" width="13" height="13" rx="3").fill
								rect(x="65" y="145" width="13" height="13" rx="3").fill
								rect(x="81" y="145" width="13" height="13" rx="3").fill
								rect(x="97" y="145" width="13" height="13" rx="3").fill
								rect(x="113" y="145" width="13" height="13" rx="3").fill
								rect(x="129" y="145" width="13" height="13" rx="3").fill
								rect(x="145" y="145" width="13" height="13" rx="3").fill

							g.line-fill-two.line-m(ref="line-fill-two")
								rect(x="1" y="161" width="13" height="13" rx="3").fill
								rect(x="17" y="161" width="13" height="13" rx="3").fill
								rect(x="33" y="161" width="13" height="13" rx="3").fill
								rect(x="49" y="161" width="13" height="13" rx="3").fill
								rect(x="65" y="161" width="13" height="13" rx="3").fill
								rect(x="81" y="161" width="13" height="13" rx="3").fill
								rect(x="97" y="161" width="13" height="13" rx="3").fill
								rect(x="113" y="161" width="13" height="13" rx="3").fill
								rect(x="129" y="161" width="13" height="13" rx="3").fill
								rect(x="145" y="161" width="13" height="13" rx="3").fill
								rect(x="161" y="161" width="13" height="13" rx="3").fill

							g.line-fill-three.line-b(ref="line-fill-three")
								rect(x="1" y="177" width="13" height="13" rx="3").fill
								rect(x="17" y="177" width="13" height="13" rx="3").fill
								rect(x="33" y="177" width="13" height="13" rx="3").fill
								rect(x="49" y="177" width="13" height="13" rx="3").fill
								rect(x="65" y="177" width="13" height="13" rx="3").fill
								rect(x="81" y="177" width="13" height="13" rx="3").fill
								rect(x="97" y="177" width="13" height="13" rx="3").fill
								rect(x="113" y="177" width="13" height="13" rx="3").fill
								rect(x="129" y="177" width="13" height="13" rx="3").fill
								rect(x="145" y="177" width="13" height="13" rx="3").fill
								rect(x="161" y="177" width="13" height="13" rx="3").fill

							rect(x="128" y="128" width="15" height="15" rx="3" fill="#86F000")
							rect(x="112" y="128" width="15" height="15" rx="3" fill="#86F000")
							rect(x="96" y="128" width="15" height="15" rx="3" fill="#86F000")
							rect(x="80" y="128" width="15" height="15" rx="3" fill="#86F000")
							rect(x="64" y="128" width="15" height="15" rx="3" fill="#86F000")
							rect(x="48" y="128" width="15" height="15" rx="3" fill="#86F000")
							rect(x="32" y="128" width="15" height="15" rx="3" fill="#86F000")
							rect(x="112" y="112" width="15" height="15" rx="3" fill="#86F000")
							rect(x="96" y="112" width="15" height="15" rx="3" fill="#86F000")
							rect(x="80" y="112" width="15" height="15" rx="3" fill="#86F000")
							rect(x="64" y="112" width="15" height="15" rx="3" fill="#86F000")
							rect(x="48" y="112" width="15" height="15" rx="3" fill="#86F000")
							rect(x="96" y="96" width="15" height="15" rx="3" fill="#86F000")
							rect(x="80" y="96" width="15" height="15" rx="3" fill="#86F000")
							rect(x="64" y="96" width="15" height="15" rx="3" fill="#86F000")
						a(href="https://www.letsverify.com/", target="_blank")
							rect(x="80" y="80" width="15" height="15" rx="3" fill="#86F000")

		.list
			.action
				.show-hide-clock(v-on:click="hideClock = !hideClock")

			.scroll-area
				.scroll-list(v-if="load")
					scrolly.scroll.foo
						scrolly-viewport
							.item.active(v-on:click="changeScroll('Campaign')", my-key="Campaign") Client Info
							.item(v-for="(item, index) in GetSettings.sections", v-bind:class="{disable: !item.enabled}", v-on:click="changeScroll(item.slug)" v-if="item.slug != 'BANT'", :my-key="item.slug")
								div(v-if="item.slug != 'IntentFeed'")
									span(v-if="item.name == 'Job Title'") {{ item.name }}s
									span(v-else) {{ item.name }}
								div(v-else)
									span Intent Data Signal Streams

						.scroll-bar
						scrolly-bar(axis="y")


		.count-cmaping
			p Remaining
				br
				| Leads

			.count
				span /
				p(v-if="load") {{ GetSettings.total_main.remaining_leads }}
				p(v-else) ---
</template>

<script>
	import { Scrolly, ScrollyViewport, ScrollyBar } from 'vue-scrolly'

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'SettingsSidebar',
		components:{
			Scrolly,
			ScrollyViewport,
			ScrollyBar
		},
		data() {
			return{
				hideClock: false,
				campaignID: '',
				load: false,
				rows:[
					'line-five',
					'line-four',
					'line-three',
					'line-two',
					'line-one'
				],
				squares:[
					'<rect x="145" y="145" width="13" height="13" rx="3"></rect><rect x="129" y="145" width="13" height="13" rx="3"></rect><rect x="113" y="145" width="13" height="13" rx="3"></rect><rect x="97" y="145" width="13" height="13" rx="3"></rect><rect x="81" y="145" width="13" height="13" rx="3"></rect><rect x="65" y="145" width="13" height="13" rx="3"></rect><rect x="49" y="145" width="13" height="13" rx="3"></rect><rect x="33" y="145" width="13" height="13" rx="3"></rect><rect x="17" y="145" width="13" height="13" rx="3"></rect>',
					'<rect x="161" y="161" width="13" height="13" rx="3"></rect><rect x="145" y="161" width="13" height="13" rx="3"></rect><rect x="129" y="161" width="13" height="13" rx="3"></rect><rect x="113" y="161" width="13" height="13" rx="3"></rect><rect x="97" y="161" width="13" height="13" rx="3"></rect><rect x="81" y="161" width="13" height="13" rx="3"></rect><rect x="65" y="161" width="13" height="13" rx="3"></rect><rect x="49" y="161" width="13" height="13" rx="3"></rect><rect x="33" y="161" width="13" height="13" rx="3"></rect><rect x="17" y="161" width="13" height="13" rx="3"></rect><rect x="1" y="161" width="13" height="13" rx="3"></rect>',
					'<rect x="161" y="177" width="13" height="13" rx="3"></rect><rect x="145" y="177" width="13" height="13" rx="3"></rect><rect x="129" y="177" width="13" height="13" rx="3"></rect><rect x="113" y="177" width="13" height="13" rx="3"></rect><rect x="97" y="177" width="13" height="13" rx="3"></rect><rect x="81" y="177" width="13" height="13" rx="3"></rect><rect x="65" y="177" width="13" height="13" rx="3"></rect><rect x="49" y="177" width="13" height="13" rx="3"></rect><rect x="33" y="177" width="13" height="13" rx="3"></rect><rect x="17" y="177" width="13" height="13" rx="3"></rect><rect x="1" y="177" width="13" height="13" rx="3"></rect>'
				],
				targetsHours: {
					top: {
						leads_goal: 0,
						leads_generated: 0
					},
					middle: {
						leads_goal: 0,
						leads_generated: 0
					},
					bottom: {
						leads_goal: 0,
						leads_generated: 0
					}
				},
				navLi: ''
			}
		},
		watch: {
			'GetSettings.total_main': function () {
				this.load = true;
				if (this.GetSettings.kind != 'copy' && this.GetSettings.kind != 'contract') {
					setTimeout(() => {
						this.createHours();
					}, 100);
				}
				setTimeout(() => {
					this.getLightEffect();
				}, 100);
			},
			'GetSettings.total_audience': function () {
				setTimeout(() => {
					this.fillHours();
				}, 100);
				// this.setMaxInt();
			},
			'GetSettings.trigger': function () {
				setTimeout(() => {
					this.getLightEffect();
				}, 100);
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		// created: function(){
			// this.sortFunc(this.GetSettings.sections);
		// 	if (localStorage.getItem('campaign_id')) {
		// 		this.campaignID = localStorage.getItem('campaign_id');
		// 		this.loadCampaignData();
		// 	}
		// },
		updated(){
			this.navLi = document.querySelectorAll(".settings-sidebar .scrolly-viewport .item");
			
			this.$nextTick(function() {
				if(window.location.hash) {
					this.changeScroll(window.location.hash.substring(1));
				}
			})
		},
		methods:{
			...mapMutations(['setTarget']),
			fillHours(){
				var percentFill = (this.GetSettings.total_audience / this.GetSettings.max_audience) * 100;
				var cq = 0;
				for(var row in this.rows){
					cq += this.$refs.[this.rows[row]].children.length;

					var lnC = this.$refs.[this.rows[row]].children.length;
					for (var d = lnC - 1; d >= 0; d--) {
						this.$refs.[this.rows[row]].children[d].classList.remove('fill-top');
					}
				}

				var percentPerOne = Number(100 / cq).toFixed(8);
				var percentLeft = percentFill;
				for(var item in this.rows){
					var cquares = 0;
					var offset = 0;
					var cqua = 0;
					var ln = this.$refs.[this.rows[item]].children.length;
					// console.log((ln * percentPerOne + percentPerOne * 3), percentLeft, ln, percentPerOne);
					// if ((ln * percentPerOne + percentPerOne * 3) < percentLeft) {
					if ((ln * percentPerOne) < percentLeft) {
						for (var i = ln - 1; i >= 0; i--) {
							this.$refs.[this.rows[item]].children[i].classList.add('fill-top');
						}
						percentLeft = percentLeft - (ln * percentPerOne);
					}else{
						cquares = Number(percentLeft / percentPerOne).toFixed(0)
						offset = Number(this.$refs.[this.rows[item]].children.length - cquares).toFixed(0);
						if (offset % 2) {
							offset = +offset + +1;
						}
						offset = offset / 2;
						cqua = Number(this.$refs.[this.rows[item]].children.length - offset).toFixed(0);
						if ((cqua - offset) == 1) {
							offset = 2;
							cqua = 5;
							var lnPrev = this.$refs.[this.rows[item - 1]].children.length - 1;
							this.$refs.[this.rows[item - 1]].children[0].classList.remove('fill-top')
							this.$refs.[this.rows[item - 1]].children[lnPrev].classList.remove('fill-top')
						}
						for (var b = offset; b <= cqua - 1; b++) {
							this.$refs.[this.rows[item]].children[b].classList.add('fill-top');
						}
						return false;
					}
				}
			},
			createHours(){
				this.targetsHours = {
					top: {
						leads_goal: 0,
						leads_generated: 0
					},
					middle: {
						leads_goal: 0,
						leads_generated: 0
					},
					bottom: {
						leads_goal: 0,
						leads_generated: 0
					}
				}
				this.$refs.['line-fill-one'].classList.remove('line-t', 'line-m', 'line-b');
				this.$refs.['line-fill-two'].classList.remove('line-t', 'line-m', 'line-b');
				this.$refs.['line-fill-three'].classList.remove('line-t', 'line-m', 'line-b');

				var top = false;
				var middle = false;
				var bottom = false;

				var stringCount = 0;

				for(var item in this.GetSettings.targets){
					if (this.GetSettings.targets[item].grade == 'Top') {
						this.targetsHours.top.leads_goal += +Number(this.GetSettings.targets[item].leads_goal).toFixed(0);
						this.targetsHours.top.leads_generated += +Number(this.GetSettings.targets[item].leads_generated).toFixed(0);
						if (!top) {
							stringCount += 1;
						}
						top = true;
					}
					if (this.GetSettings.targets[item].grade == 'Middle') {
						this.targetsHours.middle.leads_goal += +Number(this.GetSettings.targets[item].leads_goal).toFixed(0);
						this.targetsHours.middle.leads_generated += +Number(this.GetSettings.targets[item].leads_generated).toFixed(0);
						if (!middle) {
							stringCount += 1;
						}
						middle = true;
					}
					if (this.GetSettings.targets[item].grade == 'Bottom') {
						this.targetsHours.bottom.leads_goal += +Number(this.GetSettings.targets[item].leads_goal).toFixed(0);
						this.targetsHours.bottom.leads_generated += +Number(this.GetSettings.targets[item].leads_generated).toFixed(0);
						if (!bottom) {
							stringCount += 1;
						}
						bottom = true;
					}
				}

				this.$refs.['line-fill-one'].innerHTML = '';
				this.$refs.['line-fill-two'].innerHTML = '';
				this.$refs.['line-fill-three'].innerHTML = '';

				if (stringCount == 3) {
					this.$refs.['line-fill-one'].classList.add('line-t');
					this.$refs.['line-fill-two'].classList.add('line-m');
					this.$refs.['line-fill-three'].classList.add('line-b');
					this.$refs.['line-fill-one'].innerHTML = this.squares[0];
					this.$refs.['line-fill-two'].innerHTML = this.squares[1];
					this.$refs.['line-fill-three'].innerHTML = this.squares[2];
					this.fillTop('line-fill-one');
					this.fillMiddle('line-fill-two');
					this.fillBottom('line-fill-three');
				}else if(stringCount == 2){
					if (top && middle) {
						this.$refs.['line-fill-one'].classList.add('line-t');
						this.$refs.['line-fill-two'].classList.add('line-m');

						if (this.targetsHours.top.leads_goal > this.targetsHours.middle.leads_goal) {
							this.$refs.['line-fill-one'].innerHTML = this.squares[0] + this.squares[1];
							this.$refs.['line-fill-two'].innerHTML = this.squares[2];
						}else{
							this.$refs.['line-fill-one'].innerHTML = this.squares[0];
							this.$refs.['line-fill-two'].innerHTML = this.squares[1] + this.squares[2];
						}
						this.fillTop('line-fill-one');
						this.fillMiddle('line-fill-two');
					}else if(top && bottom){
						this.$refs.['line-fill-one'].classList.add('line-t');
						this.$refs.['line-fill-two'].classList.add('line-b');

						if (this.targetsHours.top.leads_goal > this.targetsHours.bottom.leads_goal) {
							this.$refs.['line-fill-one'].innerHTML = this.squares[0] + this.squares[1];
							this.$refs.['line-fill-two'].innerHTML = this.squares[2];
						}else{
							this.$refs.['line-fill-one'].innerHTML = this.squares[0];
							this.$refs.['line-fill-two'].innerHTML = this.squares[1] + this.squares[2];
						}
						this.fillTop('line-fill-one');
						this.fillBottom('line-fill-two');
					}else{
						this.$refs.['line-fill-one'].classList.add('line-m');
						this.$refs.['line-fill-two'].classList.add('line-b');

						if (this.targetsHours.middle.leads_goal > this.targetsHours.bottom.leads_goal) {
							this.$refs.['line-fill-one'].innerHTML = this.squares[0] + this.squares[1];
							this.$refs.['line-fill-two'].innerHTML = this.squares[2];
						}else{
							this.$refs.['line-fill-one'].innerHTML = this.squares[0];
							this.$refs.['line-fill-two'].innerHTML = this.squares[1] + this.squares[2];
						}

						this.fillMiddle('line-fill-one');
						this.fillBottom('line-fill-two');
					}
				}else if(stringCount == 1){
					this.$refs.['line-fill-one'].innerHTML = this.squares[0] + this.squares[1] + this.squares[2];

					if (top) {
						this.$refs.['line-fill-one'].classList.add('line-t');
						this.fillTop('line-fill-one');
					}else if(middle){
						this.$refs.['line-fill-one'].classList.add('line-m');
						this.fillMiddle('line-fill-one');
					}else if(bottom){
						this.$refs.['line-fill-one'].classList.add('line-b');
						this.fillBottom('line-fill-one');
					}
				}
			},
			fillTop(ref){
				var lenTop = this.$refs.[ref].children.length;
				var topPercentFill = Number((this.targetsHours.top.leads_generated / this.targetsHours.top.leads_goal) * 100).toFixed(0);
				var topPercentOne = Number(100 / lenTop).toFixed(8);
				var topFill = Number(topPercentFill / topPercentOne).toFixed(0);

				var offset = lenTop - topFill;

				for (var t = 0; t <= topFill - 1; t++) {
					// this.$refs.[ref].children[t].classList.add('fill');
					this.$refs.[ref].children[offset + t].classList.add('fill');
				}
			},
			fillMiddle(ref){
				var lenMiddle = this.$refs.[ref].children.length;
				var middlePercentFill = Number((this.targetsHours.middle.leads_generated / this.targetsHours.middle.leads_goal) * 100).toFixed(0);
				var middlePercentOne = Number(100 / lenMiddle).toFixed(8);
				var middleFill = Number(middlePercentFill / middlePercentOne).toFixed(0);

				var offset = lenMiddle - middleFill;

				for (var m = 0; m <= middleFill - 1; m++) {
					// this.$refs.[ref].children[m].classList.add('fill');
					this.$refs.[ref].children[offset + m].classList.add('fill');
				}
			},
			fillBottom(ref){
				var lenBottom = this.$refs.[ref].children.length;
				var bottomPercentFill = Number((this.targetsHours.bottom.leads_generated / this.targetsHours.bottom.leads_goal) * 100).toFixed(0);
				var bottomPercentOne = Number(100 / lenBottom).toFixed(8);
				var bottomFill = Number(bottomPercentFill / bottomPercentOne).toFixed(0);

				var offset = lenBottom - bottomFill;

				for (var b = 0; b <= bottomFill - 1; b++) {
					// this.$refs.[ref].children[b].classList.add('fill');
					this.$refs.[ref].children[offset + b].classList.add('fill');
				}
			},
			getLightEffect(){
				setTimeout(() => {

				}, 100);
				var quality = 0;
				for(var item in this.GetSettings.sections){
					if (this.GetSettings.sections[item].quality_sector) {
						if (this.GetSettings.sections[item].enabled && (this.GetSettings.sections[item].slug == 'CQ' || this.GetSettings.sections[item].slug == 'BANT' || this.GetSettings.sections[item].slug == 'Nurturing')) {
							quality += this.GetSettings.sections[item].quality_sector;
						}
					}
				}
				quality += this.GetSettings.base_quality;
				// var light = Number(quality * 0.2).toFixed(2);
				var light =quality / 100;
				this.$refs.light.style.opacity = light;
			},
			changeScroll(id){
				this.navLi.forEach((li) => {
					li.classList.remove("active");
					if (li.getAttribute('my-key') == id) {
						li.classList.add("active");
					}
				});
				this.setTarget(id);
			},
			openDashboard(){
				// localStorage.setItem('cfs_id', this.GetSettings.campaing_id);
				// localStorage.setItem('cfs_client_id', this.GetSettings.client.id);
				// localStorage.setItem('cfs_client_name', this.GetSettings.client.name);
				// localStorage.setItem('cfs_client_name', this.GetSettings.client.name);
				var campaignMainInfo = {
					client: {
						id: this.GetSettings.client.id,
						name: this.GetSettings.client.name
					},
					campaign: {
						id: this.GetSettings.campaing_id,
						name: this.GetSettings.name,
						clientName: this.GetSettings.client.name,
						kind: this.GetSettings.kind,
						pacing: this.GetSettings.pacing,
						integration: this.GetSettings.integration,
						client: this.GetSettings.client.id
					}
				}
				localStorage.setItem('campaign_from_settings', JSON.stringify(campaignMainInfo));
				setTimeout(() => {
					window.open(window.baseURL, '_blank');
				}, 250);
			}
		}
	}
</script>