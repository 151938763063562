// for selected campaign

export default{
	actions: {
	},
	mutations: {
		dataLoad(state, data){
			state.load = data;
		},
		setSC(state, data){
			state.id = data.id;
			state.name = data.name;
			state.kind = data.kind;
			state.pacing = data.pacing;
			state.integration = data.integration;
			state.campaignClient = data.clientName;
			localStorage.setItem('cliend_id', data.client);
			localStorage.setItem('campaign_id', data.id);
		}
	},
	state: {
		load: false,
		id: null,
		name: null,
		kind: null,
		pacing: null,
		integration: null,
		campaignClient: null
	},
	getters:{
		SC(state){
			return state
		}
	}
}