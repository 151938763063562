import Vue from 'vue'
import VueRouter from 'vue-router'
import UI from '../pages/Ui/'
import Home from '../pages/Home/'
import Login from '../pages/Login/'
import Forgot from '../pages/Forgot-Password/'
import NewPassword from '../pages/New-Password/'
import AddCampaign from '../pages/Add-Campaign/'
import Settings from '../pages/Settings/'
import Engine from '../pages/Engine/'
import EngineNew from '../pages/Engine-new/'
import Mobile from '../pages/Mobile/'
import Audience from '../pages/Audience'

Vue.use(VueRouter)

// window.baseURL = '/accelerator/';
window.baseURL = '/';
var baseURL = window.baseURL;

const routes = [
	// {
	// 	path: '/',
	// 	name: 'Home',
	// 	component: Home
	// },
	{
		path: baseURL,
		name: 'Home',
		component: Home
	},
	{
		path: baseURL+'login',
		name: 'Login',
		id: 1,
		component: Login
	},
	{
		path: baseURL+'forgot-password',
		name: 'Forgot',
		id: 2,
		component: Forgot
	},
	{
		path: baseURL+'add-new-campaign',
		name: 'Add-Campaign',
		id: 3,
		component: AddCampaign
	},
	{
		path: baseURL+'settings',
		name: 'Settings',
		id: 4,
		component: Settings
	},
	{
		path: baseURL+'engine',
		name: 'Engine',
		id: 5,
		component: Engine
	},
	{
		path: baseURL+'mobile',
		name: 'Mobile',
		id: 6,
		component: Mobile
	},
	{
		path: baseURL+'engine-new',
		name: 'Engine-new',
		id: 7,
		component: EngineNew
	},
	{
		path: baseURL+'audience',
		name: 'Audience',
		id: 8,
		component: Audience
	},
	{
		path: baseURL+'new-password',
		name: 'NewPassword',
		id: 9,
		component: NewPassword
	},
	{
		path: baseURL+'ui',
		name: 'Ui',
		id: 22,
		component: UI
	},
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
