export default{
	Token: {
		refresh: null,
		access: null
	},
	Auth: {
		email: null,
		first_name: null,
		groups: null,
		id: null,
		last_name: null,
		username: null,
		login: false
	},
	AddCampaign:{
		clientID: null,
		clientName: null,
		status: false
	},
	AddDealDesk:{
		status: false
	},
	// SelectedCampaign:{
	// 	id: null,
	// 	name: null,
	// 	kind: null,
	// 	campaignClient: null
	// }
}