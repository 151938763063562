<template lang="pug">
	div.card-box
		.card.questions-card(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="Custom Questions", slug="CQ", v-model="cardStatus", file="https://hourglass.infusemedia.com/staticfiles/Custom_Content-evergreen.pdf")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.custom_questions.length)}")
				p Infuse your funnel with the most qualified prospects.

			.questions-list(v-if="GetSettings.custom_questions.length")
				.single.with-btn(v-for="(item, index) in GetSettings.custom_questions", v-bind:class="{disabled: item.state == 'pause'}")
					.action-play-pause(
						v-if="item.state",
						v-bind:class="{played: item.state == 'running'}"
						v-on:click="changeCQStatus(index)"
					)
						.play
							svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
								rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
								path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
						.pause
							svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
								rect(width="20" height="20" rx="3").f-f
								rect(x="7" y="5" width="2" height="10" rx="1").f-t
								rect(x="11" y="5" width="2" height="10" rx="1").f-t

					.question-settings
						p {{ item.question_txt != null ? item.question_txt : item.question.question }}
						.settings(v-on:click="editQuestion(index)")
							svg(width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg")
								path(d="M9.96641 5.56628C9.98696 5.3804 10 5.19191 10 5.00016C10 4.80841 9.98696 4.61993 9.96609 4.43372L8.53845 3.92597C8.46736 3.69248 8.37409 3.46943 8.26127 3.25746L8.91085 1.89043C8.67444 1.59395 8.40558 1.32487 8.10931 1.08821L6.74232 1.73912C6.52925 1.62474 6.30531 1.53189 6.07383 1.46193L5.56643 0.034241C5.38023 0.0133703 5.19174 0 5 0C4.80826 0 4.61977 0.0130442 4.43357 0.034241L3.92617 1.46193C3.69269 1.53269 3.46964 1.62563 3.25768 1.73912L1.89037 1.08821C1.59362 1.32464 1.32492 1.59367 1.08818 1.89043L1.73906 3.25681C1.62558 3.46878 1.53264 3.69216 1.46188 3.92532L0.0342399 4.43405C0.0133699 4.61993 0 4.80907 0 5.00049C0 5.19191 0.0130438 5.3804 0.0342399 5.56661L1.46188 6.07468C1.53264 6.30784 1.62558 6.53122 1.73906 6.74319L1.0885 8.11022C1.32492 8.40698 1.59395 8.67536 1.89069 8.91179L3.2567 8.26121C3.46866 8.37469 3.69204 8.46796 3.92519 8.53905L4.43325 9.96641C4.61912 9.98696 4.8076 10 4.99967 10C5.19142 10 5.37957 9.98696 5.56577 9.96609L6.07383 8.5384C6.30698 8.46731 6.53036 8.37404 6.74232 8.26121L8.10931 8.91081C8.40605 8.67439 8.67443 8.40535 8.91085 8.10925L8.26094 6.74254C8.37442 6.53155 8.46768 6.30752 8.53877 6.07403L9.96641 5.56628ZM5 6.58829C4.79141 6.58834 4.58485 6.54729 4.39212 6.4675C4.19939 6.38772 4.02426 6.27075 3.87673 6.12328C3.57879 5.82544 3.41136 5.42145 3.41127 5.00016C3.41118 4.57888 3.57845 4.17481 3.87627 3.87686C4.17409 3.57891 4.57808 3.41147 4.99935 3.41138C5.42062 3.41129 5.82467 3.57857 6.12261 3.8764C6.42056 4.17423 6.58799 4.57823 6.58808 4.99951C6.58816 5.4208 6.4209 5.82486 6.12308 6.12281C5.82525 6.42077 5.42127 6.58821 5 6.58829Z" fill="#4F4F4F")

					DropBoxANS(
						:list="item.question.answers",
						:default="item.answer_txt != null ? item.answer_txt : item.answer",
						v-model="item.answer",
						v-on:input="updateAnswer(index)"
					)

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Infuse your funnel with the most qualified prospects.
				
			button.btn.add-new-btn(type="button", v-on:click="addQuestion()") ADD A NEW QUESTION
				
		.popup-cn(v-if="popupQuestion")
			.overclick(v-on:click="showHidePopups()")
			.popup.change-question(v-if="popupQuestion")
				.close(v-on:click="showHidePopups()")
				.inside-form
					label Question
					input(type="text", placeholder="Text here...", v-model="customQuestion", v-bind:class="{error: questionError}", autocomplete="xz").inp

					div(v-for="(item, index) in customAnswer")
						label Answer 
							span(v-if="customAnswer.length > 1") {{index + 1}}
							span(v-on:click="removeAnswer(index)", v-if="index != 0").remove-ans Remove
						input(
							type="text",
							placeholder="Text here...",
							v-model="customAnswer[index].answer",
							v-bind:class="{error: answerErrorIndex[index]}",
							autocomplete="xz"
						).inp
						//- input(
						//- 	type="text",
						//- 	placeholder="Text here...",
						//- 	v-model="customAnswer[index]",
						//- 	v-bind:class="{error: answerErrorIndex[index]}"
						//- ).inp
					
					div(v-if="editIndex")
						p.add-answer(
							v-on:click="addMoreAnswers()",
							v-if="(customAnswer.length - 1 + GetSettings.custom_questions[editIndex].question.answers.length) < 5"
						) Add Answer

					div(v-else)
						p.add-answer(
							v-on:click="addMoreAnswers()",
							v-if="(customAnswer.length - 1) < 5"
						) Add Answer

					button.btn.simple(type="button", v-on:click="saveQuestion()") SAVE CHANGES

</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropBoxANS from "../components/drop-box-ans.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'CustomQuestions',
		components:{
			CardHeader,
			DropBoxANS
		},
		data() {
			return {
				cardStatus: true,
				popupQuestion: false,
				customAnswer: [],
				answerErrorIndex: [],
				questionError: false,
				editIndex: null,
				originalAnswer: null,
				state: 'pause'
			}
		},
		created(){
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "CQ") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			changeCQStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.custom_questions.[index].state == 'running'){
						this.GetSettings.custom_questions.[index].state = 'pause'
					}else{
						this.GetSettings.custom_questions.[index].state = 'running'
					}
				}
			},
			addQuestion(){
				if (this.cardStatus) {
					this.customQuestion = '';
					this.customAnswer = [{answer:''}];
					this.popupQuestion = !this.popupQuestion;
					this.$emit('input', this.popupQuestion);
				}
			},
			editQuestion(index){
				if (this.cardStatus) {
					this.editIndex = index;
					this.customQuestion = this.GetSettings.custom_questions[this.editIndex].question.question;
					this.customAnswer = this.GetSettings.custom_questions[this.editIndex].question.answers;
					this.originalAnswer = this.GetSettings.custom_questions[this.editIndex].answer.id;
					this.answerErrorIndex = [];
					this.state = this.GetSettings.custom_questions[this.editIndex].state;
					this.popupQuestion = !this.popupQuestion;
					this.$emit('input', this.popupQuestion);
				}
			},
			updateAnswer(index){
				this.GetSettings.custom_questions[index].answer_txt = null;
				this.updateSettings(['custom_questions', this.GetSettings.custom_questions]);
				this.showHidePopups();
			},
			showHidePopups(){
				this.customQuestion = '';
				this.customAnswer = [];
				this.answerErrorIndex = [];
				this.editIndex = null;
				this.originalAnswer = null;
				this.state = 'pause';
				this.popupQuestion = false;
				this.$emit('input', false);
			},
			saveQuestion(){
				var errors = false;
				for (var i = 0; i <= this.customAnswer.length - 1; i++) {
					if (this.customAnswer[i].answer.length < 1) {
						this.$set(this.answerErrorIndex, i, true)
						errors = true;
					}else{
						this.$set(this.answerErrorIndex, i, false)
					}
				}
				if (this.customQuestion.length < 1) {
					errors = true;
					this.questionError = true;
				}
				setTimeout(() => {
					this.answerErrorIndex = [];
					this.questionError = false;
					errors = false;
				}, 3000)
				if (!errors) {
					var newCQ = {
						question: {
							answers: [],
							question: this.customQuestion.trim()
						},
						answer: {
							answer: this.customAnswer[0].answer.trim()
						},
						state: this.state
					}

					for(var answer in this.customAnswer){
						newCQ.question.answers.push({answer: this.customAnswer[answer].answer.trim()});
					}

					if (this.editIndex == null) {
						var key =  Object.keys(this.GetSettings.custom_questions).length;
						this.$set(this.GetSettings.custom_questions, key, newCQ);
					}else{
						this.$set(this.GetSettings.custom_questions, this.editIndex, newCQ);
					}

					this.updateSettings(['custom_questions', this.GetSettings.custom_questions]);
					this.editIndex = null;
					this.originalAnswer = null;
					this.customAnswer = [];
					this.answerErrorIndex = [];
					this.state = 'pause';
					this.showHidePopups();
				}
			},
			addMoreAnswers(){
				this.customAnswer.push({'answer': ''});
				this.answerErrorIndex.push(false)
			},
			removeAnswer(index){
				this.customAnswer.splice(index, 1);
				this.answerErrorIndex.splice(index, 1);
			}
		}
	}
</script>