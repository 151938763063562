<template lang="pug">
	.right-sidebar
		UserBlock

		.actions(v-if="activeCampaign && RightSidebar.sections")
			button.btn.play-pause.paused(type="button", v-if="RightSidebar.state == 'running'", v-on:click="runPause('pause')") Pause
			button.btn.play-pause(type="button", v-else, v-on:click="runPause('running')") RESUME
			router-link(to="./engine", target="_blank").btn.engine(type="button") Engine

		.scroll-list(v-if="activeCampaign && RightSidebar.sections")
			scrolly.scroll.foo
				scrolly-viewport
					.camaing-setup
						.title
							p.count
								//- span {{ RightSidebar.sections.filter(item => item.enabled == true).length }}
								//- | /{{RightSidebar.sections.length - 2}}
								span {{ activeCount }}
								| /{{RightSidebar.sections.length - 3}}

							//- router-link(to="/settings", target="_blank").campaign-link.sm-setup Campaign Setup
							p(v-on:click="openNewTab()").campaign-link.sm-setup Campaign Setup

						.setup-list
							//- .single(v-for="(item, index) in RightSidebar.sections", v-if="setupLimit == false || index < setupLimit")
							//- 	input(type="checkbox", :id="'setup-'+item.id", v-model="item.enabled").check
							//- 	label(:for="'setup-'+item.id") {{ item.name }}
							//- 	.sm-ico
							.single(v-for="(item, index) in RightSidebar.sections", v-if="setupLimit == false || index < setupLimit")
								div(v-if="item.slug != 'lets_verify' && item.slug != 'Tactics' && item.slug != 'BANT'")
									input(type="checkbox", :id="'setup-'+item.id", v-model="item.enabled", v-on:change="updateSections()").check
									label(:for="'setup-'+item.id")
									//- router-link(:to="'/settings#'+item.slug", target="_blank", v-if="item.slug != 'IntentFeed'") {{ item.name }}
									//- 	span(v-if="item.name == 'Job Title'") s
									//- router-link(:to="'/settings#'+item.slug", target="_blank", v-else) Intent Data Signal Streams
									p(v-on:click="openNewTab(item.slug)", v-if="item.slug != 'IntentFeed'") {{ item.name }}
										span(v-if="item.name == 'Job Title'") s
									p(v-on:click="openNewTab(item.slug)", v-else) Intent Data Signal Streams
									.sm-ico(v-on:click="openNewTab(item.slug)")

							.more-list
								.show-more(v-on:click="showMore()", v-bind:class="{active: setupLimit == false}")

					.tactics
						.title
							p.count
								span {{ RightSidebar.tactics.filter(item => item.active == true).length }}
								| /{{RightSidebar.tactics.length}}

							//- router-link(to="/settings#Tactics", target="_blank").tactics-activated Tactics Activated
							p(v-on:click="openNewTab('Tactics')").tactics-activated Tactics Activated

						.tactics-list
							.single-link(v-for="item in RightSidebar.tactics")
								//- router-link(to="/settings#Tactics", v-bind:class="{active: item.active}", target="_blank").link-to {{ item.name }}
								p(v-on:click="openNewTab('Tactics')", v-bind:class="{active: item.active}").link-to {{ item.name }}

				.scroll-bar
				scrolly-bar(axis="y")
		
		.static-bar(v-if="!activeCampaign")
			.for-engine-btn
				router-link(to="./engine", target="_blank").engine-go Engine

			a(href="./audience", target="_blank").database(v-bind:class="{'start-anim': DatabaseAnim}")
				p Database of
				b 138+
					span million
				p Decision Makers

			a(href="https://www.letsverify.com/", target="_blank").verify-logo
				img(src="@/assets/lets-verify-logo.svg", alt="")

			.statistic(v-bind:class="{'start-anim': StatisticAnim}")
				b 6+
					span million

				p Verified Leads 
					br
					| Delivered 
					br
					| to 1,500+ Clients

			a(href="https://intent.infusemedia.com/", target="_blank").tpi-link TRIPLE PLAY INTENT

</template>

<script>
	import { Scrolly, ScrollyViewport, ScrollyBar } from 'vue-scrolly'
	import UserBlock from '@/components/User-Action'

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'RightSidebar',
		components:{
			UserBlock,
			Scrolly,
			ScrollyViewport,
			ScrollyBar
		},
		data(){
			return {
				clientsList: false,
				setupLimit: 12,
				activeCampaign: '',
				RS: '',
				activeSettingsCount: 0,
				activeTacticsCount: 0,
				
				// animation props
				DatabaseAnim: false,
				StatisticAnim: false,
				activeCount: 0
			}
		},
		created: function(){
			// this.runHour();
			setTimeout(() => this.startSidebarAnimation(), 1000)
		},
		// computed: mapGetters(['RightSidebar']);
		computed: {
			...mapGetters(['RightSidebar']),
			SC(){
				return this.$store.getters.SC.id;
			}
		},
		// computed: {
		// 	RightSidebar(){
		// 		return this.$store.getters.RightSidebar;
		// 	},
		// 	SC(){
		// 		return this.$store.getters.SC.id;
		// 	}
		// },
		watch: {
			'RightSidebar.sectionsLoad': function () {
				if (this.RightSidebar.sectionsLoad) {
					this.calcActive();
					this.sortFunc(this.RightSidebar.sections);
					this.fixLCP(this.RightSidebar.sections);
				}
			},
			'RightSidebar.trigger': function () {
				this.calcActive();
			},

			// RightSidebar(){
			// 	this.RS = this.$store.state.RightSidebar;
			// },
			SC(){
				this.activeCampaign = this.$store.state.SelectedCampaign;
			}
		},
		methods:{
			...mapMutations(['updateRS']),
			updateSections(){
				this.updateRS(['sections', this.RightSidebar.sections]);
				var trigger = !this.RightSidebar.trigger;
				this.updateRS(['trigger', trigger]);
			},
			calcActive(){
				this.activeCount = 0;
				for (var item in this.RightSidebar.sections) {
					if (this.RightSidebar.sections[item].enabled && this.RightSidebar.sections[item].slug != 'Tactics' && this.RightSidebar.sections[item].slug != 'lets_verify' && this.RightSidebar.sections[item].slug != 'BANT') {
						this.activeCount += +1;
					}
				}
				this.updateRS(['sectionsLoad', false]);
			},
			sortFunc: function (obj){
				return obj.sort((a, b) => a.id - b.id );
			},
			fixLCP(){
				// var index = this.RightSidebar.sections.findIndex(element => element.slug == "LeadCascadeProgram'");
				// if (index > 0) {
				// 	this.RightSidebar.sections[index].slug = 'LeadCascadeProgram';
				// }
				for (var section in this.RightSidebar.sections) {
					if (this.RightSidebar.sections[section].name == 'Suppression list(s)') {
						this.RightSidebar.sections[section].name = 'Suppression List(s)';
					}
					if (this.RightSidebar.sections[section].slug == "LeadCascadeProgram'") {
						this.RightSidebar.sections[section].slug = 'LeadCascadeProgram';
					}
				}
				this.updateRS(['sections', this.RightSidebar.sections]);
			},
			startSidebarAnimation(){
				this.DatabaseAnim = true;
				this.StatisticAnim = true;
			},
			showMore(){
				if (!this.setupLimit) {
					this.setupLimit = 12
				}else{
					this.setupLimit = false;
				}
			},
			runPause(state){
				this.updateRS(['state', state]);
			},
			openNewTab(target){
				var URL = './settings';
				localStorage.setItem('campaign_id', this.SC);
				if (target) {
					URL = './settings#'+target
				}
				setTimeout(() => {
					window.open(URL, '_blank');
				}, 250);
			}
		}
	}
</script>