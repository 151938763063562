<template lang="pug">
	div.card-box
		.card.job-titles(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="Job Titles", slug="JobTitle", v-model="cardStatus")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.titles.length)}")
				p Provide ideal job titles to ensure precise targeting.

			div(v-if="GetSettings.titles.length")
				.titles-table
					.t-head
						.cl
							p Titles Captured

						.cl
							p Leads

						.cl
							//- p(v-bind:class="{empty: emptyGoal}") Goal per Titles
							p Goal per Titles

						.cl
							.chart-range(ref="chart")
								.single
									p 0

								.single
									p {{ Number(maxNumber / maxDivide * 1).toFixed(0) }}

								.single
									p {{ Number(maxNumber / maxDivide * 2).toFixed(0) }}

								.single
									p {{ Number(maxNumber / maxDivide * 3).toFixed(0) }}

					.t-row(v-for="(item, index) in GetSettings.titles", v-bind:class="{'active': item.state == 'running'}")
						.cl
							.action-play-pause(
								v-if="item.state",
								v-bind:class="{played: item.state == 'running'}"
								v-on:click="changeJobStatus(index)"
							)
								.play
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
										path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
								.pause
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(width="20" height="20" rx="3").f-f
										rect(x="7" y="5" width="2" height="10" rx="1").f-t
										rect(x="11" y="5" width="2" height="10" rx="1").f-t

							p(v-if="item.job_title", :title="item.job_title.name") {{ item.job_title.name }}
							p(v-else, :title="item.user_job_title") {{ item.user_job_title }}

						.cl
							//- p(v-if="item.percent") {{ Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(0) }}
							p(v-if="item.percent") {{ item.leads_generated}}
							p(v-else) -

						.cl
							//- p(v-if="item.percent") {{ Number(item.percent).toFixed(0) }}%
							//- p(v-else) -
							.inp-row
								.for-inp
									p.invisible {{item.goal}}%
									.field-absolute
										input(
											type="text",
											v-model="item.goal",
											:ref="'goal_'+index",
											v-on:focus="editLeadGoal(index)",
											v-on:blur="handleFocusOut",
											v-on:input="checkGoal(index)"
											v-bind:class="{error: leadGoalError === index}",
											@keypress="onlyNumber"
										).goal-inp
										span(v-if="item.goal > 0 && item.goal <= 100") %
									
									.error-tooltip.up(v-bind:class="{show: leadGoalError === index}")
										span The lead goal can not be smaller than the leads generated within this section or larger than the campaign lead goal.

						.cl
							.fill-line
								.lines
									.s-line
									.s-line
									.s-line
									.s-line

								.fill(:style='{width: Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(0) / maxNumber * 100 + "%"}')
									p(
										v-bind:class="{'push': chartWidth * Number(Number(GetSettings.total_main.leads_generated * (item.percent / 100)).toFixed(0) / maxNumber * 100).toFixed(2) / 100 < 40 }"
								) {{ Number(item.percent).toFixed(2) }}%

						.cl
							.remove-row(v-on:click="removeRow(index)", v-if="item.new")

				.for-list
					.blue-ico-text(
						v-on:click="showList()",
						v-bind:class="{opened: listOpened}"
					)

						.ico
							svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
								path(d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3Z").cl-1
								path(d="M6.20731 7.40939L5.84431 7.06555L6.20731 7.40939ZM7.16846 8.40375L6.80896 8.75125L6.95637 8.90375H7.16846V8.40375ZM8.69918 8.90375C8.97533 8.90375 9.19918 8.6799 9.19918 8.40375C9.19918 8.12761 8.97533 7.90376 8.69918 7.90376V8.90375ZM5.67334 7.14152C5.55249 7.14152 5.42578 7.03526 5.42578 6.86812H4.42578C4.42578 7.55525 4.96846 8.14152 5.67334 8.14152V7.14152ZM5.42578 6.86812C5.42578 6.70098 5.55249 6.59473 5.67334 6.59473V5.59473C4.96846 5.59473 4.42578 6.18099 4.42578 6.86812H5.42578ZM5.67334 6.59473C5.79419 6.59473 5.9209 6.70098 5.9209 6.86812H6.9209C6.9209 6.18099 6.37823 5.59473 5.67334 5.59473V6.59473ZM5.9209 6.86812C5.9209 6.94817 5.89038 7.0169 5.84431 7.06555L6.57032 7.75323C6.78849 7.52289 6.9209 7.20953 6.9209 6.86812H5.9209ZM5.84431 7.06555C5.7972 7.11528 5.73693 7.14152 5.67334 7.14152V8.14152C6.0281 8.14152 6.34606 7.99 6.57032 7.75323L5.84431 7.06555ZM5.84781 7.75689L6.80896 8.75125L7.52797 8.05626L6.56682 7.06189L5.84781 7.75689ZM7.16846 8.90375H8.69918V7.90376H7.16846V8.90375Z").cl-2
								ellipse(cx="8.16492" cy="5.46871" rx="0.747561" ry="0.773396").cl-2-s
								path(d="M8.77015 10.5346C9.04629 10.5346 9.27015 10.3108 9.27015 10.0346C9.27015 9.75848 9.04629 9.53463 8.77015 9.53463V10.5346ZM4.99512 10.0346C4.99512 10.2018 4.86841 10.308 4.74756 10.308V11.308C5.45245 11.308 5.99512 10.7218 5.99512 10.0346H4.99512ZM4.74756 10.308C4.62671 10.308 4.5 10.2018 4.5 10.0346H3.5C3.5 10.7218 4.04268 11.308 4.74756 11.308V10.308ZM4.5 10.0346C4.5 9.86749 4.62671 9.76123 4.74756 9.76123V8.76123C4.04268 8.76123 3.5 9.3475 3.5 10.0346H4.5ZM4.74756 9.76123C4.86841 9.76123 4.99512 9.86749 4.99512 10.0346H5.99512C5.99512 9.3475 5.45245 8.76123 4.74756 8.76123V9.76123ZM5.49512 10.5346H8.77015V9.53463H5.49512V10.5346Z").cl-2
								path(d="M7.18083 11.4346V10.9346H6.96873L6.82132 11.0871L7.18083 11.4346ZM8.66359 11.9346C8.93973 11.9346 9.16359 11.7107 9.16359 11.4346C9.16359 11.1584 8.93973 10.9346 8.66359 10.9346V11.9346ZM5.9209 13.055C5.9209 13.2222 5.79419 13.3284 5.67334 13.3284V14.3284C6.37823 14.3284 6.9209 13.7421 6.9209 13.055H5.9209ZM5.67334 13.3284C5.55249 13.3284 5.42578 13.2222 5.42578 13.055H4.42578C4.42578 13.7421 4.96846 14.3284 5.67334 14.3284V13.3284ZM5.42578 13.055C5.42578 12.8879 5.55249 12.7816 5.67334 12.7816V11.7816C4.96846 11.7816 4.42578 12.3679 4.42578 13.055H5.42578ZM5.67334 12.7816C5.7319 12.7816 5.78695 12.8037 5.83201 12.8454L6.51143 12.1117C6.29197 11.9085 5.99771 11.7816 5.67334 11.7816V12.7816ZM5.83201 12.8454C5.88485 12.8944 5.9209 12.9681 5.9209 13.055H6.9209C6.9209 12.6838 6.76446 12.346 6.51143 12.1117L5.83201 12.8454ZM6.53122 12.8261L7.54033 11.7821L6.82132 11.0871L5.81221 12.1311L6.53122 12.8261ZM7.18083 11.9346H8.66359V10.9346H7.18083V11.9346Z").cl-2
								ellipse(cx="8.16492" cy="14.4548" rx="0.747561" ry="0.773396").cl-2-s
								path(fill-rule="evenodd" clip-rule="evenodd" d="M12.4198 15.5433C11.7162 15.8448 10.962 16 10.2004 16V14.6671C10.7927 14.667 11.3792 14.5463 11.9264 14.3118C12.4736 14.0773 12.9709 13.7336 13.3898 13.3002C13.8086 12.8669 14.1409 12.3524 14.3676 11.7863C14.5943 11.2201 14.7109 10.6132 14.7109 10.0004C14.7109 9.38757 14.5943 8.78074 14.3676 8.21455C14.1409 7.64836 13.8086 7.13391 13.3898 6.70057C12.9709 6.26723 12.4736 5.92349 11.9264 5.68897C11.3792 5.45448 10.7927 5.33378 10.2004 5.33374V4C10.962 4 11.7162 4.15519 12.4198 4.45672C13.1235 4.75825 13.7628 5.20021 14.3014 5.75736C14.8399 6.31451 15.2671 6.97595 15.5585 7.7039C15.85 8.43185 16 9.21207 16 10C16 10.7879 15.85 11.5681 15.5585 12.2961C15.2671 13.0241 14.8399 13.6855 14.3014 14.2426C13.7628 14.7998 13.1235 15.2417 12.4198 15.5433Z").cl-2
								path(d="M15.3556 9.33285L11.4892 8.99951V10.6662L15.3556 10.3328V9.33285Z").cl-2
								path(d="M10.2005 12.0005C10.7133 12.0005 11.205 11.7898 11.5675 11.4147C11.9301 11.0396 12.1337 10.5309 12.1337 10.0005C12.1337 9.47006 11.9301 8.96135 11.5675 8.58627C11.205 8.2112 10.7133 8.00049 10.2005 8.00049L10.2005 10.0005L10.2005 12.0005Z").cl-2
								path(d="M10.2001 14.6663V11.7033L11.1667 11.3329L10.8445 14.6663H10.2001Z").cl-2
						span Include similar titles generated by Artificial Intelligence

						.eye-c
							svg(width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg")
								path(d="M9.075 6.25488C6.20065 6.25488 3.85959 4.80866 2.71165 3.94997C1.36487 2.92859 0 1.43718 0 0.542331C0 0.244049 0.244049 0 0.542331 0C0.750224 0 0.876769 0.153661 0.967157 0.325399C1.28352 0.894846 1.62699 1.4191 2.06085 1.9072C2.47664 2.35914 2.92859 2.76589 3.42572 3.13648C4.45615 3.89574 6.55316 5.17926 9.075 5.17926C11.5968 5.17926 13.6938 3.89574 14.7243 3.13648C15.357 2.66646 15.9355 2.12413 16.4236 1.50949C16.6315 1.24736 16.7942 0.958118 16.9659 0.677914C17.1105 0.442904 17.2913 0.00903905 17.6077 0.00903905C17.906 0.00903905 18.15 0.253088 18.15 0.55137C18.15 1.44622 16.7851 2.93762 15.4383 3.95901C14.2995 4.80866 11.9494 6.25488 9.075 6.25488Z" fill="#4F4F4F")
								path(d="M17.1374 5.3962C17.0199 5.3962 16.8934 5.351 16.812 5.25158L15.2754 3.62459C15.1037 3.44381 15.1127 3.15457 15.2935 2.98283C15.4743 2.81109 15.7635 2.82013 15.9353 3.00091L17.4719 4.6279C17.6436 4.80867 17.6346 5.09792 17.4538 5.26965C17.3543 5.351 17.2459 5.3962 17.1374 5.3962Z" fill="#4F4F4F")
								path(d="M12.4557 7.81867C12.2658 7.81867 12.0851 7.69213 12.0218 7.50231L11.3439 5.36915C11.2716 5.13414 11.3981 4.88105 11.6331 4.7997C11.8681 4.72739 12.1212 4.85393 12.2026 5.08894L12.8805 7.22211C12.9528 7.45712 12.8263 7.71021 12.5912 7.79156C12.5551 7.80963 12.5009 7.81867 12.4557 7.81867Z" fill="#4F4F4F")
								path(d="M0.840615 5.3962C0.732149 5.3962 0.614647 5.351 0.533298 5.26965C0.352521 5.09792 0.343481 4.80867 0.515219 4.6279L2.05182 3.00091C2.22356 2.82013 2.5128 2.81109 2.69358 2.98283C2.87435 3.15457 2.88339 3.44381 2.71166 3.62459L1.17505 5.25158C1.08466 5.351 0.95812 5.3962 0.840615 5.3962Z" fill="#4F4F4F")
								path(d="M5.51324 7.81856C5.46804 7.81856 5.42285 7.80952 5.37766 7.80049C5.14265 7.72818 5.00706 7.46605 5.08841 7.23104L5.76632 5.09787C5.83864 4.86286 6.10076 4.72728 6.33577 4.80863C6.57078 4.88094 6.70636 5.14307 6.62501 5.37808L5.9471 7.51124C5.88383 7.69202 5.71209 7.81856 5.51324 7.81856Z" fill="#4F4F4F")

						.eye
							svg(width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg")
								path(d="M8.99999 1.10002C11.3004 1.10002 13.2475 2.15738 14.4758 3.04454C15.9311 4.09559 16.68 5.11225 16.8742 5.50002C16.6798 5.88775 15.9311 6.90441 14.4758 7.95549C13.2475 8.84262 11.3004 9.89998 8.99999 9.89998C6.69953 9.89998 4.75254 8.84262 3.52417 7.95549C2.06862 6.90441 1.31999 5.88775 1.12552 5.50002C1.31999 5.11225 2.06862 4.09559 3.52389 3.04454C4.75254 2.15738 6.69953 1.10002 8.99999 1.10002ZM8.99999 0C4.02955 0 0 4.4 0 5.50002C0 6.60003 4.02955 11 8.99999 11C13.9705 11 18 6.6 18 5.50002C18 4.40003 13.9705 0 8.99999 0Z")
								ellipse(cx="9.00107" cy="5.49971" rx="2.4" ry="2.42")

					.list(v-if="listOpened")
						vuescroll(:ops="ops")
							.inside
								div(v-for="item in GetSettings.artificial_titles")
									.ch-list(v-if="item.leads > 0")
										label(v-bind:class="{checked: ((GetSettings.titles.filter(single => single.job_title.id == item.id).length) > 0)}").gray {{ item.name }}
									.ch-list(v-on:click="addNewTitle(item)", v-else)
										label(v-bind:class="{checked: ((GetSettings.titles.filter(single => single.job_title.id == item.id).length) > 0)}") {{ item.name }}

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Provide ideal job titles to ensure precise targeting.

			button.btn.add-new-btn(type="button", v-on:click="addJob()") ADD A NEW job title

		.popup.add-job-p(v-if="popupAddJob")
			.close(v-on:click="showHidePopups()")
			.inside-form
				label Job Title
				input(type="text", placeholder="Text here...", v-model="newJobName", v-bind:class="{error: newJobNameError}", autocomplete="xz").inp

				button.btn.simple(type="button", v-on:click="saveJob()") Apply

</template>

<script>
	import vuescroll from 'vuescroll'
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'JobTitles',
		components:{
			CardHeader,
			DropList,
			vuescroll
		},
		data() {
			return {
				generatedLeads: 719,
				cardStatus: true,
				chartWidth: '',
				maxDivide: 3,
				maxNumber: 100,
				emptyGoal: true,
				listOpened: false,
				ops:{
					bar:{
						background: '#FC9000',
						onlyShowBarOnScroll: false,
						keepShow: true,
						size: '2px'
					},
					rail:{
						background: '#E6E6E6',
						opacity: 1,
						size: '2px'
					}
				},
				newJobName: '',
				popupAddJob: false,
				newJobNameError: false,
				leadGoalError: false,
				prevNumber: false,
				editIndex: false
			}
		},
		created(){
			this.setLeads();
			this.sortFunc(this.GetSettings.titles);
			this.setMaxInt();
			this.checkList();
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "JobTitle") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		watch: {
			'GetSettings.total_main': function () {
				this.generateLeads();
				// this.setMaxInt();
			}
		},
		mounted() {
			// window.addEventListener('resize', this.handleResize);
			this.handleResize();
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			generateLeads(){
				if(this.cardStatus){
					for(var item in this.GetSettings.titles){
						if(this.GetSettings.titles[item].state == 'running'){
							var nextLG = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.titles[item].percent / 100)).toFixed(0);
							if (this.GetSettings.titles[item].leads_generated <= nextLG) {
								this.GetSettings.titles[item].leads_generated = nextLG;
							}
						}else{
							if(this.GetSettings.titles[item].percent != 0){
								this.GetSettings.titles[item].percent = Number(this.GetSettings.titles[item].leads_generated / this.GetSettings.total_main.leads_generated * 100).toFixed(2);
							}
						}
					}
				}else{
					for(var single in this.GetSettings.titles){
						if(this.GetSettings.titles[single].percent != 0){
							this.GetSettings.titles[single].percent = Number(this.GetSettings.titles[single].leads_generated / this.GetSettings.total_main.leads_generated * 100).toFixed(2);
						}
					}
				}
				this.updateSettings(['titles', this.GetSettings.titles]);
				this.sortFunc(this.GetSettings.titles);
				this.setMaxInt();
				this.setTitltesList();
			},
			setLeads(){
				for(var single in this.GetSettings.titles){
					var res = Number(this.GetSettings.total_main.leads_generated * (this.GetSettings.titles[single].percent / 100)).toFixed(0);
					if (res == 0) {
						res = '-'
					}
					this.GetSettings.titles[single].leads_generated = res;
					if (!this.GetSettings.titles[single].goal) {
						this.GetSettings.titles[single].goal = '-';
					}
				}
				this.updateSettings(['titles', this.GetSettings.titles]);
				this.setTitltesList();
			},
			setTitltesList(){
				for(var title in this.GetSettings.titles){
					var obj = this.GetSettings.titles[title];
					var res = this.GetSettings.artificial_titles.findIndex(single => single.id === this.GetSettings.titles[title].job_title.id);
					if (res > 0) {
						this.GetSettings.artificial_titles[res].leads = obj.leads_generated;
					}
				}
				this.updateSettings(['artificial_titles', this.GetSettings.artificial_titles]);
			},
			...mapMutations(['updateSettings']),
			handleResize() {
				if(this.GetSettings.titles.length){
					this.chartWidth = this.$refs.chart.clientWidth;
				}
			},
			sortFunc: function (obj){
				return obj.sort((a, b) => b.percent - a.percent );
			},
			setMaxInt(){
				var max = this.maxNumber;
				for (var item in this.GetSettings.titles) {
					var leads = this.GetSettings.total_main.leads_generated * (this.GetSettings.titles[item].percent / 100)
					var num = parseInt(leads);
					if (max < num && !isNaN(num)) {
						max = num
					}
				}

				var r = parseInt(max.toString()[0]);
				check();
				var x;
				function check(){
					x = r * 100;
					if(x < max){
						r = r + 1;
						check();
					}
				}
				this.maxNumber = x;
			},
			checkList(){
				for (var item in this.jobsList) {
					var goal = this.jobsList[item].goal;
					if (goal.length > 1 || goal > 0) {
						this.emptyGoal = false;
						return;
					}
				}
			},
			changeJobStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.titles.[index].state == 'running'){
						this.GetSettings.titles.[index].state = 'pause'
					}else{
						this.GetSettings.titles.[index].state = 'running'
					}
				}
			},
			showList(){
				this.listOpened = !this.listOpened;
			},
			addJob(){
				if (this.cardStatus) {
					this.popupAddJob = !this.popupAddJob;
					this.$emit('input', this.popupAddJob);
				}
			},
			showHidePopups(){
				this.newJobName = '';
				this.popupAddJob = false;
				this.$emit('input', false);
			},
			saveJob(){
				if (this.newJobName.length < 2){
					this.newJobNameError = true;
					setTimeout(() => {
						this.newJobNameError = false;
					}, 1500)
				}else{
					var key =  Object.keys(this.GetSettings.titles).length;
					var pattern = {
						campaign: '',
						goal: '-',
						job_title:{
							name: ''
						},
						leads_generated: 0,
						percent: 0,
						state:"pause",
						new: true

					}
					pattern.job_title.name = this.newJobName;
					this.$set(this.GetSettings.titles, key, pattern);
					this.updateSettings(['titles', this.GetSettings.titles]);
					this.showHidePopups();
				}
			},
			addNewTitle(item){
				if (this.GetSettings.titles.filter(single => single.job_title.id == item.id).length > 0) {
					this.GetSettings.titles.splice(this.GetSettings.titles.findIndex(single => single.job_title.id === item.id),1);
				}else{
					var key =  Object.keys(this.GetSettings.titles).length;
					var newTitle = {
						job_title: item,
						leads_generated: 0,
						percent: 0,
						goal: '-',
						state: 'pause'
					}
					this.$set(this.GetSettings.titles, key, newTitle);
				}
				this.updateSettings(['titles', this.GetSettings.titles]);
				this.handleResize();
			},
			onlyNumber ($event) {
				let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
					$event.preventDefault();
				}
			},
			editLeadGoal(index){
				this.editIndex = index;
				this.prevNumber = this.GetSettings.titles[this.editIndex].goal;
			},
			checkGoal(){
				if (+this.GetSettings.titles[this.editIndex].goal > 100 || +this.GetSettings.titles[this.editIndex].goal < 1) {
					this.leadGoalError = this.editIndex
				}else{
					this.leadGoalError = false;
				}
			},
			handleFocusOut(){
				if (+this.GetSettings.titles[this.editIndex].goal > 100 || +this.GetSettings.titles[this.editIndex].goal < 1) {
					this.GetSettings.titles[this.editIndex].goal = '-';
					// this.GetSettings.titles[this.editIndex].goal = this.prevNumber;
				}
				this.updateSettings(['titles', this.GetSettings.titles]);
				this.sortFunc(this.GetSettings.titles);
				this.setMaxInt();
				this.leadGoalError = false;
				this.prevNumber = false;
				this.editIndex = false;
			},
			removeRow(index){
				this.GetSettings.titles.splice(index, 1);
				this.updateSettings(['titles', this.GetSettings.titles]);
			}
		}
	}
</script>